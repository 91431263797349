import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CallRulesService } from '@services/call-rules/call-rules.service';
import { SipInnerModel } from '@models/local/sip/sip-inner.model';
import { CallRuleActionType } from '@shared/constant/call-rule.constants';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';

@Component({
  selector: 'sip-inner-redirection',
  templateUrl: './sip-inner-redirection.component.html',
  styleUrls: ['./sip-inner-redirection.component.sass']
})
export class SipInnerRedirectionComponent implements OnInit {

  private _extensions: SipInnerModel[];

  @Input() form: FormGroup;
  @Input() index: number;
  @Input() service: CallRulesService | CrmIntegrationRulesService;

  get extensions(): SipInnerModel[] {
    return this._extensions;
  }

  constructor() { }

  ngOnInit(): void {
    if (this.index > 0) {
      const prevAction = this.form.value.ruleActions[this.index - 1];
      if (prevAction.action === CallRuleActionType.SIP_INNER_REDIRECTION) {
        const param = prevAction.parameter;
        this._extensions = this.service.extensions.filter(x => x.id !== param);
        return;
      }
    }
    this._extensions = this.service.extensions.sort((a, b) => a.phoneNumber.localeCompare(b.phoneNumber));
  }
}
