import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CallRulesService } from '@services/call-rules/call-rules.service';
import { CallQueueModel } from '@models/local/call-queue/call-queue.model';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';

@Component({
  selector: 'call-queue-redirection',
  templateUrl: './call-queue-redirection.component.html',
  styleUrls: ['./call-queue-redirection.component.sass']
})
export class CallQueueRedirectionComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() index: number;
  @Input() service: CallRulesService | CrmIntegrationRulesService;
  @Input() nextQueue = false;

  get callQueues(): CallQueueModel[] {
    return this.nextQueue ? this.service.nextQueues : this.service.callQueues;
  }

  get placeholder(): string {
    return this.callQueues.length === 0
      ? 'You have no queues'
      : 'choose one';
  }

  constructor() { }

  ngOnInit() { }
}
