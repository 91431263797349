import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Injector,
  AfterViewInit,
} from '@angular/core';
import 'rxjs/add/operator/takeUntil';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { BaseModel } from '@models/local/base.model';
import { hasId, tryValueAsNumber } from '@helpers/utils/model.utils';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NO_ITEMS_FOUND } from '@shared/constant/app-notification.constants';
import { TranslateService } from '@services/translate/translate.service';
import { alive } from '@helpers/utils/componentDestroyed';


@Component({
  selector: 'etl-select-new',
  templateUrl: './etl-select-new.component.html',
  styleUrls: [
    './etl-select-new.component.sass',
    './etl-select-new.white.component.sass',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlSelectNewComponent),
      multi: true
    }
  ]
})
export class EtlSelectNewComponent
  extends EtlBaseFormControlComponent
  implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {

  @Input() value: any;
  @Input() options: BaseModel[];
  @Input() optionFieldName: string;

  @Input() disabled: boolean = false;
  @Input() useIdAsValue: boolean = true;

  @Input() clearable: boolean = false;
  @Input() searchable: boolean = false;
  @Input() searchWithStart: boolean = false;

  @ViewChild('select', { static: false }) select: NgSelectComponent;

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(alive(this))
      .subscribe(() => {
        if (this.options) {
          if (this.localformControlName === 'region') {
            this.options.sort((a, b) => {
              return a.value.localeCompare(b.value);
            });
          }
          if (this.localformControlName === 'countryId' || this.localformControlName === 'countryCode') {
            this.options.sort((a, b) => {
              return a.title.localeCompare(b.title);
            });
          }
          this.options = [...this.options];
          // console.log('opts', JSON.stringify(this.options));
          this.matchSelection();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.options = changes.options.currentValue;
      this.matchSelection();
    }
  }

  ngAfterViewInit(): void {
    if (this.select) {
      this.select.notFoundText = this.translate.instant(NO_ITEMS_FOUND);
    }
  }

  // -- ControlValueAccessor overrides ----------------------------------------

  writeValue(value: any): void {
    if (value === null) {
      setTimeout(() => {
        this.value = null;
      }, 0);
    } else {
      setTimeout(() => {
        this.value = this.useIdAsValue ? tryValueAsNumber(value) : value;
        this.matchSelection();
      }, 0);
    }
  }

  // -- event handlers --------------------------------------------------------

  onChangeValue(value: any): void {
    value = value || null;
    this.value = value;

    const valueChanges = this.useIdAsValue && value ? value.id : value;
    this.onChange(valueChanges);
    this.onTouched();

    this.change.emit(value);
  }

  onStateChanges(state: boolean): void {
    if (state) {
      this.onFocus(state);
    } else if (this.focused) {
      this.onBlur(state);
    }
  }

  onFocus(event) {
    this.focused = true;
    this.focus.emit(event);
  }

  onBlur(event) {
    this.focused = false;
    this.blur.emit(event);
    this.onTouched();
  }

  // -- general methods -------------------------------------------------------

  setFocus(silent: boolean = false): void {
    if (!this.disabled) {
      this.focused = true;
    }
    this.select.focus();
    if (!silent) {
      this.select.open();
    }
  }

  clearFocus(): void {
    this.focused = false;
    this.select.focused = false;
  }

  reset(): void {
    this.onChange(null);
    this.onTouched();
  }

  private matchSelection(value: any = null): void {
    if (!this.options || this.options.length === 0) {
      return;
    }

    value = (value === null || value === undefined) ? this.value : value;
    if (this.useIdAsValue && (hasId(this.options) || !this.options)) {
      const targetId = hasId(value)
        ? value.id
        : tryValueAsNumber(value);
      this.value = this.options.find(o => o.id === targetId);
    } else {
      if (hasId(value) && hasId(this.options)) {
        this.value = this.options.find(o => o.id === value.id);
      } else if (this.options) {
        this.value = this.options.find(o => o === value);
      }
    }
  }

  search = (term: string, item: any) => {
    term = term.toLowerCase();
    if (this.searchWithStart) {
      return item[this.optionFieldName].toLowerCase().startsWith(term);
    } else {
      return item[this.optionFieldName].toLowerCase().indexOf(term) > -1;
    }
  }
}
