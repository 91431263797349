/**
 * General App messages constants
 */
export const METHOD_IS_NOT_IMPLEMENTED = 'Method is not implemented';
export const METHOD_MUST_BE_OVERRIDDEN = 'Method must be overridden in derived class';
export const CHANGES_SAVE_COMPLETION = 'The changes have been saved successfully';
export const LEAVE_EDIT_FORM_CONFIRMATION = 'You have made changes. Do you really want to leave without saving?';
export const LEAVE_EMPTY_FORM_CONFIRMATION = 'Do you really want to leave without saving?';
export const ACCEPTED_FORMATS_ERROR = 'acceptedFormatsError'; // {formats}
export const FILE_EXCEEDS_LIMIT_ERROR = 'fileExceedsLimitMb'; // {size}
export const INTERNAL_SERVER_ERROR_MESSAGE = 'Something went wrong';
export const AUTHORIZED_SESSION_MESSAGE = 'You already have an authorized session running';
export const NO_ITEMS_FOUND = 'No items found';
export const UNKNOWN_ERROR = 'Unknown error';

/**
 * Authorization messages constants
 */
export const MAX_LOGIN_ATTEMPTS = 'maxLoginAttempts';
export const LOGOUT_CONFIRMATION = 'You have successfully logged out';

/**
 * Call Rules component messages constants
 */
export const CALL_RULE_CREATE_COMPLETION = 'Call Rule has been created successfully';
export const CALL_RULE_DELETE_CONFIRMATION = 'Are you sure you want to delete this Call Rule?';
export const ACTIVE_CALL_RULE_DELETE_CONFIRMATION = 'Are you sure you want to delete this active Call Rule?';
export const CALL_RULE_DELETE_COMPLETION = 'Call Rule has been deleted successfully';

/**
 * Call Forwarding component messages constants
 */
export const CALL_FORWARDING_CREATE_COMPLETION = 'Call Forwarding has been created successfully';
export const CALL_FORWARDING_DELETE_CONFIRMATION = 'Are you sure you want to delete this Call Forwarding?';
export const ACTIVE_CALL_FORWARDING_DELETE_CONFIRMATION = 'Are you sure you want to delete this active Call Forwarding?';
export const CALL_FORWARDING_DELETE_COMPLETION = 'Call Forwarding has been deleted successfully';

/**
 * CRM Integration Rules component messages constants
 */
export const CRM_INTEGRATION_RULE_CREATE_COMPLETION = 'CRM Integration Rule has been created successfully';
export const CRM_INTEGRATION_RULE_DELETE_CONFIRMATION = 'Are you sure you want to delete this CRM Integration Rule?';
export const ACTIVE_CRM_INTEGRATION_RULE_DELETE_CONFIRMATION = 'Are you sure you want to delete this active CRM Integration Rule?';
export const CRM_INTEGRATION_RULE_DELETE_COMPLETION = 'CRM Integration Rule has been deleted successfully';

/**
 * IVR component messages constants
 */
export const IVR_DELETE_CONFIRMATION = 'Are you sure you want to delete this IVR?';
export const ACTIVE_IVR_DELETE_CONFIRMATION = 'Are you sure you want to delete this active IVR?';
export const IVR_DELETE_COMPLETION = 'IVR has been deleted successfully';

/**
 * Call Queues component messages constants
 */
export const CALL_QUEUE_CREATE_COMPLETION = 'Call Queue has been created successfully';
export const CALL_QUEUE_DELETE_CONFIRMATION = 'Are you sure you want to delete this Call Queue?';
export const CALL_QUEUE_DELETE_COMPLETION = 'Call Queue has been deleted successfully';
export const CALL_QUEUE_EMPTY_MESSAGE = 'You do not have any Call Queues';
export const CALL_QUEUE_EMPTY_MEMBERS = 'You have not chosen the members';
export const CALL_QUEUE_EMPTY_MEMBERS_GENERAL = 'Choose at least one member';
export const CALL_QUEUE_EMPTY_EXT_PHONES = 'You have not chosen the external phones';
export const CALL_QUEUE_EMPTY_EXT_PHONES_GENERAL = 'Choose at least one external phone';
export const CALL_QUEUE_ADDED_MEMBERS_MSG = 'callQueueMembersAddedMessage';
export const CALL_QUEUE_REMOVED_MEMBERS_MSG = 'callQueueMembersRemovedMessage';
export const CALL_QUEUE_MEMBER_DELETE_CONFIRMATION = 'Are you sure you want to delete this member?';
export const CALL_QUEUE_PHONE_CHANGE_CONFIRMATION = 'Changing the phone number will remove all members. Would you like to continue?';

/**
 * Storage component messages constants
 */
export const MEDIA_FILE_IS_IN_USE = 'mediaFileInUse'; // {file|module|name}
export const FILE_ALREADY_UPLOADED_CONFIRMATION = 'fileAlreadyExistsMessage';

export const MEDIA_FILE_UPLOAD_COMPLETION = 'fileDownloadConfirmationSn'; // {file}
export const MEDIA_FILES_UPLOAD_COMPLETION = 'fileDownloadConfirmationPl'; // {count}
export const MEDIA_FILE_REPLACE_COMPLETION = 'fileOverwriteConfirmationSn'; // {file}
export const MEDIA_FILES_REPLACE_COMPLETION = 'fileOverwriteConfirmationPl'; // {count}

export const MEDIA_FILE_DELETE_CONFIRMATION = 'deleteMediaFileConfirmation'; // {name}
export const MEDIA_FILES_DELETE_CONFIRMATION = 'deleteMediaFilesConfirmation'; // {count}
export const MEDIA_FILE_DELETE_COMPLETION = 'deleteMediaFileCompletion'; // {name}
export const MEDIA_FILES_DELETE_COMPLETION = 'deleteMediaFilesCompletion'; // {count}

export const MEDIA_FILE_PURGE_CONFIRMATION = 'purgeMediaFileConfirmation'; // {name}
export const MEDIA_FILES_PURGE_CONFIRMATION = 'purgeMediaFilesConfirmation'; // {count}

export const MEDIA_FILES_RESTORE_CONFIRMATION = 'Are you sure you want to restore the file(s)?';
export const MEDIA_FILE_RESTORE_COMPLETION = 'restoreMediaFileCompletion'; // {name}
export const MEDIA_FILES_RESTORE_COMPLETION = 'restoreMediaFilesCompletion'; // {count}

/**
 * Phone Numbers component messages constants
 */
export const PHONE_NUMBER_CREATE_COMPLETION = 'Phone Number has been created successfully';
export const PHONE_NUMBER_DELETE_CONFIRMATION = 'phoneNumberDeleteConfirmationEmpty';
export const PHONE_NUMBER_DELETE_CONFIRMATION_USE_RULES_OR_IVR = 'phoneNumberDeleteConfirmationEnableRulesAndIvr';

export const PHONE_NUMBER_DELETE_COMPLETION_SN = 'deletePhoneConfirmationSn';
export const PHONE_NUMBER_DELETE_COMPLETION_PL = 'deletePhoneConfirmationPl';
export const PHONE_NUMBER_STATUS_ENABLED = 'The phone number has been enabled';
export const PHONE_NUMBER_STATUS_DISABLED = 'The phone number has been disabled';
export const PHONE_NUMBER_PURCHASE_CONFIRMATION = 'phoneNumberPurchaseNumberConfirmation'; // {phone}
export const PHONE_NUMBER_PURCHASE_COMPLETION = 'phoneNumberPurchase'; // {phone}
export const PHONE_NUMBER_PURCHASE_REFILL_BALANCE = 'You don\'t have enough money to buy this phone number. Please refill your balance';

export const PHONE_NUMBER_BUY_NUMBER_CONFIRMATION = 'phoneNumberBuyNumberConfirmation';
export const PHONE_NUMBER_BUY_NUMBER_COMPLETION = 'phoneNumberBuyNumberCompletion';
export const PHONE_NUMBER_BUY_REFILL_BALANCE = 'You don\'t have enough money to buy this phone number. Please refill your balance';

/**
 * Extensions component messages constants
 */
export const EXTENSION_CREATE_COMPLETION = 'Extension has been created successfully';
export const EXTENSION_DELETE_CONFIRMATION = 'Are you sure you want to delete this Extension?';
export const EXTENSION_DELETE_COMPLETION = 'deleteExtensionCompletion';
export const EXTENSION_RESET_TO_ADMIN_CONFIRMATION
  = 'Are you sure you want to reset your password and send your new password to the admin?';
export const EXTENSION_RESET_TO_USER_CONFIRMATION
  = 'Are you sure you want to reset your password and send your new password to the user?';
export const EXTENSION_RESET_TO_ADMIN_AND_USER_CONFIRMATION
  = 'Are you sure you want to reset your password and send your new password to admin and user?';
export const EXTENSION_RESET_TO_ADMIN_COMPLETION = 'The password has been changed and sent to the admin email';
export const EXTENSION_RESET_TO_USER_COMPLETION = 'The password has been changed and sent to the user email';

/**
* Address Book component messages constants
*/
export const CONTACT_CREATE_COMPLETION = 'Contact has been created successfully';
export const CONTACT_DELETE_CONFIRMATION = 'deleteContactConfirmation';
export const CONTACT_DELETE_COMPLETION = 'deleteContactCompletion';
export const CONTACT_BLOCK_CONFIRMATION = 'contactBlockAlert';
export const CONTACT_BLOCK_COMPLETION = 'contactBlockConfirmation';
export const CONTACT_UNBLOCK_CONFIRMATION = 'contactUnblockAlert';
export const CONTACT_UNBLOCK_COMPLETION = 'contactUnblockConfirmation';

/**
 * Marketplace component messages constants
 */
export const MARKETPLACE_PURCHASE_CONFIRMATION = 'Are you sure you want to buy this module?';
export const MARKETPLACE_REFUND_CONFIRMATION = 'Do you really want to unsubscribe from this module? Your data will be lost.';
export const MARKETPLACE_REFILL_BALANCE_CONFIRMATION
  = 'You don\'t have enough money to accomplish a purchase of this module. Please refill your balance.';

/**
 * Cdr component messages constants
 */
export const CDR_BLOCK_CONFIRMATION = 'Are you sure you want to block this contact?';
export const CDR_BLOCK_COMPLETION = 'Contact blocked successfully';
export const CDR_UNBLOCK_CONFIRMATION = 'Are you sure you want to unblock this contact?';
export const CDR_UNBLOCK_COMPLETION = 'Contact unblocked successfully';

/**
 * Auto Call component messages constants
 */
export const CAMPAIGN_CREATE_COMPLETION = 'Campaign has been created successfully';
export const CAMPAIGN_PHONE_EMPTY_ERROR = 'Please fill at least 1 phone number';
export const CAMPAIGN_ALREADY_OVER = 'The campaign is already over';
export const CAMPAIGN_ACTIVATE_COMPLETION = 'Campaign has been activated';
export const CAMPAIGN_DEATIVATE_COMPLETION = 'Campaign has been deactivated';
export const CAMPAIGN_PHONE_PARSE_ERROR = 'normalizePhoneError'; // {line}
export const AUTO_CALL_DELETE_COMPLETION = 'Campaign has been deleted successfully';
export const AUTO_CALL_DELETE_CONFIRMATION = 'Are you sure you want to delete this Campaign?';
export const AUTO_CALL_BLACK_LIST_DELETE_COMPLETION = 'Phone number deleted successfully';
export const AUTO_CALL_BLACK_LIST_DELETE_CONFIRMATION = 'Are you sure you want to delete this phone number?';
export const PHONE_NUMBER_CREATE_NOTIFICATION = 'The phone number was successfully created.';
export const PHONE_NUMBER_CHANGE_NOTIFICATION = 'The phone number was successfully changed.';

/**
 * Transfer with Support component messages constants
 */
export const TRANSFER_CREATE_COMPLETION = 'Transfer has been created successfully';
export const TRANSFER_DELETE_CONFIRMATION = 'Are you sure you want to delete this Tarnsfer?';
export const TRANSFER_DELETE_COMPLETION = 'deleteTransferCompletion';

/**
 * Api token management component messages constants
 */
export const TOKEN_CREATE_COMPLETION = 'Token has been created successfully';
export const TOKEN_DELETE_CONFIRMATION = 'Are you sure you want to delete this Token?';
export const TOKEN_DELETE_COMPLETION = 'deleteTokenCompletion';
/**
 * STATISTICS
 */
export const STATISTICS_INPUT_DELETE_COMPLETION = 'Input web hook has been deleted successfully';
export const STATISTICS_OUTPUT_DELETE_COMPLETION = 'Output web hook has been deleted successfully';
export const STATISTICS_INPUT_DELETE_CONFIRMATION = 'Are you sure you want to delete this input web hook?';
export const STATISTICS_OUTPUT_DELETE_CONFIRMATION = 'Are you sure you want to delete this output web hook?';
/**
 * DISA
 */
export const DISA_DELETE_CONFIRMATION = 'Are you sure you want to delete this DISA?';
export const DISA_OUTPUT_DELETE_COMPLETION = 'DISA has been deleted successfully';
