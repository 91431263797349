import { ValidationErrorType } from './validation-error-type.enum';

export const CALL_FORWARDING_VALIDATION_MESSAGES = [
  {
    name: 'name',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the call forwarding name'
  },
  {
    name: 'name',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Please enter the call forwarding name'
  },
  {
    name: 'name',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Call forwarding name can\'t contain over 150 characters'
  },
  {
    name: 'name',
    error: ValidationErrorType.PATTERN,
    message: 'invalidCharsDigitsSymbolsMsg',
    params: { field: 'Forwarding name', symbols: ' \'-_.&' }
  },
  {
    name: 'sipId',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Phone number'
  },
  {
    name: 'ruleActions.*.timeout',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter a value from 5 to 300'
  },
  {
    name: 'ruleActions.*.timeout',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter a value from 5 to 300'
  },
  {
    name: 'ruleActions.*.timeout',
    error: ValidationErrorType.RANGE,
    message: 'Please enter a value from 5 to 300'
  },
  {
    name: 'ruleActions.*.scheduleDate',
    error: ValidationErrorType.DAYS,
    message: 'Please choose at least one day of the week'
  },
  {
    name: 'ruleActions.*.scheduleTime',
    error: ValidationErrorType.START_TIME,
    message: 'Start time cannot be greater than end time'
  },
  {
    name: 'ruleActions.*.scheduleTime',
    error: ValidationErrorType.EQUAL_TIME,
    message: 'Start time cannot be the same as end time'
  },
  {
    name: 'ruleActions.*.scheduleTime',
    error: ValidationErrorType.INVALID_RANGE,
    message: 'Invalid time range format'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the External number',
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'External number' }
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.MINLENGTH,
    message: 'External number is too short. Use at least 11 numbers'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.MAXLENGTH,
    message: 'External number is too long. Use no more than 16 numbers'
  },
];
