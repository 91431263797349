import { GuideEventType, GuideSrcElement, GuideEventHandler, GuideAction } from '../guide.enums';

export const ADDRESS_BOOK_CREATE_SCENARIO = [
  {
    intro: 'Welcome! We will tell you how to create a contact in the address book',
    hint: true,
  },
  {
    element: 'button-create',
    intro: 'Click on the button Create contact',
    position: 'bottom-left',
    canMove: false,
    stepSwitchDelay: 400,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.CREATE_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.ADDRESS_BOOK_SIDEBAR,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'first-name',
    arrowTo: 'input',
    intro: 'Enter the name of the new contact',
    position: 'bottom-left',
    focus: true,
    canMove: false,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['firstname']
      }
    ]
  },
  {
    element: 'last-name',
    arrowTo: 'input',
    intro: 'Enter last name (optional field)',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['lastname']
      }
    ]
  },
  {
    element: 'contact-phone',
    arrowTo: 'input',
    intro: 'Enter phone number (optional field)',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['contactPhone.0.value']
      }
    ]
  },
  {
    element: 'phone-type',
    arrowTo: 'input',
    intro: 'Select phone number type from the drop-down list (optional field)',
    position: 'bottom-left',
    focus: true,
  },
  {
    element: 'contact-email',
    arrowTo: 'input',
    intro: 'Enter email address (optional field)',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['contactEmail.0.value']
      }
    ]
  },
  {
    element: 'company-name',
    arrowTo: 'input',
    intro: 'Enter organization name (optional field)',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['company']
      }
    ]
  },
  {
    element: 'department',
    arrowTo: 'input',
    intro: 'Enter department (optional field)',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['department']
      }
    ]
  },
  {
    element: 'position',
    arrowTo: 'input',
    intro: 'Enter position (optional field)',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['position']
      }
    ]
  },
  {
    element: 'country',
    arrowTo: 'input',
    intro: 'Select country from the drop-down list (optional field)',
    position: 'bottom-left',
    focus: true,
  },
  {
    element: 'address',
    arrowTo: 'input',
    intro: 'Enter address (optional field)',
    position: 'top-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['address']
      }
    ]
  },
  {
    element: 'button-save',
    intro: 'Click on the Save button',
    position: 'top-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.SAVE_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.ADDRESS_BOOK_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    tableElement: true,
    intro: 'The contact you created is added to the table',
    position: 'bottom-left',
  },
  {
    intro: 'Come again, always happy to help ...',
    hint: true,
  },
];
