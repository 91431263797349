import { Mapper } from '@mappers/mapper';
import { SipModel } from '@models/local/sip/sip.model';
import { SipServerModel } from '@models/server/sip/sip-server.model';
import { SipInnerMapper } from './sip-inner.mapper';
import { IvrInfoModel } from '@models/local/ivr/ivr-info.model';
import { CallRuleInfoModel } from '@models/local/call-rule/call-rule-info.model';
import { PhoneFormatterPipe } from '@shared/pipes/phone-formatter.pipe';

// tslint:disable-next-line:naming-convention
export let SipMapper: Mapper<SipModel, SipServerModel> = {
  
  from(obj: SipServerModel): SipModel {
    if (!obj) { return null; }

    let activeIvr: IvrInfoModel = null;
    if (obj.enabledIvr) {
      activeIvr = {
        id: obj.enabledIvr.id,
        name: obj.enabledIvr.name
      };
    }

    let activeCallRule: CallRuleInfoModel = null;
    if (obj.enabledOuterRule) {
      activeCallRule = {
        id: obj.enabledOuterRule.id,
        name: obj.enabledOuterRule.name
      };
    }

    const formatter = new PhoneFormatterPipe();

    return {
      id: obj.id,
      phoneNumber: obj.phoneNumber,
      formattedPhone: formatter.transform(obj.phoneNumber),
      sipInners: obj.sipInners
        ? SipInnerMapper.fromArray(obj.sipInners)
        : null,
      status: obj.status,
      providerId: obj.providerId,
      activeIvr: activeIvr,
      activeCallRule: activeCallRule
    };
  },

  fromArray(arr: SipServerModel[]): SipModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: SipModel): SipServerModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      phoneNumber: obj.phoneNumber,
      status: obj.status,
      providerId: obj.providerId
    };
  }
};
