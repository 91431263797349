import { MediaFileModel } from './media-file.model';

export interface StorageUploadResult {
  code: StorageUploadCode;
  file: MediaFileModel | File;
  originalFileId?: number;
  replaced?: boolean;
  message?: string;
  params?: { [key: string]: any };
}

export enum StorageUploadCode {
  SUCCESS = 0,
  SKIPPED = 1,
  FILE_EXISTS = 2,
  FILE_IS_IN_USE = 3,
  UNKNOWN = 4,
  FILE_IS_LARGE = 5,
}
