import { Pipe, PipeTransform } from '@angular/core';
import { isComVersion } from '@helpers/utils/app.utils';

@Pipe({
    name: 'phoneFormatter',
    pure: true
})
export class PhoneFormatterPipe implements PipeTransform {

    transform(value: any): string {
        return this.isLongPhone(value) ? this.formatLongPhone(value) : this.formatShortPhone(value);
    }

    private formatLongPhone(val) {
        const cleaned = ('' + val).replace(/\D/g, '');
        const match = cleaned.match(/^(\d|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match && !isComVersion()) {
            const intlCode = (match[1] ? match[1] === '8' ? '+7' : `+${match[1]}` : '');
            return `${intlCode} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
        }
        return val;
    }

    private formatShortPhone(val) {
        // const cleaned = ('' + val).replace(/\D/g, '');

        // const match = cleaned.match(/(\d{2})(\d{2})(\d{2})$/);

        // if (match) {
        //     return `${match[1]}-${match[2]}-${match[3]}`;
        // }
        return val;
    }

    isLongPhone(val) {
        return val && val.length >= 10;
    }
}
