import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '@services/user/authentication-service.service';
import moment from 'moment';
@Pipe({
  name: 'customDate',
  pure: true
})
export class CustomDatePipe implements PipeTransform {
  private _datePipe: DatePipe;
  private _userFormat: string;

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private _auth: AuthenticationService
  ) {
    this._datePipe = new DatePipe(_locale);
    if (_auth.user) {
      this._userFormat = this.timeFormatTransform(_auth.user.dateFormat, _auth.user.timeFormat);
    } else {
      this._userFormat = this.timeFormatTransform('dd.MM.yyyy', 'HH:mm:ss');
    }
  }

  /**
   * @description
   * Transforms date and time, date, time
   * example use date and time format: ObjectDate | customDate
   * example use date format only: ObjectDate | customDate:'dateFormat'
   * example use time format only: ObjectDate | customDate:'timeFormat'
   *
   * @param value date object
   * @param format date format string
   */
  transform(value: any, format: string = 'dateFomat') {
    let outDate: string;
    switch (format) {
      case 'dateFormat': {
        outDate = this._userFormat.split(' ')[0].toUpperCase();
        break;
      }
      case 'timeFormat': {
        outDate = this._userFormat.split(' ')[1];
        break;
      }
      default: {
        const res = this._userFormat.split(' ');
        res[0] = res[0].toUpperCase();
        outDate = res.join(' ');
      }
    }
    const formated = moment.unix(moment(value).unix() + this._auth.user.timeZoneOffset).format(outDate).split(' ');
    if (formated.length === 3) {
      formated[2] = formated[2].split('').join('.');
    }
    return formated.join(' ');
  }

  /**
   * @description
   * Updates date format to match selected time format. (24 to 12 for example)
   *
   * @param value date format string
   * @param format time format string
   */
  private timeFormatTransform(value: string, format: string) {
    if (format === '12') {
      value = value.replace('HH', 'hh') + ' a';
    }
    return value;
  }
}
