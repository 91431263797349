import { Mapper } from '@mappers/mapper';
import { ModuleModel } from '@models/local/tariff-plan/module.model';
import { ModuleServerModel } from '@models/server/tariff-plan/module-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';

// tslint:disable-next-line:naming-convention
export let ModuleMapper: Mapper<ModuleModel, ModuleServerModel> = {
  
  from(obj: ModuleServerModel): ModuleModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      name: obj.name,
      title: obj.title,
      sum: obj.sum,
      sumWithVat: obj.sumWithVat,
      sort: obj.sort,
      exists: setExist(obj.name),
      count: obj.count
    };
  },

  fromArray(arr: ModuleServerModel[]): ModuleModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: ModuleModel): ModuleServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};

function setExist(str: string): number {
  switch (str) {
    case 'vats':
      return 2;
    case 'onePhoneInTariff':
      return 2;
    case 'callForwarding':
      return 1;
    default:
      return 0;
  }
}
