import { GuideSrcElement, GuideEventType, GuideEventHandler } from '../guide.enums';

export const INVOICES_DOWNLOAD_SCENARIO = [
  {
    intro: 'Welcome! We will tell you how to view and download the invoice',
    hint: true,
  },
  {
    tableElement: true,
    arrowTo: '.zoom',
    intro: 'To view the invoice, click the Magnifying Glass icon on the desired invoice.',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.ZOOM_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.INVOICE_VIEW_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'invoice-view-table',
    intro: 'The table contains information about the services rendered for the selected billing period',
    position: 'top-left',
  },
  {
    element: 'download-button',
    intro: 'To download the invoice, click on the Download button',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.DOWNLOAD_BUTTON,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    intro: 'Come again, always happy to help ...',
    hint: true,
  },
];
