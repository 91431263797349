import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDataModel, ModalButtonStyle } from '@models/common/modal-data/modal-data.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [
    './confirm-dialog.component.sass',
    './confirm-dialog.dark-theme.component.sass',
    './confirm-dialog.white-theme.component.sass',
  ]
})
export class ConfirmDialogComponent implements OnInit {

  get okBtnStyle(): string {
    if (this.data.okBtn.style) {
      return this.data.okBtn.style;
    }
    return ModalButtonStyle.PRIMARY;
  }

  constructor(
    private _dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDataModel
  ) {
  }

  ngOnInit() { }

  close(result) {
    this._dialogRef.close(result);
  }
}
