import { Mapper } from '@mappers/mapper';
import { PageInfoModel } from '@models/common/table-models/page-info.model';
import { PAGE_LIMIT_DEFAULT } from '@helpers/utils/pageInfo.utils';
import { FilterInfoModel } from '@models/common/table-models/filter-info.model';
import { FilterMapper } from './filter-mapper';

export class PageInfoMapper<TModel, TServerModel> implements Mapper<PageInfoModel<TModel>, PageInfoModel<TServerModel>> {

  from(obj: PageInfoModel<TServerModel>): PageInfoModel<TModel> {
    if (!obj) { return null; }

    return {
      page: obj.page,
      pageCount: obj.pageCount,
      limit: obj.limit || PAGE_LIMIT_DEFAULT,
      items: [],
      itemsCount: obj.itemsCount,
      visible: obj.visible,
      filter: obj.filter
    };
  }

  to(obj: PageInfoModel<TModel>): PageInfoModel<TServerModel> {
    if (!obj) { return null; }

    return {
      page: obj.page,
      pageCount: obj.pageCount,
      limit: obj.limit,
      items: [],
      itemsCount: obj.itemsCount,
      visible: obj.visible,
      filter: obj.filter
    };
  }
}

export function convertPageInfo<T1, T2>(
    src: PageInfoModel<T2>,
    modelMapper: Mapper<T1, T2>,
    filterMapper?: FilterMapper<FilterInfoModel, any>
  ): PageInfoModel<T1> {
  const mapper = new PageInfoMapper<T1, T2>();

  const local = mapper.from(src);
  if (!local) { return null; }

  if (src.items) {
    local.items = modelMapper.fromArray(src.items);
  }

  if (src.numbers) {
    local.items = modelMapper.fromArray(src.numbers);
  }

  if (filterMapper) {
    local.filterInfo = filterMapper.from(src);
  }

  if (local.filterInfo && local.filterInfo.options) {
    local.total = local.filterInfo.options
      .reduce((sum, obj) => sum + +obj.count, 0);
  }

  return local;
}
