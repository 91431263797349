import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { clone } from '@helpers/utils/transformation.utils';

@Injectable({
  providedIn: 'root'
})
export class FormSnapshotService {

  form: FormGroup;
  model: any;

  private _formChanges: Subscription;
  private _snapshot: string;

  constructor() { }

  setModel(model: any) {
    this.model = clone(model);
    this.save();
  }

  init(form: FormGroup): void {
    this.form = form;

    if (this.model) {
      if (this._formChanges) {
        this._formChanges.unsubscribe();
      }
      this._formChanges = this.form.valueChanges
        .subscribe(() => this.mapFormToModel());
    } else {
      this.save();
    }
  }

  reset(): void {
    this.form = null;
    this.model = null;
    this._snapshot = null;
    if (this._formChanges) {
      this._formChanges.unsubscribe();
    }
  }

  save(): void {
    this._snapshot = this.takeSnapshot();
  }

  check(): boolean {
    const snapshot = this.takeSnapshot();
    return snapshot !== this._snapshot;
  }

  takeSnapshot(): string {
    if (this.form || this.model) {
      const value = this.model
        ? this.model
        : this.form.value;
      let snapshot = JSON.stringify(value);
      snapshot = snapshot.split('null').join('');
      snapshot = snapshot.split('"').join('');
      return snapshot;
    }
    return null;
  }

  mapFormToModel(): void {
    Object.keys(this.form.value).forEach(key => {
      if (this.model.hasOwnProperty(key)) {
        this.model[key] = this.form.value[key];
      }
    });
  }
}
