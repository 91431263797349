import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of, from, forkJoin, iif } from 'rxjs';
import { map, tap, mergeMap, switchMap, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { MediaFileType } from '@shared/constant/media/media-file-type.enum';
import { UploadMode } from '@shared/constant/media/upload-mode.enum';
import {
  FILE_ALREADY_UPLOADED_CONFIRMATION,
  METHOD_IS_NOT_IMPLEMENTED,
  MEDIA_FILE_IS_IN_USE
} from '@shared/constant/app-notification.constants';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { BaseService } from '@models/common/base-service.interface';
import { MediaFileServerModel } from '@models/server/storage/media-file-server.model';
import { PageInfoModel } from '@models/common/table-models/page-info.model';
import { convertPageInfo } from '@mappers/page-info.mapper';
import { MediaFileMapper } from '@mappers/storage/media-file.mapper';
import { getUrlByPageInfo } from '@helpers/utils/pageInfo.utils';
import { DeleteServerResponse } from '@models/server/responses/delete-response';
import { MediaFileFilterInfoMapper } from '@mappers/storage/media-file-filter-info.mapper';
import { DLG_RESULT_OK } from '@shared/constant/dialog.constants';
import { ModalService } from '@services/modal/modal.service';
import { StorageUploadResult, StorageUploadCode } from '@models/local/storage/storage-upload-result';
import { StorageDeleteResult } from '@models/local/storage/storage-delete-result';
import { ErrorResponseCode } from '@models/local/responses/code-error-response';
import { forceFileDownload } from '@helpers/utils/dom.utils';
import { ListItemModel } from '@models/common/list-item.model';
import { TranslateService } from '@services/translate/translate.service';
import { TtsRequestModel } from '@models/local/storage/tts-request.model';
import { RE_OBJECT_IN_USE } from '@shared/constant/regexp';


@Injectable({
  providedIn: 'root'
})
export class StorageService
  implements BaseService<MediaFileModel> {

  medidFiles: MediaFileModel[] = [];

  ttsGenders: ListItemModel[] = [
    { id: 1, code: 'Male' },
    { id: 2, code: 'Female' }
  ];

  ttsProviders: ListItemModel[] = [
    { id: 1, code: 'Yandex' },
    { id: 2, code: 'Google' }
  ];

  constructor(
    private _http: HttpClient,
    private _dialog: ModalService,
    private _translate: TranslateService
  ) {
    this._translate.register('tts-genders', this.ttsGenders, 'code');
    this._translate.register('tts-providers', this.ttsProviders, 'code');
  }

  // -- BaseService interface -------------------------------------------------

  get baseURL(): string {
    return `${environment.urlWithVersion}/account/file`;
  }

  getItems(pageInfo: PageInfoModel<MediaFileModel>): Observable<PageInfoModel<MediaFileModel>> {
    return this._http
      .get<PageInfoModel<MediaFileServerModel>>(`${this.baseURL}${getUrlByPageInfo(pageInfo)}`)
      .pipe(
        tap(res => console.log('res', res)),
        map(res => convertPageInfo(res, MediaFileMapper)),
        tap(res => res.filterInfo = MediaFileFilterInfoMapper.create()),
        tap(res => this.medidFiles = res.items)
      );
  }

  getItem(id: number): Observable<MediaFileModel> {
    return this._http
      .get<MediaFileServerModel>(`${this.baseURL}/${id}`)
      .pipe(
        map(res => MediaFileMapper.from(res))
      );
  }

  saveItem(model: any): Observable<MediaFileModel> {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }

  deleteItem(id: number): Observable<DeleteServerResponse> {
    return this._http
      .delete<DeleteServerResponse>(`${this.baseURL}/trash/${id}`);
  }

  getReferences(): Observable<any> {
    return of([]);
  }

  // -- General methods -------------------------------------------------------

  restoreItem(id: number): Observable<DeleteServerResponse> {
    return this._http
      .post<DeleteServerResponse>(`${this.baseURL}/trash/restore/${id}`, null);
  }

  purgeItem(id: number): Observable<DeleteServerResponse> {
    return this._http
      .delete<DeleteServerResponse>(`${this.baseURL}/delete/${id}`);
  }

  deleteAll(): Observable<DeleteServerResponse> {
    return this._http
      .delete<DeleteServerResponse>(`${this.baseURL}/delete/all`);
  }

  downloadFiles(files: MediaFileModel[]): Observable<MediaFileModel[]> {
    return forkJoin(
      from(files)
        .pipe(
          mergeMap(file => this.getItem(file.id)),
          tap(file => {
            const fileUrl = `/download/${file.downloadHash}`;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
            link.click();
          })
        )
    );
  }

  deleteFiles(files: MediaFileModel[]): Observable<StorageDeleteResult[]> {
    const files$ = [];
    for (const file of files) {
      const file$ = of(file)
        .pipe(
          mergeMap(f =>
            this.deleteItem(f.id)
              .pipe(
                map(res => {
                  if (this.isDeletionError(res)) {
                    throw {
                      internal: {
                        message: this.getDeletionErrorMessage(res, f)
                      }
                    };
                  } else {
                    return { file: f };
                  }
                }),
                catchError(error => of({ file: f, error: error.internal }))
              )
          )
        );
      files$.push(file$);
    }
    return forkJoin(...files$);
  }

  isDeletionError(err: any): boolean {
    return err && err.message && RE_OBJECT_IN_USE.test(err.message);
  }

  getDeletionErrorMessage(res: any, file: MediaFileModel): string {
    const matches = RE_OBJECT_IN_USE.exec(res.message);
    const module = this._translate.instant(matches[1]);
    const message = this._translate
      .instant(MEDIA_FILE_IS_IN_USE, { file: file.fileName, module: module, name: matches[2] });
    return message;
  }

  restoreFiles(files: MediaFileModel[]): Observable<StorageDeleteResult[]> {
    const files$ = [];
    for (const file of files) {
      const file$ = of(file)
        .pipe(
          mergeMap(f =>
            this.restoreItem(f.id)
              .pipe(
                map(() => ({ file: f })),
                catchError(error => of({ file: f, error: error.internal }))
              )
          )
        );
      files$.push(file$);
    }
    return forkJoin(...files$);
  }

  purgeFiles(files: MediaFileModel[]): Observable<StorageDeleteResult[]> {
    const files$ = [];
    for (const file of files) {
      const file$ = of(file)
        .pipe(
          mergeMap(f =>
            this.purgeItem(f.id)
              .pipe(
                map(() => ({ file: f })),
                catchError(error => of({ file: f, error: error.internal }))
              )
          )
        );
      files$.push(file$);
    }
    return forkJoin(...files$);
  }

  getFile(fileId: number): Observable<Blob> {
    return this._http
      .get(`${this.baseURL}/${fileId}`)
      .pipe(
        mergeMap((res: any) => this._http
          .get(
            `/download/${res.downloadHash}`,
            { responseType: 'blob' }
          )
        )
      );
  }

  downloadFile(fileId: number, fileName: string = 'file'): Observable<void> {
    return this
      .getFile(fileId)
      .pipe(
        map(data => forceFileDownload(data, fileName))
      );
  }

  textToSpeech(data: TtsRequestModel): Observable<MediaFileModel> {
    return this._http
      .post<MediaFileServerModel>(`${this.baseURL}/from-text`, data)
      .pipe(
        map(res => MediaFileMapper.from(res)),
      );
  }

  // -- Upload methods --------------------------------------------------------

  uploadFiles(files: File[], mediaType: MediaFileType): Observable<StorageUploadResult[]> {
    const files$ = [];
    for (const file of files) {
      const file$ = of(file)
        .pipe(
          mergeMap(f => this.checkFileExists(f, this.medidFiles)),
          mergeMap(f => this.uploadFile(f, mediaType, UploadMode.NEW)),
          map(f => ({ code: StorageUploadCode.SUCCESS, file: f, replaced: false })),
          catchError(error => {
            // 2 types of errors: server-side and file exists
            if (error.code === StorageUploadCode.FILE_EXISTS) {
              return this.showOverwriteDialog(error.message, error.params)
                .pipe(
                  mergeMap(res =>
                    iif(() => res === DLG_RESULT_OK,
                      this.uploadFile(error.file, mediaType),
                      throwError({ code: StorageUploadCode.SKIPPED })
                    )
                  ),
                  map(f => ({ code: StorageUploadCode.SUCCESS, file: f, originalFileId: error.originalFileId, replaced: true })),
                  catchError(e => of(e))
                );
            } else {
              return of(error);
            }
          })
        );
      files$.push(file$);
    }
    return forkJoin(...files$);
  }

  checkFileExists(file: File, files?: MediaFileModel[]): Observable<File> {
    if (files && files.some(f => f.fileName === file.name)) {
      const mediaFile = files.find(f => f.fileName === file.name);
      return throwError(this.createFileExistsError(file, mediaFile.id));
    }

    return this._http
      .get(`${this.baseURL}?filter[search]=${file.name}`)
      .pipe(
        switchMap((response: any) => {
          if (!response.items.some(f => f.fileName === file.name)) {
            return of(file);
          } else {
            const mediaFile = response.items.find(f => f.fileName === file.name);
            return throwError(this.createFileExistsError(file, mediaFile.id));
          }
        })
      );
  }

  uploadFile(file: File, type: MediaFileType, mode: UploadMode = UploadMode.REPLACE): Observable<MediaFileModel> {
    const request = new FormData();
    request.append('file', file);
    request.append('type', type);
    request.append('mode', mode);

    return this._http
      .post<MediaFileModel>(`${this.baseURL}`, request)
      .pipe(
        catchError(error => {
          throw(this.handleUploadServerError(error, file));
        })
      );
  }

  private createFileExistsError(file: File, fileId: number): StorageUploadResult {
    return {
      code: StorageUploadCode.FILE_EXISTS,
      file: file,
      originalFileId: fileId,
      message: FILE_ALREADY_UPLOADED_CONFIRMATION,
      params: { file: file.name }
    };
  }

  private handleUploadServerError(error: any, file: File): StorageUploadResult {
    if (error.internal && error.internal.code === ErrorResponseCode.IN_USE) {
      return {
        code: StorageUploadCode.FILE_IS_IN_USE,
        file: file,
        message: MEDIA_FILE_IS_IN_USE,
        params: {
          file: file.name,
          module: error.internal.object,
          name: error.internal.objectName
        }
      };
    } else if (error.internal.code === ErrorResponseCode.IS_LARGE) {

      return {
        code: StorageUploadCode.FILE_IS_LARGE,
        file: file,
        message: error.internal.messageTemplate,
        params: {
          size: error.internal.size,
          symbol: error.internal.symbol
        }
      };

    } else {

      return {
        code: StorageUploadCode.UNKNOWN,
        file: file,
        message: error.error.message ? error.error.message : error.message
      };

    }
  }

  showOverwriteDialog(message: string, params?: any): Observable<any> {
    return this._dialog
      .showConfirm({
        okBtn: { title: 'Overwrite', result: DLG_RESULT_OK },
        body: message,
        bodyParams: params
      });
  }
}
