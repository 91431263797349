export const RE_EMAIL = new RegExp(
    // источник паттерна https://emailregex.com/
    // но для унификации валидации электронного адреса с бэком

    // tslint:disable-next-line:max-line-length
   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const RE_SIMPLE_NAME = new RegExp(/^[a-z\u00BF-\u1FFF\u2C00-\uD7FF0-9]+$/i);
export const RE_NAME = new RegExp(/^([А-Яа-яA-Za-z\u00BF-\u1FFF\u2C00-\uD7FF0-9]([\-_.\' ']+)?)+$/i);
export const RE_REGISTRATION_USER_NAME = new RegExp(/^([А-Яа-яA-Za-z\u00BF-\u1FFF\u2C00-\uD7FF]([\-_.\' ']+)?)+$/i);

export const RE_IVR_NAME = new RegExp(/^([А-Яа-яA-Za-z\u00BF-\u1FFF\u2C00-\uD7FF0-9]([\-\.'\"\`\_\s\&]+)?)+$/i);

export const RE_NUMBER = new RegExp(/^\d+$/);
export const RE_PHONE = new RegExp(/^\d{6,16}$/m);
export const RE_SIMPLE_PHONE_EN = new RegExp(/^\d{10,16}$/);
export const RE_PHONE_RU = new RegExp(/^\+?7\s*[\(]{0,1}[0-9]{3,3}[\)]{0,1}\s*[-\s\.\/0-9]{1,9}$/);
export const RE_PHONE_RU_EXT = new RegExp(/[\(]{0,1}[0-9]{3,3}[\)]{0,1}\s*[-\s\.\/0-9]{1,11}$/);

export const RE_DATE_YMD = new RegExp(/^((19|20)\d{2})[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/);
export const RE_DATE_DMY_SLASH = new RegExp(/^((0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d{2})$/);
export const RE_DATE_DMY_POINT = new RegExp(/^((0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d{2})$/);
export const RE_DATE_DMY_DASH = new RegExp(/^((0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\d{2})$/);
export const RE_DATE_MDY = new RegExp(/^((0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[-](19|20)\d{2})$/);
export const RE_HOST = new RegExp('^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])(\\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9]))*$');

export const PHONE_NUMBER_PORT = new RegExp(
  // tslint:disable-next-line:max-line-length
  /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/m);

// export const WEEK_DAY_NAMES: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// Company pattern RegExps
export const RE_COMPANY_NAME = new RegExp(/^([a-z\u00BF-\u1FFF\u2C00-\uD7FF0-9]([\-_ ]+)?)+$/i);
export const RE_COMPANY_HOUSE = new RegExp(/^([a-z\u00BF-\u1FFF\u2C00-\uD7FF0-9]([\/ ]+)?)+$/i);
export const RE_COMPANY_OFFICE = RE_NUMBER;
export const RE_COMPANY_PHONE = RE_NUMBER;
export const RE_COMPANY_VAT_ID = new RegExp(/^[\A-Za-z\u00BF-\u1FFF\u2C00-\uD7FF0-9-_]+$/i);

// Call Rules pattern RegExps
export const RE_CALL_RULE_NAME = new RegExp(/^[a-zа-я\u00BF-\u1FFF\u2C00-\uD7FF0-9 '`\-_\.&]+$/i);

// Call Queues pattern RegExps
export const RE_CALL_QUEUE_NAME = RE_CALL_RULE_NAME;

// Extensions pattern RegExps
export const RE_EXTENSION_USER_NAME = new RegExp(/^[a-zа-я\u00BF-\u1FFF\u2C00-\uD7FF0-9 '`\-\.]+$/i);

export const RE_CONTACT_ADDRESS = new RegExp(/^([a-z\u00BF-\u1FFF\u2C00-\uD7FF0-9]([\-_.]+)?)+$/i);

// Address book
export const RE_ADDRESS_BOOK_NAME = new RegExp(/^[a-zа-я\u00BF-\u1FFF\u2C00-\uD7FF0-9 '`\-\.]+$/i);
export const RE_ADDRESS_BOOK_ADDRESS = new RegExp(/^[a-zа-я\u00BF-\u1FFF\u2C00-\uD7FF0-9 \-_\.]+$/i);
export const RE_ADDRESS_PHONE = new RegExp(/^[\d\+#]{6,16}$/m);

export const RE_OPTION_NAME = new RegExp(/^(.*?)\s*(\(\+?\d+\))?$/);

export const RE_FILENAME = new RegExp(/^(.+)\.([^\.]+)$/);

// General RegExps
export const RE_OPTION_WITH_COUNTER = new RegExp(/^(.+)\s+(\(\d+\))$/);
// export const RE_AMOUNT = new RegExp(/(?<=^| )\d+(\.\d+)?(?=$| )/); // Does not work in Mozilla - SyntaxError: invalid regexp group
export const RE_AMOUNT = new RegExp(/^\d+(\.\d+)$/);

export const RE_PASSWORD = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/);

export const RE_OBJECT_IN_USE = new RegExp(/^this object is used in (.+) named? (.+)$/i);
