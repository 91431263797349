export interface StorageFilterModel {
  type: string;
  search: string;
}

export enum StorageFilterType {
  AUDIO = 'audio',
  CALL_RECORD = 'call_record',
  TRASH = 'trash'
}
