import { FormArray } from '@angular/forms';
import { CallRuleActionType } from '@shared/constant/call-rule.constants';

export function isLastAction(formActions: FormArray): boolean {
  const len = formActions.length;
  if (len === 0) { return false; }
  return [
      CallRuleActionType.RING_GROUP_REDIRECTION,
      CallRuleActionType.CALL_TERMINATION
  ].includes(formActions.at(len - 1).value.action);
}

export function canPlayVoiceFileToGroup(formActions: FormArray, i): boolean {
  const len = formActions.length;
  if (len === 0 || i < 1) { return false; }
  const types = [
    CallRuleActionType.SIP_INNER_REDIRECTION,
    CallRuleActionType.SIP_OUTER_REDIRECTION
  ];
  return (types.includes(formActions.at(len - 1).value.action) && formActions.at(len - 1).get('parameter').value)
    || (types.includes(formActions.at(i - 1).value.action) && formActions.at(i - 1).get('parameter').value);
}
