import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import 'rxjs/add/operator/takeUntil';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { DadataResponse, DadataService, DadataSuggestion, DadataType } from '@services/dadata/dadata.service';


@Component({
  selector: 'etl-dadata',
  templateUrl: './etl-dadata.component.html',
  styleUrls: ['./etl-dadata.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlDadataComponent),
      multi: true
    }
  ]
})
export class EtlDadataComponent extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor {

  changeTrigger$: BehaviorSubject<string> = new BehaviorSubject('');
  data: DadataSuggestion[] = [];

  @Input() config: {
    type: DadataType;
    mode: 'suggest' | 'findById';
    count: number;
    parts?: string[];
  } = {
    type: DadataType.PARTY,
    mode: 'suggest',
    count: 5
  };

  @Output() selected: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatInput, { static: false }) input: MatInput;

  constructor(
    protected injector: Injector,
    private _service: DadataService
  ) {
    super(injector);
  }

  writeValue(obj: any): void {
    this.value = obj;

    if (this.changeTrigger$.observers && this.changeTrigger$.observers.length) {
      this.changeTrigger$.next(this.value);
    }
  }

  ngOnInit() {
    this.changeTrigger$.pipe(
      debounce(() => timer(500)),
    ).takeUntil(componentDestroyed(this)).subscribe(x => {
      if (x && x.length) {
        this._service.getData(x, this.config)
          .subscribe((y: DadataResponse) => {
            this.data = y.suggestions;
          });
      }
    });
  }

  onClick(e: MouseEvent, item: DadataSuggestion) {
    let val = this.value;
    switch (this.config.type) {
      case DadataType.PARTY:
        if ('inn' in item.data) {
          val = item.data.inn;
        }
        break;
      case DadataType.FIO:
        val = item.value;
        break;
      default:
        break;
    }
    this.data = [];
    this.value = val;
    this.onChange(this.value);
    this.selected.emit(item);
  }

  onInputChange(e: any): void {
    if (typeof e === 'undefined') {
      return;
    }
    if (typeof e === 'string') {
      this.changeTrigger$.next(e);
    }
    this.onChange(e);
  }

  setFocus(): void {
    this.focused = true;
    this.input.focus();
  }

  onFocus(event) {
    this.focused = true;
    this.focus.emit(event);
  }

  onBlur(event) {
    this.focused = false;
    this.blur.emit(event);
    this.onTouched();
  }

  clear() {
    this.input.ngControl.reset(null);
  }
}
