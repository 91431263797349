import { GuideEventType, GuideSrcElement, GuideEventHandler, GuideAction } from '../guide.enums';

export const CALL_QUEUE_CREATE_SCENARIO = [
  {
    intro: 'Welcome! We\'ll show you how to create a call queue',
    hint: true,
  },
  {
    element: 'button-create',
    intro: 'Click on the Create Queue button',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.CREATE_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.CALL_QUEUE_EDIT_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'queue-name',
    arrowTo: 'input',
    intro: 'Enter queue name',
    position: 'bottom-left',
    focus: true,
    canMove: false,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['name']
      }
    ]
  },
  {
    element: 'queue-description',
    arrowTo: 'input',
    intro: 'Enter description (optional field)',
    position: 'bottom-left',
    focus: true,
  },
  {
    element: 'queue-strategy',
    arrowTo: 'input',
    intro: 'Select call strategy from the drop-down list',
    position: 'bottom-right',
    focus: true,
    canMove: false,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['strategyId']
      }
    ]
  },
  {
    element: 'queue-ring-time',
    arrowTo: 'input',
    intro: 'Enter timeout',
    position: 'bottom-right',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['ringTime']
      }
    ]
  },
  {
    element: 'queue-max-callers',
    arrowTo: 'input',
    intro: 'Enter the maximum number of subscribers in the queue',
    position: 'bottom-right',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['maxCallers']
      }
    ]
  },
  {
    element: 'tab-members',
    intro: 'Click on the Users',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.CALL_QUEUE_MEMBERS_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'button-add-member',
    intro: 'Click on the Add member button',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.CALL_QUEUE_ADD_MEMBERS_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'table-add-members',
    intro: 'Select the queue participants by clicking on the line or on the checkbox',
    position: 'top-left',
    canMove: false,
    actions: [
      {
        handler: GuideAction.VALIDATE_TABLE_CHECKED
      }
    ]
  },
  {
    element: 'button-save-members',
    intro: 'Click on the Add button',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.ADD_MEMBERS_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.CALL_QUEUE_MEMBERS_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'button-save',
    intro: 'Click on the Save button',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.SAVE_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.CALL_QUEUE_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    tableElement: true,
    intro: 'The created call queue appeared in the table',
    position: 'bottom-left',
  },
  {
    intro: 'Come again, always happy to help ...',
    hint: true,
  },
];
