import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _sidenav: MatSidenav;

  public setNav(sidenav: MatSidenav) {
    this._sidenav = sidenav;
  }

  public open() {
    return this._sidenav.open();
  }

  public close() {
    return this._sidenav.close();
  }

  public toggle(): void {
    this._sidenav.toggle();
  }
}
