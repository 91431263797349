import { OnInit, Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CallRuleActionType } from '@shared/constant/call-rule.constants';
import { CallRuleModel } from '@models/local/call-rule/call-rule.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PhoneFormatterPipe } from '@shared/pipes/phone-formatter.pipe';
import { CallRulesService } from '@services/call-rules/call-rules.service';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';
import { CallRuleActionModel } from '@models/local/call-rule/call-rule-action.model';


@Component({
  selector: 'base-call-rule-sidebar',
  template: '',
})
export class BaseCallRuleSidebarComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:naming-convention
  CallRuleActionType: typeof CallRuleActionType = CallRuleActionType;

  modelEnabled: boolean;

  @Input() model: CallRuleModel;

  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() refreshData: EventEmitter<void> = new EventEmitter();

  protected service: CallRulesService | CrmIntegrationRulesService;

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected phoneFormatter: PhoneFormatterPipe
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      this.modelEnabled = this.model.enabled;
    }
  }

  // -- event handlers --------------------------------------------------------

  onToggleEnable(event: MatSlideToggleChange): void {
    this.modelEnabled = event.checked;
  }

  onCancel(): void {
    this.cancel.emit();
  }

  // -- general methods -------------------------------------------------------

  getActionName(type: number): string {
    const action = this.service.actions.find(a => a.id === type);
    if (action) {
      return action.code;
    }
    return null;
  }

  getActionParamName(type: number): string {
    switch (type) {
      case CallRuleActionType.SIP_INNER_REDIRECTION:
        return 'Extension';
      case CallRuleActionType.SIP_OUTER_REDIRECTION:
        return 'Phone number';
      case CallRuleActionType.CALL_QUEUE_REDIRECTION:
        return 'Queue';
      case CallRuleActionType.REDIRECT_TO_NEXT_QUEUE:
        return 'Queue';
      case CallRuleActionType.RING_GROUP_REDIRECTION:
        return 'Group';
      case CallRuleActionType.MEDIA_FILE_PLAYBACK:
        return 'Voice greeting';
      default:
        return null;
    }
  }

  getActionParamValue(action: CallRuleActionModel): any {
    switch (action.action) {
      case CallRuleActionType.SIP_INNER_REDIRECTION:
        return action.sipInnerNumber ? action.sipInnerNumber : '-';

      case CallRuleActionType.SIP_OUTER_REDIRECTION:
        return this.phoneFormatter.transform(action.parameter);

      case CallRuleActionType.CALL_QUEUE_REDIRECTION:
        const queue = this.service.callQueues
          .find(q => q.id === +action.parameter);
        return queue ? queue.name : '-';

      case CallRuleActionType.REDIRECT_TO_NEXT_QUEUE:
        const nextQueue = this.service.nextQueues
          .find(q => q.globalId === action.parameter);
        return nextQueue ? nextQueue.name : '-';

      case CallRuleActionType.RING_GROUP_REDIRECTION:
        if (this.service instanceof CallRulesService) {
          const group = this.service.ringGroups
            .find(q => q.id === +action.parameter);
          return group ? group.name : '-';
        }
        return '-';

      case CallRuleActionType.MEDIA_FILE_PLAYBACK:
      const file = this.service.mediaFiles
          .find(f => f.id === +action.parameter);
        return file ? file.fileName : '-';

      default:
        return null;
    }
  }
}
