import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationType, NotificationModel } from '@models/local/notification.model';

@Component({
  selector: 'app-etl-notification',
  templateUrl: './etl-notification.component.html',
  styleUrls: ['./etl-notification.component.sass']
})
export class EtlNotificationComponent implements OnInit {
  // tslint:disable-next-line:naming-convention
  MessageType: typeof NotificationType = NotificationType;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public message: NotificationModel,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }

  onClose(): void {
    this._snackBar.dismiss();
  }
}
