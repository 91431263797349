import { Component, OnInit, forwardRef, Renderer2, ElementRef, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'etl-checkbox',
  templateUrl: './etl-checkbox.component.html',
  styleUrls: [
    './etl-checkbox.component.sass',
    './etl-checkbox.dark-theme.component.sass',
    './etl-checkbox.white-theme.component.sass'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlCheckboxComponent),
      multi: true
    }
  ],
})
export class EtlCheckboxComponent
  extends EtlBaseFormControlComponent
  implements OnInit {

  @Input() disabled: boolean = false;
  @Input() comment: string;
  @Input() labelPosition: 'before' | 'after' = 'after';

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onChangeValue(event: MatCheckboxChange): void {
    this.onChange(event.checked);
    this.change.emit(event);
    this.focused = true;
    this.focus.emit(event);
  }

  onMouseLeave(event): void {
    if (this.focused) {
      this.focused = false;
      this.blur.emit(event);
    }
    this.mouseleave.emit(event);
  }
}
