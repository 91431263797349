import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { CabinetComponent } from '@modules/cabinet/cabinet.component';
import { SharedModule } from '@shared/shared.module';

const routes: Routes = [
  {
    path: 'cabinet',
    component: CabinetComponent,
  },
];

@NgModule({
  declarations: [
    CabinetComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class CabinetModule { }
