import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const SETTINGS_PROFILE_VALIDATION_MESSAGES = [
  {
    name: 'firstname',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the first name'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'First name must begin with a letter'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.MINLENGTH,
    message: 'First name is too short. Please use at least 2 characters'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'First name is too short. Please use at least 2 characters'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'First name is too long. Please don\'t use over 190 characters'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.PATTERN,
    message: 'First name contains invalid characters. You can only use letters, numbers and the following characters: \'-.'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'Last name must begin with a letter'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.MINLENGTH,
    message: 'Last name is too short. Please use at least 2 characters'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Last name is too short. Please use at least 2 characters'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Last name is too long. Please don\'t use over 190 characters'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.PATTERN,
    message: 'Last name contains invalid characters. You can only use letters, numbers and the following characters: \'-.'
  },
  {
    name: 'phone',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'Contact phone is too short. Please use at least 10 characters' : 'Contact phone is too short. Please use at least 11 characters'
  },
  {
    name: 'phone',
    error: ValidationErrorType.MAXLENGTH,
    message: isComVersion() ? 'Contact phone is too long. Please don\'t use over 16 characters' : 'Contact phone is too long. Please don\'t use over 11 characters'
  },
  {
    name: 'phone',
    error: ValidationErrorType.PATTERN,
    message: 'Contact phone contains invalid characters. You can use numbers only'
  },
  {
    name: 'phone',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter phone number'
  },
];
