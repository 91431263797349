import { Injectable } from '@angular/core';
import { TranslateService } from '@services/translate/translate.service';

import { ValidationFormName, VALIDATION_MESSAGES } from '@shared/constant/form-validation-messages';
import { FormValidationMessages } from '@models/local/validation/form-validation-messages';
import { CustomValidationMessage } from '@models/local/validation/custom-validation-message';


@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {

  private _validationMessages: FormValidationMessages;

  constructor(
    private _translate: TranslateService
  ) {
    this._validationMessages = VALIDATION_MESSAGES;
  }

  /**
   * @description
   * Returns custom validation messages for the form by specified key.
   * @param key - form key
   */
  getByFormKey(key: ValidationFormName): CustomValidationMessage[] {
    return this._validationMessages[key];
  }

  /**
   * @description
   * Sets custom validation message for specified form control and error type.
   * @param key - form key
   * @param name - form control name
   * @param type - validation error type
   * @param message - validation message
   * @param params - (optional) validation message translation params
   */
  setMessage(key: ValidationFormName, name: string, type: string, message: string, params?: any): void {
    const messages = this.getByFormKey(key);
    if (messages) {
      const src = messages.find(m => m.name === name && m.error === type);
      if (src) {
        src.trKey = message;
        src.params = params;
      } else {
        messages.push({
          name: name,
          message: message,
          trKey: message,
          error: type,
          params: params
        });
      }
    }
    this._translate.register('validation-messages', messages, 'message');
  }

  /**
   * @description
   * Sets custom validation messages.
   * @param name - form control name
   * @param messages - custom validation messages
   */
  setMessages(key: ValidationFormName, messages: CustomValidationMessage[]): void {
    messages.forEach(
      m => this.setMessage(key, m.name, m.error, m.message, m.params)
    );
  }

  /**
   * @description
   * Return default validation error message for the specified control name by
   * validation error key.
   * @param controlName - form control name
   * @param errorKey - validation error key
   * @param errors - array of control's validation errors
   */
  getDefaultMessage(controlName: string, errorKey: string, errors: any): string {
    const ctrlName: string = this._translate
      .instant(this.normalizeControlName(controlName));

    if (errorKey === 'required') {
      const szPleaseEnter: string = this._translate.instant('Please enter the');
      return `${szPleaseEnter} ${ctrlName}`;
    } else if (errorKey === 'minlength') {
      const pluralEnd: string = errors.minlength.requiredLength > 1 ? 's' : '';
      const szCharacters: string = this._translate.instant(`character${pluralEnd}`);
      const szTooShort: string = this._translate.instant('is too short. Please use at least');
      return `${ctrlName} ${szTooShort} ${errors.minlength.requiredLength} ${szCharacters}`;
    } else if (errorKey === 'trimmedLength') {
      const pluralEnd: string = errors.trimmedLength.requiredLength > 1 ? 's' : '';
      const szCharacters: string = this._translate.instant(`character${pluralEnd}`);
      const szTooShort: string = this._translate.instant('is too short. Please use at least');
      return `${ctrlName} ${szTooShort} ${errors.trimmedLength.requiredLength} ${szCharacters}`;
    } else if (errorKey === 'maxlength') {
      const pluralEnd: string = errors.maxlength.requiredLength > 1 ? 's' : '';
      const szCharacters: string = this._translate.instant(`character${pluralEnd}`);
      const szCantContain: string = this._translate.instant('can\'t contain over');
      return `${ctrlName} ${szCantContain} ${errors.maxlength.requiredLength} ${szCharacters}`;
    } else if (errorKey === 'pattern') {
      const szPleaseEnterValid: string = this._translate.instant('Please enter valid');
      return `${szPleaseEnterValid} ${ctrlName}`;
    } else if (errorKey === 'phoneMinLength') {
      const pluralEnd: string = errors.phoneMinLength.value > 1 ? 's' : '';
      const szCharacters: string = this._translate.instant(`character${pluralEnd}`);
      const szPleaseEnterValid: string = this._translate.instant('phone is too short. Please use at least');
      return `${ctrlName} ${szPleaseEnterValid} ${errors.phoneMinLength.value} ${szCharacters}.`;
    }

    const szInvalidDefault: string = this._translate.instant('The value is invalid');
    return szInvalidDefault;
  }

  /**
   * @description
   * Returns normalized form of a given string.
   * @param name - string to be normalized
   * @returns - normalized form of a given string
   */
  private normalizeControlName(name: string): string {
    let normalized = name.toLowerCase();
    normalized = normalized.replace(/\s+\*\s*$/, '');
    normalized = normalized.replace(/(\.\d+)/, '');
    normalized = normalized[0].toUpperCase() + normalized.slice(1);
    return normalized;
  }
}
