import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Route } from '@angular/router';
import {tap, map, catchError, take} from 'rxjs/operators';
import { Observable, BehaviorSubject, interval, forkJoin, Subject, of } from 'rxjs';
import 'rxjs/add/operator/takeUntil';

import { environment } from '@env/environment';
import { TOKEN, CURRENT_USER, LAST_SESSION_INFO, MAIN_MENU, USER_LANG } from '@shared/constant/storage.const';
import { IdleService } from './idle-service.service';
import { ServerTokenModel } from '@models/server/user/server.token.model';
import { WebSocketService } from '@services/web-socket/web-socket.service';
import { UserDataModel } from '@models/local/user/user-data.model';
import { UserDataMapper } from '@mappers/user/user-data.mapper';
import { UserDataServerModel } from '@models/server/user/user-data-server.model';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { MainMenuMapper } from '@mappers/menu/main-menu.mapper';
import { NotificationService } from '@services/notification/notification.service';
import { MainMenuModel } from '@models/local/main-menu/main-menu.model';
import { AUTHORIZED_SESSION_MESSAGE } from '@shared/constant/app-notification.constants';
import { ModalService } from '@services/modal/modal.service';
import { TranslateService } from '@services/translate/translate.service';
import {Md5} from 'ts-md5';



@Injectable()
export class AuthenticationService {

  private _url = `${environment.back}/`;
  private _urlWithVersion = `${environment.back}/${environment.backApiVersion}/`;
  private _userToken: BehaviorSubject<ServerTokenModel>;
  public currentUser: BehaviorSubject<UserDataModel>;
  private _timerUpdateToken$: Observable<any>;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  public navigationMenu: BehaviorSubject<MainMenuModel[]>;

  constructor(
    private _http: HttpClient,
    private _storage: BrowserStorageService,
    private _router: Router,
    private _idleService: IdleService,
    private _wsService: WebSocketService,
    private _notification: NotificationService,
    private _modal: ModalService,
    private _translate: TranslateService,
  ) {
    this._userToken = new BehaviorSubject<ServerTokenModel>(this._storage.readItem(TOKEN));
    this.currentUser = new BehaviorSubject<UserDataModel>(this._storage.readItem(CURRENT_USER));
    this.navigationMenu = new BehaviorSubject<MainMenuModel[]>(this._storage.readItem(MAIN_MENU));
    if (this.isAuthenticated) {
      this.initWebSoketsAndIdle();
      setTimeout(() => this.initMenuAndUserData().pipe(take(1)).subscribe(), 0);
    }
  }

  private validateTokenAndIdle() {
    const token = new BehaviorSubject<ServerTokenModel>(this._storage.readItem(TOKEN));
    const nextGenarationToken = new Date(token.getValue().lastUpdate).getTime() +
      (token.getValue().expiresIn - 600) * 1000;
    if (nextGenarationToken < new Date().getTime()) {
      // this.updateToken().pipe(take(1)).subscribe();
    }
  }

  refreshToken() {
    return this._http.post<ServerTokenModel>
    (`${this._url}refresh-token`, { refreshToken: this.token.refreshToken });
  }

  saveToken(token: ServerTokenModel) {
    if (this.isAuthenticated) {
      token.accessTokenInline = Md5.hashStr(token.accessToken).toString();
      token.lastUpdate = new Date();
      this._storage.writeItem(TOKEN, token);
      this._userToken.next(token);
    }
  }

  updateToken(): Observable<ServerTokenModel> {
    return this._http.post<ServerTokenModel>(`${this._url}refresh-token`, { refreshToken: this.token.refreshToken }).pipe(
      tap(res => {
        if (this.isAuthenticated) {
          res.accessTokenInline = Md5.hashStr(res.accessToken).toString();
          res.lastUpdate = new Date();
          this._storage.writeItem(TOKEN, res);
          this._userToken.next(res);
        }
      }),
      // catchError(res => {
      //   this.logout();
      //   console.warn(res);
      //   return of(res);
      // })
    );
  }

  public get token(): ServerTokenModel {
    return this._userToken.getValue();
  }

  public get user(): UserDataModel {
    return this.currentUser.getValue();
  }

  public get isAuthenticated(): boolean {
    return !!this.token;
  }

  public get isAdmin(): boolean {
    if (this.user) {
      return 'tariffPlan' in this.user;
    }
  }

  get isBlocked() {
    return this.user && this.user.isBlocked;
  }

  signIn(userName: string, password: string): Observable<ServerTokenModel> {
    const loginModel = {
      login: userName,
      password,
    };
    if (environment.subdomain) {
      loginModel['loginHash'] = environment.loginHash;
    }
    return this._http
      .post<ServerTokenModel>(this._url + 'login', loginModel)
      .pipe(
        tap(res => {
          if (res.auth) {
            this._router.navigate(['/code-confirmation', res.hash]);
          } else {
            this.initToken(res);
          }
        })
      );
  }

  initUserInfo(): Observable<UserDataModel> {
    return this._http.get<UserDataServerModel>(`${this._urlWithVersion}account/info`)
      .pipe(
        map(response => {
          const userData = UserDataMapper.from(response);
          this._storage.writeItem(CURRENT_USER, userData);
          this.currentUser.next(userData);
          this._translate.use(response.user_lang);
          this._storage.writeItem(USER_LANG, response.user_lang);
          return userData;
        })
      );
  }

  initUserNavigation(): Observable<MainMenuModel[]> {
    return this._http.get<any>(`${this._urlWithVersion}nav`)
      .pipe(
        map(res => {
          const mainMenu = MainMenuMapper.from(res.items);

          // mainMenu.push({
          //   icon: '/assets/icons/_menu/audio_conference.svg',
          //   menuType: 0,
          //   path: 'transfer',
          //   title: 'Transfer with Support',
          // });

          this.navigationMenu.next(mainMenu);
          this._storage.writeItem(MAIN_MENU, mainMenu);
          return mainMenu;
        })
      );
  }

  logout() {
    this._storage.clear();
    // this._idleService.end();
    this._modal.reset();
    this._userToken.next(null);
    this._destroy$.next(true);
    this.currentUser.next(null);
    this._wsService.close();
    const lang = this._storage.readItem('user_lang');
    this._translate.init(lang);
    location.replace('/sign-in');
  }

  initToken(token: ServerTokenModel) {
    if (!token.accessToken) {
      return;
    }
    token.accessTokenInline = Md5.hashStr(token.accessToken).toString();
    token.lastUpdate = new Date();
    this._storage.writeItem(TOKEN, token);
    this._userToken.next(token);
    this.initWebSoketsAndIdle();

    this.initMenuAndUserData().takeUntil(this._destroy$).subscribe(() => {
      this.redirectToUserPage();
    });
  }

  initWebSoketsAndIdle() {
    this._wsService.initSocket();
    this._wsService.authenticate(this.token.accessToken);
    // this._idleService.start();
    this._timerUpdateToken$ = interval(5000);
    this._timerUpdateToken$.takeUntil(this._destroy$).subscribe(() => {
      this._userToken.next(this._storage.readItem(TOKEN));
      if (this._userToken.getValue()) {
        this.validateTokenAndIdle();
      } else {
        this.logout();
      }
    });
    this._wsService.logOut$.takeUntil(this._destroy$).subscribe(() => {
      // if (this.token.accessToken !== token) { TODO: при рефреше вылогиневает
      this.logout();
      // this._idleService.end();
      this._notification.error(AUTHORIZED_SESSION_MESSAGE);
      // }
    });
    this._wsService.balance$.takeUntil(this._destroy$).subscribe(() => {
      this.currentUser.next(this._storage.readItem(CURRENT_USER) as UserDataModel);
    });
    this._wsService.service$.takeUntil(this._destroy$).subscribe((data) => {
      if (this.isAuthenticated) {
        this.initMenuAndUserData().subscribe();
      }
    });
  }

  initMenuAndUserData() {
    return forkJoin([this.initUserInfo(), this.initUserNavigation()]);
  }

  redirectToUserPage() {
    const url = this._storage.readItem(LAST_SESSION_INFO);
    const menu = this.navigationMenu.getValue();
    let urlSegments = url ? url.split('/') : [];
    urlSegments = urlSegments[urlSegments.length - 1];
    if (menu.some(x => x.path && x.path.indexOf(urlSegments) > -1)) {
      if (url && url.startsWith('/cabinet/')) {
        this._router.navigateByUrl(url);
        return;
      }
    }
    this._router.navigateByUrl('/cabinet');
  }
}

