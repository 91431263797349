import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormArray, FormGroup } from '@angular/forms';

import { CallRulesService } from '@services/call-rules/call-rules.service';
import { CallRuleActionType } from '@shared/constant/call-rule.constants';
import { ActionEvent } from '@models/local/call-rule/action-event';
import { canPlayVoiceFileToGroup, isLastAction } from '@helpers/utils/call-rules.utils';
import { ListItemModel } from '@models/common/list-item.model';
import { EtlSelectNewComponent } from '@elements/etl-select-new/etl-select-new.component';
import { ValidationService } from '@services/validation/validation.service';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';


@Component({
  selector: 'call-rules-action-list',
  templateUrl: './call-rules-action-list.component.html',
  styleUrls: [
    './call-rules-action-list.component.sass',
    './call-rules-action-list.dark-theme.component.sass',
    './call-rules-action-list.white-theme.component.sass',
  ]
})
export class CallRulesActionListComponent implements OnInit {
  // tslint:disable-next-line:naming-convention
  CallRuleActionType: typeof CallRuleActionType = CallRuleActionType;
  filteredActions: ListItemModel[];

  @Input() form: FormGroup;
  @Input() service: CallRulesService | CrmIntegrationRulesService;
  @Input() activationEnabled = true;

  @Output() actionAdd: EventEmitter<ActionEvent> = new EventEmitter<ActionEvent>();
  @Output() actionChange: EventEmitter<ActionEvent> = new EventEmitter<ActionEvent>();
  @Output() actionDelete: EventEmitter<ActionEvent> = new EventEmitter<ActionEvent>();
  @Output() toggleEnable: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();

  @ViewChild('newAction', { static: false }) newActionCtrl: EtlSelectNewComponent;
  @ViewChildren(EtlSelectNewComponent) actionCtrls: QueryList<EtlSelectNewComponent>;

  // -- properties ------------------------------------------------------------

  get formActions(): FormArray {
    return <FormArray>this.form.controls['ruleActions'];
  }

  actions(index): ListItemModel[] {
    return canPlayVoiceFileToGroup(this.formActions, index) ?
      this.service.actions :
      this.filteredActions;
  }

  get isLastAction(): boolean {
    return isLastAction(this.formActions);
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _validation: ValidationService
  ) { }

  ngOnInit() {
    this.filteredActions = this.service.actions.filter((action) => action.id !== this.CallRuleActionType.PLAY_VOICE_FILE_TO_PARTY);
    this.resetNewAction(false);
  }

  // -- event handlers --------------------------------------------------------

  onActionAdd(event: any): void {
    this.actionAdd.emit({ action: event.id });
    this.resetNewAction();
  }

  onActionChange(event: any, index: number): void {
    this.actionChange.emit({ action: event.id, index: index });
    this.resetNewAction();
  }

  onActionDelete(_: MouseEvent, index: number): void {
    this.actionDelete.emit({ index: index });
    this.resetNewAction();
  }

  onToggleEnable(event: MatCheckboxChange): void {
    this.toggleEnable.emit(event);
  }

  // -- component methods -----------------------------------------------------

  resetNewAction(setFocus: boolean = true): void {
    // this.actions = canPlayVoiceFileToGroup(this.formActions) ? this.service.actions :
    //   this.service.actions.filter((action) => action.id !== this.CallRuleActionType.PLAY_VOICE_FILE_TO_PARTY);

    this.form.controls['lastAction'].reset();
    this.form.controls['lastAction'].setValue(this.isLastAction ? true : null);
    if (this.newActionCtrl) {
      this.newActionCtrl.clearFocus();
    }
    if (setFocus) {
      setTimeout(() => {
        let action: EtlSelectNewComponent;
        this.actionCtrls.forEach(ctrl => {
          if (!ctrl.formControlName) { action = ctrl; }
        });
        if (action) {
          action.setFocus(true);
        }

        this.form.controls['lastAction'].markAsUntouched();
        this.form.controls['lastAction'].setErrors(null);
        this.form.controls['lastAction'].updateValueAndValidity();
        this._validation.lastFocusedControl = '';
        this._validation.clearErrorsVisibility();
        this._validation.updateValidationState();
      }, 0);
    }
  }
}
