import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { CallRulesService } from '@services/call-rules/call-rules.service';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { StorageService } from '@services/storage/storage.service';
import { MediaFileType } from '@shared/constant/media/media-file-type.enum';
import { NotificationService } from '@services/notification/notification.service';
import { StorageUploadCode } from '@models/local/storage/storage-upload-result';
import { MEDIA_FILE_REPLACE_COMPLETION, MEDIA_FILE_UPLOAD_COMPLETION } from '@shared/constant/app-notification.constants';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';


@Component({
  selector: 'media-file-playback',
  templateUrl: './media-file-playback.component.html',
  styleUrls: ['./media-file-playback.component.sass']
})
export class MediaFilePlaybackComponent implements OnInit, OnDestroy {

  private _forceUploadUpdate: boolean = false;

  @Input() form: FormGroup;
  @Input() index: number;
  @Input() service: CallRulesService | CrmIntegrationRulesService;

  // -- properties ------------------------------------------------------------

  get mediaFiles(): MediaFileModel[] {
    return this.service.mediaFiles;
  }

  get formParameter(): any {
    return this.form
      .get(['ruleActions', this.index])
      .get('parameter')
      .value;
  }

  set formParameter(value: any) {
    setTimeout(() => {
      this.form
      .get(['ruleActions', this.index])
      .get('parameter')
      .setValue(value);
    }, 0);
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _storage: StorageService,
    private _notification: NotificationService  ) { }

  ngOnInit(): void {
    // Subscribe to replace media file notifications. When media file is selected
    // replaced file id should be updated in form.
    this.service.mediaFileReplaced$
      .takeUntil(componentDestroyed(this))
      .subscribe((info: any) => {
        if (this._forceUploadUpdate || this.formParameter && +this.formParameter === info.originalId) {
          this.formParameter = info.updatedId;
          this._forceUploadUpdate = false;
        }
      });
  }

  ngOnDestroy(): void { }

  // -- event handlers --------------------------------------------------------

  onUpload(files: File[]): void {
    this._storage
      .uploadFiles(files, MediaFileType.AUDIO)
      .subscribe(
        (res: any) => {
          switch (res[0].code) {
            case StorageUploadCode.SUCCESS:
              const message = res[0].replaced
                ? MEDIA_FILE_REPLACE_COMPLETION
                : MEDIA_FILE_UPLOAD_COMPLETION;
              this._notification.success(message, { file: res[0].file.fileName });
              this.refreshMediaFiles(res[0].file, res[0].originalFileId);
              break;
            case StorageUploadCode.SKIPPED:
              break;
            default:
              this._notification.error(res[0].message, res[0].params);
          }
        }
      );
  }

  onSpeechCreate(mediaFile: MediaFileModel): void {
    this.service.mediaFiles.push(mediaFile);
    this.formParameter = mediaFile.id;
}

  // -- common methods --------------------------------------------------------

  refreshMediaFiles(uploadedFile: MediaFileModel, originalFileId?: number): void {
    this.service
      .getMediaFiles(true)
      .subscribe(() => {
        if (originalFileId) {
          this._forceUploadUpdate = true;
          this.service.mediaFileReplaced$
            .next({ originalId: originalFileId, updatedId: uploadedFile.id });
        } else {
          this.formParameter = uploadedFile.id;
        }
      });
  }
}
