import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { EtlNotificationComponent } from '@elements/etl-notification/etl-notification.component';
import { Subject } from 'rxjs';
import { TranslateService } from '@services/translate/translate.service';
import { NotificationModel, NotificationType } from '@models/local/notification.model';
import { NOTIFICATION_MSG_SHOW_TIME } from '@shared/app-settings/app.settings';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _queue: NotificationModel[] = [];
  private _nextMessage$: Subject<NotificationModel> = new Subject();
  
  constructor(
    private _snackBar: MatSnackBar,
    private _translate: TranslateService,
  ) {
    this._nextMessage$.subscribe(() => {
      if (this._queue.length > 0) {
        this.show(this._queue[0]);
      }
    });
  }

  success(message: string, params?: any): void {
    this.addQueue(NotificationType.SUCCESS, message, params);
  }

  error(message: string, params?: any): void {
    this.addQueue(NotificationType.ERROR, message, params);
  }

  private addQueue(notificationType: NotificationType, message: string, params?: any) {
    if (!message) {
      message = 'Server error';
    }
    if (params) {
      Object.keys(params)
        .forEach(k => params[k] = `<span>${params[k]}</span>`);
    }
    this._queue.push({
      type: notificationType,
      text: this._translate.instant(message, params),
    });
    if (this._queue.length === 1) {
      this._nextMessage$.next();
    }
  }

  private show(msgModel: NotificationModel): void {
    // msgModel.text = AppMessages[msgModel.type] ? AppMessages[msgModel.text] : msgModel.text;

    setTimeout(() => {
      this._snackBar.openFromComponent(EtlNotificationComponent, {
        data: msgModel,
        duration: NOTIFICATION_MSG_SHOW_TIME,
        verticalPosition: 'top'
      }).afterDismissed().subscribe(() => {
        this._queue.splice(0, 1);
        this._nextMessage$.next();
      });
    }, 200);
  }
}
