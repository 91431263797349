export interface Lockable {
  locker: Locker;
}

export class Locker {
  private _lockCount: number;
  
  get free(): boolean {
      return this._lockCount === 0;
  }

  constructor() {
      this._lockCount = 0;
  }

  public lock(): void {
      this._lockCount ++;
  }

  public unlock(): void {
      this._lockCount --;
      if (this._lockCount < 0) {
        this._lockCount = 0;
      }
  }
}
