// tslint:disable-next-line:naming-convention
export const environment = {
  production: false,
  backApiVersion: 'v1',
  translation: '/translate/translations',
  back: 'http://front-ett-test.encry.ru/front-api',
  urlWithVersion: 'http://front-ett-test.encry.ru/front-api/v1',
  ws: 'ws.front-ett-test.encry.ru',
  defaultCountry: 78,
  version: '1.1.0',
  guidesEnabled: false,
  subdomain: false,
  loginHash: '',
  defaultLang: 'ru',
  defaultTheme: 'white'
};
