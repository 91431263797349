import { Component, ViewChild, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, tap, mergeMap } from 'rxjs/operators';

import { TranslateService } from '@services/translate/translate.service';
import { MediaPlayerService } from '@services/media-player/media-player.service';
import { EtlMediaPlayerComponent } from '@elements/etl-media-player/etl-media-player.component';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { APP_TITLE } from '@shared/app-settings/app.settings';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { GuideService } from '@services/guide/guide.service';
import { EtlGuideComponent } from '@elements/etl-guide/etl-guide.component';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {

  routerSubscription: Subscription;

  @ViewChild('mediaPlayerContainer', { read: ViewContainerRef, static: true })
  private _mediaContainer: ViewContainerRef;

  @ViewChild('guideContainer', { read: ViewContainerRef, static: true })
  private _guideContainer: ViewContainerRef;

  constructor(
    private _translate: TranslateService,
    private _storage: BrowserStorageService,
    private _mediaService: MediaPlayerService,
    private _title: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private _guide: GuideService
  ) {
    const lang = this._storage.readItem('user_lang');
    this._translate.init(lang);

    if (this._authService.isAuthenticated) {
      this._authService.navigationMenu.takeUntil(componentDestroyed(this)).subscribe(val => {
        if (!val) {
          this._authService.initUserNavigation().subscribe();
        }
      });
    }

    // TODO: Информация об аккаунте должна обновляться каждый раз если в сторе нет информации о пользователе или он не верифицирован
    // чтобы каждый раз информация была актульна и интерфейс разблокировался вовремя.
    // этот код - это костыль, необходимо как то его переписать на велосепед
    if (this._authService.isAuthenticated && (!this._authService.user || this._authService.user.verificationStatus === 0)) {
      this._authService.currentUser.next(null);
      this._authService.initUserInfo().subscribe(res => {
        this._router.navigate(['cabinet']);
      });
    }
  }

  ngOnInit(): void {
    this._mediaService
      .createComponent(EtlMediaPlayerComponent, this._mediaContainer);

    this._guide
      .createComponent(EtlGuideComponent, this._guideContainer);

      this.routerSubscription = this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(
        data => {
          if (data['title']) {
            this._title.setTitle(`${APP_TITLE} | ${this._translate.instant(data['title'])}`);
          }
        }
      );

      this._translate.onLangChange.takeUntil(componentDestroyed(this)).pipe(
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
        .subscribe(
          data => this._title.setTitle(`${APP_TITLE} | ${this._translate.instant(data['title'])}`)
        );
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
