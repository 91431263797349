import { Injectable, Type, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { environment } from '@env/environment';
import { EtlGuideComponent } from '@elements/etl-guide/etl-guide.component';
import { GUIDE_INFO, GUIDE_SCENARIOS } from '@models/common/guide/guide.constants';
import { GuideModel } from '@models/common/guide/guide.model';
import { GuideEvent } from '@models/common/guide/guide.interfaces';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class GuideService {

  private _currentRoute: string;
  private _guidePages: string[];

  private _container: ViewContainerRef;
  private _component: EtlGuideComponent;

  guide: GuideModel = new GuideModel(this._user);

  state$: Subject<boolean> = new Subject();

  constructor(
    private _router: Router,
    private _resolver: ComponentFactoryResolver,
    private _user: UserService
  ) {
    this._guidePages = GUIDE_INFO.map(x => x.startPage);

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(
        (event: NavigationEnd) => this._currentRoute = event.url
      );

    this.guide.events$
      .subscribe(event => {
        if (event && this[event]) { 
          this[event]();
        }
      });
  }

  get enabled(): boolean {
    return environment.guidesEnabled
      && this._guidePages.includes(this._currentRoute);
  }

  get active(): boolean {
    return this.guide.active;
  }

  start(): void {
    const info = GUIDE_INFO.find(x => x.startPage === this._currentRoute);
    if (info) {
      this.state$.next(true);
      this.guide.init(GUIDE_SCENARIOS[info.key], info);
      this._component.start();
    }
  }

  stop(): void {
    this.state$.next(false);
    this._component.stop();
  }

  loadScenario(): any[] {
    const guideKey = GUIDE_INFO.find(x => x.startPage === this._currentRoute).key;
    return GUIDE_SCENARIOS[guideKey];
  }

  createComponent(type: Type<EtlGuideComponent>, container: ViewContainerRef): void {
    this._container = container;
    this._container.clear();

    const factory = this._resolver
      .resolveComponentFactory(type);
    const componentRef: ComponentRef<EtlGuideComponent> = this._container
      .createComponent(factory);

    this._component = componentRef.instance;
    this._component.service = this;
  }

  // -- events ----------------------------------------------------------------

  notify(event: GuideEvent): void {
    this.guide.notify(event);
  }

  showTooltip(): void {
    this._component.showTooltip();
  }

  hideTooltip(): void {
    this._component.hideTooltip();
  }
}
