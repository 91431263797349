import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  state: boolean = false;
  private _value: any;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  set value($value) {
    this._value = $value;
  }

  get value() {
    return this._value;
  }
}
