import { Component, OnInit, Output, EventEmitter, Input, HostListener, OnDestroy, TemplateRef } from '@angular/core';

import { killEvent } from '@helpers/utils/event.utils';
import { MediaFileType } from '@shared/constant/media/media-file-type.enum';
import { SUPPORTED_IMAGE_FORMATS, SUPPORTED_AUDIO_FORMATS, MIME_TYPES } from '@shared/constant/media/supported-media-formats';
import { NotificationService } from '@services/notification/notification.service';
import { ACCEPTED_FORMATS_ERROR, FILE_EXCEEDS_LIMIT_ERROR } from '@shared/constant/app-notification.constants';
import { MediaSelectorType } from '@shared/constant/media/media-selector-type.enum';
import { BehaviorSubject } from 'rxjs';
import { componentDestroyed } from '@helpers/utils/component.utls';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'media-files-uploader',
  templateUrl: './media-files-uploader.component.html',
  styleUrls: ['./media-files-uploader.component.sass']
})
export class MediaFilesUploaderComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:naming-convention
  MediaFileType: typeof MediaFileType = MediaFileType;
  // tslint:disable-next-line:naming-convention
  MediaSelectorType: typeof MediaSelectorType = MediaSelectorType;

  acceptedExtensions: string[] = [];
  acceptedMediaTypes: string[] = [];

  activeState: boolean = false;

  screenWidth: number;
  private _screenWidth$ = new BehaviorSubject<number>(window.innerWidth);

  @Input() mediaSelectorType: MediaSelectorType = MediaSelectorType.BUTTON;
  @Input() mediaType: MediaFileType = MediaFileType.IMAGE;
  @Input() acceptedFormats: string;
  @Input() selectedImage: string | ArrayBuffer;

  @Input() buttonText: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() disabled: boolean = false;
  @Input() sizeLimitMb: number = 0;

  @Input() iconTpl: string | TemplateRef<void> | undefined;

  @Output() upload: EventEmitter<File[]> = new EventEmitter();
  @Output() uploadFile: EventEmitter<File> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._screenWidth$.next(event.target.innerWidth);
  }
  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _notification: NotificationService
  ) { }

  ngOnInit() {
    this.setAcceptedData();
    this._screenWidth$.pipe(takeUntil(componentDestroyed(this))).subscribe(width => {
      this.screenWidth = width;
    });
  }

  ngOnDestroy() {
  }

  // -- event handlers --------------------------------------------------------

  onUpload(event?: Event): void {
    if (!event) { return; }
    killEvent(event);

    const element = event.target as HTMLInputElement;
    const files = Array.from(element.files);
    this.uploadFiles(files);

    element.value = null;
  }

  onDdrop(event: DragEvent): void {
    event.preventDefault();
    if (this.disabled) { return; }

    let files: File[];
    if (this.mediaType === MediaFileType.IMAGE) {
      files = [ event.dataTransfer.files[0] ];
    } else {
      files = Array.from(event.dataTransfer.files);
    }
    this.uploadFiles(files);

    this.activeState = false;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    if (this.disabled) { return; }
    this.activeState = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    if (this.disabled) { return; }
    this.activeState = false;
  }

  // -- general methods -------------------------------------------------------

  setAcceptedData(): void {
    if (this.mediaType && !this.acceptedFormats) {
      switch (this.mediaType) {
        case MediaFileType.IMAGE:
          this.acceptedFormats = SUPPORTED_IMAGE_FORMATS;
          break;
        case MediaFileType.AUDIO:
          this.acceptedFormats = SUPPORTED_AUDIO_FORMATS;
          break;
      }
    }

    this.acceptedExtensions = [];
    this.acceptedMediaTypes = [];
    this.acceptedFormats
      .replace(/\s+/g, '')
      .split(',')
      .forEach(fmt => {
        const mimeType = MIME_TYPES.find(t => t.format === fmt);
        if (mimeType) {
          this.acceptedExtensions = [
            ...new Set([...this.acceptedExtensions, ...mimeType.extensions])
          ];
          this.acceptedMediaTypes = [
            ...new Set([...this.acceptedMediaTypes, ...mimeType.types])
          ];
        }
      });
  }

  checkCompatibility(files: File[]): boolean {
    let result = true;
    files.forEach(file => {
      result = result && this.acceptedMediaTypes.includes(file.type);
    });
    return result;
  }

  uploadFiles(files: File[]): void {
    const acceptefFiles = files
      .filter(f => this.acceptedMediaTypes.includes(f.type));

    if (files.length !== acceptefFiles.length) {
      this._notification.error(
        ACCEPTED_FORMATS_ERROR,
        { formats: this.acceptedExtensions.join(', ') }
      );
      if (!acceptefFiles.length) {
        return;
      }
    }

    if (this.sizeLimitMb) {
      let sizeTotal = acceptefFiles.reduce((sum, f) => sum += f.size, 0);
      sizeTotal /= (1024 * 1024);
      if (sizeTotal > this.sizeLimitMb) {
        this._notification.error(
          FILE_EXCEEDS_LIMIT_ERROR,
          { size: this.sizeLimitMb }
        );
        return;
      }
    }

    if (this.mediaType === MediaFileType.IMAGE) {
      this.uploadImage(acceptefFiles[0]);
      return;
    }

    this.upload.emit(acceptefFiles);
  }

  uploadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImage = reader.result;
    };
    reader.readAsDataURL(file);
    this.uploadFile.emit(file);
  }
}
