import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { environment as env } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _renderer: Renderer2;
  currentThem: string;

  constructor(
    private _storage: BrowserStorageService,
    private _cookieService: CookieService,
    private _rendererFactory: RendererFactory2) {
    this._renderer = _rendererFactory.createRenderer(null, null);
    const theme = this._cookieService.get('pbx_theme') || _storage.readItem('theme') || env.defaultTheme;
    this.setTheme(theme);
  }

  setTheme(theme: string) {
    this._renderer.removeClass(document.querySelector('app-root'), 'dark');
    this._renderer.removeClass(document.querySelector('app-root'), 'white');
    this._renderer.addClass(document.querySelector('app-root'), theme);
    this._storage.writeItem('theme', theme);
    this._cookieService.set('pbx_theme', theme);
    this.currentThem = theme;
  }
}
