import { Component, Output, EventEmitter, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { filter, tap } from 'rxjs/operators';

import { SHORT_DEBOUNCE_TIME } from '@shared/app-settings/app.settings';
import { takeSnapshot } from '@helpers/utils/form.utils';
import { METHOD_MUST_BE_OVERRIDDEN } from '@shared/constant/app-notification.constants';
import { FilterInfoOptionModel } from '@models/common/table-models/filter-info.model';
import { TranslateService } from '@services/translate/translate.service';


@Component({
  selector: 'app-etl-base-filter',
  template: ''
})
export class EtlBaseFilterComponent<TFilterModel>
  implements OnInit, OnChanges, OnDestroy {

  form: FormGroup;

  protected snapshot: string;
  protected debounceTime: number = SHORT_DEBOUNCE_TIME;

  @Input() options: FilterInfoOptionModel[];

  @Output() filterChange: EventEmitter<TFilterModel> = new EventEmitter();

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.formChanges()
      .subscribe(() => {
        this.filterChange.emit(this.form.value);
      });
  }

  ngOnDestroy(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.translate.register('base-filter-options', changes.options.currentValue, 'title');
    }
  }

  // -- general methods -------------------------------------------------------

  formChanges(): Observable<TFilterModel> {
    this.snapshot = takeSnapshot(this.form.value);

    return this.form.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        filter(value => this.snapshot !== takeSnapshot(value)),
        tap(_ => this.snapshot = takeSnapshot(this.form.value))
      );
  }

  createForm(): void {
    throw new Error(`createForm() ${METHOD_MUST_BE_OVERRIDDEN}`);
  }

  reset(): void {
    this.form.reset();
  }
}
