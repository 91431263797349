import { Injectable } from '@angular/core';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { Subject, Observable, of, Observer } from 'rxjs';

import { TranslateService } from '@services/translate/translate.service';
import { ValidationMessageService } from '@services/validation/validation-message.service';
import { Locker } from '@models/common/locker';
import { ControlValidationInfo } from '@models/local/validation/control-validation-info';
import { CustomValidationMessage } from '@models/local/validation/custom-validation-message';
import { ControlErrorInfo } from '@models/local/validation/control-error-info';
import { ModalService } from '@services/modal/modal.service';
import { DLG_RESULT_OK } from '@shared/constant/dialog.constants';
import { FormSnapshotService } from '@services/forms/form-snapshot.service';
import { CanFormComponentDeactivate } from '@shared/guards/can-deactivate-form.guard';
import { LEAVE_EDIT_FORM_CONFIRMATION, LEAVE_EMPTY_FORM_CONFIRMATION } from '@shared/constant/app-notification.constants';
import { map } from 'rxjs/operators';
import { ControlVisibilityState } from '@models/local/validation/control-visibility-state';

@Injectable({
  providedIn: 'root'
})
export class ValidationService implements CanFormComponentDeactivate {

  /**
   * @description
   * Flag specifies whether the service in active cycle state (i.e. handle 
   * form control statuses) or not.
   */
  active: boolean = false;
  locker: Locker = new Locker();

  form: FormGroup;
  controls: ControlValidationInfo[] = [];
  visibleStates: ControlVisibilityState[] = [];
  
  serverErrors: any;
  private _customMessages: CustomValidationMessage[] = [];
  get customMessages(): CustomValidationMessage[] {
    return this._customMessages;
  }
  set customMessages(value: CustomValidationMessage[]) {
    this._customMessages = value;
    this._translate.register('validation-messages', this._customMessages, 'message');
  }

  controlError: ControlErrorInfo;
  lastFocusedControl: string;

  invalidControlsChanges$: Subject<void> = new Subject();
  errorVisibilityChanges$: Subject<void> = new Subject();
  scrollToFirstError$: Subject<string> = new Subject();
  reInitDirective$: Subject<string> = new Subject();

  // -- properties ------------------------------------------------------------

  /**
   * @description
   * Find form control with visible error state.
   */
  get visibleErrorControl(): string {
    const control = this.controls.find(c => c.errorVisible);
    return control ? control.name : null;
  }

  // -- component lifecycle methods -------------------------------------------

  constructor(
    private _validationMessageService: ValidationMessageService,
    private _translate: TranslateService,
    private _dialog: ModalService,
    private _snapshotService: FormSnapshotService
  ) { }

  // -- initialization --------------------------------------------------------

  /**
   * @description
   * Adds form element to the validation cycle processing and starts handling
   * controls validation states (active state).
   * @param form - form element
   * @param customMessages - array of custom validation messages that overrides
   * default validation messages
   */
  init(form: FormGroup, customMessages?: CustomValidationMessage[]): void {
    this.form = form;
    if (customMessages) {
      this.customMessages = customMessages;
    }
    this.listen();
  }

  /**
   * @description
   * Scans form element for the controls that have Validator specicifed and 
   * creates control's validation state map.
   */
  initControlsValidationMap(): void {
    this.controls = [];
    this.scanForm(this.form, '', (control, name) => {
      if (control.validator) {
        this.controls.push(
          { name: name, error: false, errorVisible: false, visible: true, focused: false, mouseOver: false }
        );
      }
    });
  }

  /**
   * @description
   * Sets visible controls list.
   * @param controlStates - controls visibility states
   */
  setVisibleControls(controlStates: ControlVisibilityState[]): void {
    controlStates.forEach(state => {
      const localState = this.visibleStates.find(s => s.name === state.name);
      if (localState) {
        localState.visible = state.visible;
      } else {
        this.visibleStates.push(state);
    }
    });

    this.controls.forEach(control => {
      const state = controlStates.find(s => s.name === control.name);
      if (state) {
        control.visible = state.visible;
      }
    });

    if (this.controls
      .some(c => c.name === this.lastFocusedControl && !c.visible)) {
      this.lastFocusedControl = null;
    }
  }

  /**
   * @description
   * Resets service variables. Method is called in ngOnDestroy of the component
   * or directive.
   */
  reset(): void {

    this.stop();

    this.form = null;
    this.controls = [];
    // this._customMessages = [];
    this.visibleStates = [];
  }

  /**
   * @description
   * Endpoint to trigger etlValidation directive to update elements
   * subscriptions when form controls changed.
   */
  reInitDirective(): void {
    this.reInitDirective$.next();
  }

  // -- event handlers --------------------------------------------------------

  /**
   * @description
   * Focus event handler. Sets focused flag in controls validation map for 
   * the specified control. If control has server error assigned, error is
   * cleared.
   * @param controlName - form control name
   */
  onControlFocus(controlName: string): void {
    const control = this.getControl(controlName);
    if (control) { 
      control.focused = true;
      this.lastFocusedControl = control.name;
      this.controls.forEach(c => {
        if (c.name !== controlName) { c.focused = false; }
      });
    }
    this.clearServerError(controlName);
    this.updateValidationState();
  }

  /**
   * @description
   * Blur event handler. Resets focused flag in controls validation map for
   * the specified control.
   * @param controlName - form control name
   */
  onControlBlur(controlName: string): void {
    const control = this.getControl(controlName);
    if (control) { 
      control.focused = false;
    }
    this.updateValidationState();
  }

  /**
   * @description
   * MouseEnter event handler. Sets mouseOver flag in controls validation map
   * for the specified control.
   * @param controlName - form control name
   */
  onControlMouseOver(controlName: string): void {
    const control = this.getControl(controlName);
    if (control) { 
      control.mouseOver = true; 
    }
    this.updateValidationState();
  }

  /**
   * @description
   * MouseLeave event handler. Resets mouseOver flag in controls validation map
   * for the specified control.
   * @param controlName - form control name
   */
  onControlMouseOut(controlName: string): void {
    const control = this.getControl(controlName);
    if (control) { 
      control.mouseOver = false; 
    }
    this.updateValidationState();
  }

  // -- validation lifecycle --------------------------------------------------

  /**
   * @description
   * Starts form validation cycle.
   */
  listen(): void {
      if (this.form) {
          this.initControlsValidationMap();
      }
      this.active = true;
  }

  /**
   * @description
   * Stops form validation cycle.
   */
  stop(): void {
    this.active = false;
  }

  /**
   * @description
   * Determines control, which error should be shown at the current moment and 
   * sets error visibility flag.
   */
  updateValidationState(): void {
    if (!this.active) { return; }

    this.updateControlValidationStates();

    let control = this.controls
      .find(c => c.mouseOver && c.visible);
    if (control && control.error) {
      this.setErrorVisible(control.name);
      return;
    }

    control = this.controls
      .find(c => c.focused && c.visible);
    if (control) {
      if (control.error) {
        this.setErrorVisible(control.name);
        return;
      } else {
        this.clearErrorsVisibility();
        return;
      }
    }

    const controlName = this.findVisibleErrorControl();
    if (controlName) {
      this.setErrorVisible(controlName);
    } else {
      this.clearErrorsVisibility();
    }
  }

  /**
   * @description
   * Updates controls validation state and sends invalidControlsChanges event.
   */
  private updateControlValidationStates(): void {
    this.scanForm(this.form, '', (formControl, name) => {
      const control = this.controls.find((c) => c.name === name);
      if (control) {
        control.error = this.checkControlInvalidState(name, formControl);
      }
    });
    this.invalidControlsChanges$.next();
  }

  /**
   * @description
   * Checks whether the specified control error can be shown. Error shows when
   * server control error exists or related form control is INVALID state.
   * @param controlName - form control name
   * @param control - form control
   * @returns - true when control in error state
   */
  private checkControlErrorState(controlName: string, control: AbstractControl): boolean {
    const serverError: boolean = this.serverErrors && this.serverErrors[controlName];
    const formError: boolean = !control.valid && control.errors && (control.touched || control.dirty);
    return serverError || formError;
  }

  /**
   * @description
   * Checks whether control is under server error or input validation occured.
   * @param controlName - form control name
   * @param control - form control
   * @returns - true when control in validation error
   */
  private checkControlInvalidState(controlName: string, control: AbstractControl): boolean {
    const serverError: boolean = this.serverErrors && !!this.serverErrors[controlName];
    const formError: boolean = !control.valid && (control.touched || control.dirty);
    return serverError || formError;
  }

  /**
   * @description
   * Sets error visible for specified form control. Fires errorVisibilityChanges
   * event.
   * @param controlName - form control name
   */
  private setErrorVisible(controlName: string): void {
    const error: ControlErrorInfo = this.getControlError(controlName);
    if (error
        && (!this.controlError 
          || error.controlName !== this.controlError.controlName
          || error.errorKey !== this.controlError.errorKey
          || !this.controls.find((c) => c.errorVisible))
        ) {
      this.controlError = error;

      this.clearErrorsVisibility();

      this.locker.lock();
      setTimeout(() => {
        this.locker.unlock();
        if (this.locker.free) {
          const control = this.controls.find((c) => c.name === controlName);
          if (control) { 
            control.errorVisible = true;
          }
          this.errorVisibilityChanges$.next();
        }
      }, 0);
    }
  }

  /**
   * @description
   * Hides error visibility for each form control. Fires errorVisibilityChanges
   * event.
   */
  clearErrorsVisibility(): void {
    this.controls.forEach(control => control.errorVisible = false);
    this.errorVisibilityChanges$.next();
  }

  /**
   * @description
   * Determines form's first invalid control name.
   * @return - first invalid control name
   */
  private findVisibleErrorControl(): string {
    let control: ControlValidationInfo;
    if (this.lastFocusedControl) {
      control = this.controls.find(c => 
        c.name === this.lastFocusedControl && c.visible);
      if (control && control.error) {
        return control.name;
      }
    }

    control = this.controls.find(c => c.error && c.visible);
    if (control) {
      return control.name;
    }
    
    return null;
  }

  /**
   * @description
   * Sends scroll to first error notification to etlValidation directives.
   */
  emitScrollToFirstError(): void {
    const control = this.controls.find(c => c.error);
    if (control) {
      this.scrollToFirstError$.next(control.name);
    }
  }

  // -- server errors ---------------------------------------------------------

  /**
   * @description
   * Sets an object of server side errors. Errors object keys are control names
   * and values are error messages.
   * @param errors - object of server side errors
   */
  setServerErrors(errors: any): void {
    this.serverErrors = errors;
    if (!errors || !Object.keys(errors).length) { return; }
    
    Object.keys(this.serverErrors).forEach(name => {
      const control: ControlValidationInfo = this.controls.find((c) => c.name === name);
      if (control) { control.error = true; }
    });

    this.updateValidationState();
  }

  /**
   * @description
   * Resets server side error for specified control.
   * @param controlName - form control name
   */
  clearServerError(controlName: string): void {
    if (this.serverErrors && this.serverErrors[controlName]) {
      this.serverErrors[controlName] = undefined;
    }
  }

  // -- helpers ---------------------------------------------------------------
  
  /**
   * @description
   * Gets validation info for the specified control.
   * @param controlName - form control name
   * @returns - control's validation info
   */
  private getControl(controlName: string): ControlValidationInfo {
    return this.controls.find(c => c.name === controlName);
  }

  /**
   * @description
   * Iterates through each form control and calls callback function for each 
   * iteration step.
   * @param form - reactive form instance
   * @param parent - parent form element key, uses as prefix for the current control name
   * @param action - iteration callback
   */
  private scanForm(form: FormGroup | FormArray, parent: string = '', action: (control: AbstractControl, name: string) => void): void {
    if (!form) { return; }

    Object.keys(form.controls).forEach(ctrlName => {
      const name = (parent) ? `${parent}.${ctrlName}` : ctrlName;
      const control = form.get(ctrlName);
      action(control, name);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.scanForm(control, name, action);
      }
    });
  }
  
  // -- error messages --------------------------------------------------------

  /**
   * @description
   * Gets control current error information. Error determines the following way:
   * if server side error exists it returns, otherwise the first validation error
   * returns.
   * @param controlName - form control name
   * @returns - control error information
   */
  getControlError(controlName: string): ControlErrorInfo {
    if (this.serverErrors && this.serverErrors[controlName]) {
      return { controlName: controlName, errorKey: 'server' };
    }

    let errorKey: string = null;
    this.scanForm(this.form, '', (control, name) => {
      if (name === controlName && control.errors) {
        const errorKeys = Object.keys(control.errors);
        errorKey = errorKeys[0];
      }
    });
    if (errorKey) {
      return { controlName: controlName, errorKey: errorKey };
    }
    return null;
  }

  /**
   * @description
   * Gets validation error message for the specified form control.
   * @param controlName - form control name
   * @returns - validation error message
   */
  getErrorMessage(controlName: string): string {
    if (this.serverErrors && this.serverErrors[controlName]) {
      return this._translate.instant(this.serverErrors[controlName]);
    }

    let errorMessage: string = null;
    this.scanForm(this.form, '', (control, name) => {
      if (name === controlName && control.errors) {
        const errorKeys = Object.keys(control.errors);
        errorMessage = this
          .getValidationMessage(controlName, errorKeys[0], control.errors);
      }
    });

    return errorMessage;
  }

  /**
   * @description
   * Gets validation error message for the specified form control by validation
   * error key.
   * @param controlName - form control name
   * @param errorKey - validation error key
   * @param errors - array of all form control validation errors
   * @returns - validation error message
   */
  private getValidationMessage(controlName: string, errorKey: string, errors: any): string {
    const customMessage = this.getCustomValidationMessage(controlName, errorKey);
    if (customMessage) {
      return customMessage;
    }

    return this._validationMessageService
      .getDefaultMessage(controlName, errorKey, errors);
  }

  /**
   * @description
   * Gets custom validation message string for the specified control and error key.
   * @param controlName - form control name
   * @param errorKey - validation error key
   * @returns - custom validation message string
   */
  private getCustomValidationMessage(controlName: string, errorKey: string): string {
    // FormArrays validation keys look like:
    // - formName.arrayName.${index: number}.fieldName i.e. 'myForm.phones.0.extension'
    // but customMessages should contain corresponding key in the following format:
    // - formName.arrayName.*.fieldName i.e. 'myForm.phones.*.extension'
    if (this._customMessages) {
      const validationKey = controlName.replace(/\.\d{1,}\./, '.*.');
      const item = this._customMessages
        .find(m => m.name === validationKey && m.error === errorKey);
      if (item) {
        return item.message;
      }
    }
    return null;
  }

  // -- CanFormComponentDeactivate implementation -----------------------------

  canDeactivate(): Observable<boolean> {
    if (!this.form || !this.checkFormChanged()) {
      return of(true);
    }
    return this
      .showExitDialog()
      .pipe(
        map(result => result === DLG_RESULT_OK)
      );
  }

  canClose(): Observable<boolean> {
    if (!this.form || !this.checkFormChanged()) {
      return of(true);
    }

    return new Observable((observer: Observer<boolean>) => {
      this
        .showExitDialog()
        .subscribe(result => {
          if (result === DLG_RESULT_OK) {
            observer.next(true);
          } else {
            observer.error(null);
          }
          observer.complete();
        });
    });
  }

  checkFormChanged(): boolean {
    return this._snapshotService.check();
  }

  private showExitDialog(): Observable<any> {
    return this._dialog
      .showConfirm({ 
        okBtn: { title: 'Yes', result: DLG_RESULT_OK },
        body: this.form.value.id && this.form.value.id > 0
          ? LEAVE_EDIT_FORM_CONFIRMATION
          : LEAVE_EMPTY_FORM_CONFIRMATION
      });
  }
}
