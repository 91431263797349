import { Mapper } from '@mappers/mapper';
import { BalanceModel } from '@models/local/balance/balance.model';
import { BalanceServerModel } from '@models/server/balance/balance-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';

// tslint:disable-next-line:naming-convention
export let BalanceMapper: Mapper<BalanceModel, BalanceServerModel> = {
  from(obj: BalanceServerModel): BalanceModel {
    if (!obj) { return null; }

    return {
      symbol: obj.symbol,
      value: obj.balance,
      minutes: obj.minutes
    };
  },

  to(obj: BalanceModel): BalanceServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
