import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@env/environment';
import {
  TranslateService as NgxTranslateService,
  TranslateStore,
  TranslateLoader,
  TranslateCompiler,
  TranslateParser,
  MissingTranslationHandler
} from '@ngx-translate/core';

import { EtlTranslateLoader } from '@shared/loaders/etl-translate.loader';
import { TranslatableInfo } from '@models/local/translate/translatable-info';
import { Translatable } from '@models/local/translate/translatable.model';
import { clone } from '@helpers/utils/transformation.utils';
import { RE_OPTION_WITH_COUNTER } from '@shared/constant/regexp';
import { Object } from 'core-js';


@Injectable({
  providedIn: 'root'
})
export class TranslateService
  extends NgxTranslateService {

  private _translatables: TranslatableInfo[] = [];
  private _renderer: Renderer2;

  constructor(
    store: TranslateStore,
    currentLoader: TranslateLoader,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingTranslationHandler: MissingTranslationHandler,
    private _rendererFactory: RendererFactory2
  ) {
    super(store, currentLoader, compiler, parser, missingTranslationHandler);
    this._renderer = _rendererFactory.createRenderer(null, null);

    this.onLangChange.subscribe(transl => {
      this._translatables.forEach(tr => this.update(tr));
      this.setTranslateClass(transl.lang);
    });
  }

  init(lang: string): void {
    (<EtlTranslateLoader>this.currentLoader).loadTranslations().subscribe((res) => {
      this.setDefaultLang(environment.defaultLang);
      lang = lang || environment.defaultLang;
      this.use(lang);
    });
  }

  register<T extends Translatable>(id: string, array: T[], key: string, sortable: boolean = false, params?: any): void {
    if (!array) { return; }

    array
      .filter(item => !item.trKey)
      .forEach(item => item.trKey = item[key]);

    let info: TranslatableInfo;
    if (params && params.dictKey && params.paramKey) {
      info = { id: id, key: key, items: array, sortable: sortable, dictKey: params.dictKey, paramKey: params.paramKey };
    } else {
      info = { id: id, key: key, items: array, sortable: sortable };
    }

    this.addTranslatable(info);
  }

  private addTranslatable(info: TranslatableInfo): void {
    const entry = this._translatables.find(tr => tr.id === info.id);
    if (!entry) {
      this._translatables.push(info);
    }
    this.update(info);
  }

  private update(info: TranslatableInfo): void {
    if (info.dictKey && info.paramKey) {
      info.items.forEach(item => {
        const params = {};
        params[info.paramKey] = item.trKey;
        item[info.key] = this.instant(item[info.dictKey], params);
      });
    } else {
      info.items.forEach(item => {
        if (item.params) {
          const params = clone(item.params);
          Object.keys(params).forEach(key => {
            params[key] = this.instantEx(params[key]);
          });
          item[info.key] = this.instantEx(item.trKey, params);
        } else {
          item[info.key] = this.instantEx(item.trKey);
        }
      });
    }
    if (info.sortable) {
      info.items.sort((a, b) => (a[info.key] > b[info.key]) ? 1 : -1);
    }
  }

  instantEx(key: string, params?: any): string {
    if (params) {
      Object
        .keys(params)
        .forEach(pkey => {
          params[pkey] = this.instant(params[pkey]);
        });
    }

    if (RE_OPTION_WITH_COUNTER.test(key)) {
      const matches = RE_OPTION_WITH_COUNTER.exec(key);
      const text = this.instant(matches[1], params);
      return `${text} ${matches[2]}`;
    } else {
      return this.instant(key, params);
    }
  }

  setTranslateClass(lang) {
    this._renderer.removeClass(document.querySelector('app-root'), 'en');
    this._renderer.removeClass(document.querySelector('app-root'), 'ru');
    this._renderer.addClass(document.querySelector('app-root'), lang);

    document.querySelector('html').setAttribute('lang', lang);
  }
}
