import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '@components/dialogs/confirm-dialog/confirm-dialog.component';
import { ModalDataModel, ModalSize } from '@models/common/modal-data/modal-data.model';
import { DLG_RESULT_OK, DLG_RESULT_CANCEL } from '@shared/constant/dialog.constants';
import { TranslateService } from '@services/translate/translate.service';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _dlgRef: any;

  defaultOptions: ModalDataModel = {
    body: 'Are you sure?',
    cancelBtn: { title: 'Cancel', result: DLG_RESULT_CANCEL },
    okBtn: { title: 'Ok', result: DLG_RESULT_OK },
    title: 'Confirm',
    showDialogActions: true,
    size: ModalSize.MD
  };

  constructor(
    private _dialog: MatDialog,
    private _translate: TranslateService
  ) { }

  showConfirm(options?: ModalDataModel): Observable<any> {
    const dialogData = Object.assign({}, this.defaultOptions, options);

    if (dialogData.bodyParams) {
      Object.keys(dialogData.bodyParams)
        .forEach(k => dialogData.bodyParams[k] = `<span>${dialogData.bodyParams[k]}</span>`);
    }
    dialogData.body = this._translate.instant(dialogData.body, dialogData.bodyParams);

    this._dlgRef = this._dialog
      .open(ConfirmDialogComponent, { data: dialogData, panelClass: dialogData.size});

    return this._dlgRef
      .afterClosed()
      .pipe(
        tap(() => this._dlgRef = null)
      );
  }

  reset(): void {
    if (this._dlgRef) {
      this._dlgRef.close();
      this._dlgRef = null;
    }
  }
}
