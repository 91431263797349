import {Component, OnInit, Input, ViewChild, HostListener} from '@angular/core';
import { MenuType, MainMenuModel } from '@models/local/main-menu/main-menu.model';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '@services/navigation/navigation.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass']
})
export class NavigationComponent implements OnInit {

  itemType = MenuType;
  screenWidth: number;
  private _screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  // @ts-ignore
  @ViewChild('sidenav') public sidenav: MatSidenav;
  @Input() mainMenu: MainMenuModel[];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._screenWidth$.next(event.target.innerWidth);
  }

  constructor(public navService: NavigationService) {
  }

  ngOnInit(): void {
    setTimeout(() => this.navService.setNav(this.sidenav), 0);
    this._screenWidth$.subscribe(width => {
      this.screenWidth = width;
    });
  }
}
