import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'playerTime',
  pure: true
})
export class PlayerTimePipe implements PipeTransform {

  transform(value: any): string {
    const secTotal = parseInt(value, 10);
    const hours = Math.floor(secTotal / 3600) % 24;
    const minutes = Math.floor(secTotal / 60) % 60;
    const seconds = secTotal % 60;
    return [hours, minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .filter((v, i) => v !== '00 ' || i > 0)
      .join(':');
  }
}
