import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Field } from '@models/server/api-settings/api-setting';

export function validateFormControls(form: FormGroup | FormArray): void {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    control.markAsTouched({ onlySelf: true });
    control.markAsDirty({ onlySelf: true });
    if (control instanceof FormGroup || control instanceof FormArray) {
      validateFormControls(control);
    }
  });
}

export function extractServerValidationMsg(err: HttpErrorResponse): any {
  const result = {};
  if (err.error && err.error.message.includes('Form validate error(s)') && err.error) {
    const errors = err.error.errors;
    Object.keys(errors).forEach(e => {
      result[e] = errors[e][0];
    });
  }
  if (Object.keys(result).length > 0) {
    return result;
  }
  return null;
}

export function takeSnapshot(value: any): string {
  let snapshot = JSON.stringify(value);
  snapshot = snapshot.split('null').join('');
  snapshot = snapshot.split('"').join('');
  return snapshot;
}

export function toFormData(obj: any, form?: FormData, namespace?: string): FormData {
  if (!obj) { return; }
  form = form || new FormData();

  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      const formKey = namespace
        ? `${namespace}[${key}]`
        : key;
      if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
        form = toFormData(obj[key], form);
      } else if (obj[key] instanceof Date) {
        form.append(formKey, obj[key].toISOString());
      } else {
        form.append(formKey, (typeof obj[key] === 'string') ? obj[key].trim() : obj[key]);
      }
    }
  });

  return form;
}

/*
*   Эта функция клон функции toFormData() , но с возможностью обработки массивов.
* */
export function toFormDataWithArrayFilesSupport(obj: any, form?: FormData, namespace?: string): FormData {
  if (!obj) {
    return;
  }
  form = form || new FormData();

  Object.keys(obj).forEach(key => {

    if (obj[key]) {
      const formKey = namespace
        ? `${namespace}[${key}]`
        : key;
      if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach(
            (arrayItem: any, arrayItemIndex: number) => {
              form.append(key + '[]', obj[key][arrayItemIndex]);
            }
          );
        } else {
          form = toFormData(obj[key], form);
        }
      } else if (obj[key] instanceof Date) {
        form.append(formKey, obj[key].toISOString());
      } else {
        form.append(formKey, (typeof obj[key] === 'string') ? obj[key].trim() : obj[key]);
      }
    }
  });

  return form;
}

export function trimFormValue(form: FormGroup, name: string): void {
  if (form.controls[name].value) {
    form.controls[name]
      .setValue(
        form.controls[name].value.toString().trim()
      );
  }
}
export const getDirtyValues = (form: any) => {
  const dirtyValues = {};

  Object.keys(form.controls)
    .forEach(key => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

  return dirtyValues;
};

export const makeFormByField = (data: Field[]) => {
  const form = new FormGroup({});
  data.forEach(x => {
    if (x.type !== 'collection') {
      form.addControl(x.name, new FormControl(x.defaultValue));
      form.addControl(`${x.name}Default`, new FormControl(x.defaultValue));
    } else {
      const formArray = new FormArray([]);
      formArray.push(makeFormByField(x.values));
      form.addControl(x.name, formArray);
    }
  });
  return form;
};
