export interface MainMenuModel {
  path?: string;
  title?: string;
  icon?: string;
  disabled?: boolean;
  menuType: MenuType;
}

export enum MenuType {
  ITEM,
  DIVIDER
}
