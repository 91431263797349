import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, filter } from 'rxjs/operators';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { ServerTokenModel } from '@models/server/user/server.token.model';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private _refreshTokenInProgress = false;
  private _refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthenticationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request);
    }

    const token: ServerTokenModel = this.authService ? this.authService.token : undefined;
    const nextGenarationToken = token && new Date(token.lastUpdate).getTime() + (token.expiresIn - 600) * 1000;

    if (token && nextGenarationToken >= new Date().getTime()) {
      return next.handle(this.injectToken(request));
    }

    if (token && nextGenarationToken < new Date().getTime()) {
      if (!this._refreshTokenInProgress) {
        this._refreshTokenInProgress = true;
        this._refreshTokenSubject.next(null);
        return this.authService.refreshToken().pipe(
          switchMap((res) => {
            this.authService.saveToken(res);
            this._refreshTokenSubject.next(res);
            return next.handle(this.injectToken(request));
          }),
        );
      } else {
        return this._refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request));
          })
        );
      }
    }

    return next.handle(this.injectToken(request));
  }

  private injectToken(request: HttpRequest<any>) {
    const token: ServerTokenModel = this.authService ? this.authService.token : undefined;
    let req = request;
    if (token && request.url.indexOf('refresh-token') === -1) {
      req = request.clone({
        headers: request.headers.append('Authorization', `Bearer ${token.accessToken}`)
      });
    }
    return req;
  }
}
