import { Mapper } from '@mappers/mapper';
import { CallRuleModel } from '@models/local/call-rule/call-rule.model';
import { CallRuleServerModel } from '@models/server/call-rule/call-rule-server.model';
import { CallRuleActionMapper } from './call-rule-action.mapper';
import { SipMapper } from '@mappers/sip/sip.mapper';

// tslint:disable-next-line:naming-convention
export let CallRuleMapper: Mapper<CallRuleModel, CallRuleServerModel> = {
  
  from(obj: CallRuleServerModel): CallRuleModel {
    if (!obj) { return null; }

    const mod = new CallRuleModel();
    mod.id = obj.id;
    mod.name = obj.name;
    mod.description = obj.description;
    mod.enabled = obj.enabled;
    mod.sipId = obj.sip ? obj.sip.id : null;
    mod.sip = SipMapper.from(obj.sip);
    mod.ruleActions = CallRuleActionMapper.fromArray(obj.ruleActions);

    return mod;
  },

  fromArray(arr: CallRuleServerModel[]): CallRuleModel[] {
    if (!arr) { return null; }
    return arr.map(a => this.from(a));
  },
  
  to(obj: CallRuleModel): CallRuleServerModel {
    if (!obj) { return null; }
    
    return {
      id: obj.id,
      name: obj.name,
      description: obj.description,
      enabled: obj.enabled,
      sipId: obj.sipId,
      ruleActions: CallRuleActionMapper.toArray(obj.ruleActions),
    };
  }
};
