export const SUPPORTED_AUDIO_FILE_TYPES = ['audio/mp3', 'audio/ogg', 'audio/wav', 'audio/mpeg', 'audio/x-wav'];
export const SUPPORTED_IMAGE_FILE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

export const SUPPORTED_AUDIO_FORMATS = 'mpeg, mp3, ogg, wav';
export const SUPPORTED_IMAGE_FORMATS = 'png, webp, jpeg';

export const IMAGE_FILE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'webp'];

export interface MimeTypeModel {
  format: string;
  types: string[];
  extensions: string[];
}

export const MIME_TYPES: MimeTypeModel[] = [
  // Audio files formats
  {
    format: 'mpeg',
    types: ['audio/mpeg'],
    extensions: ['.mpg']
  },
  {
    format: 'mp3',
    types: ['audio/mp3', 'audio/mpeg-3', 'audio/x-mpeg-3'],
    extensions: ['.mp3']
  },
  {
    format: 'ogg',
    types: ['audio/ogg'],
    extensions: ['.ogg']
  },
  {
    format: 'wav',
    types: ['audio/wav', 'audio/x-wav'],
    extensions: ['.wav']
  },
  // Image file formats
  {
    format: 'jpeg',
    types: ['image/jpeg'],
    extensions: ['.jfif', '.jpe', '.jpeg', '.jpg']
  },
  {
    format: 'png',
    types: ['image/png', 'image/x-png'],
    extensions: ['.png']
  },
  {
    format: 'webp',
    types: ['image/webp'],
    extensions: ['.webp']
  },

  // Application file formats
  {
    format: 'pdf',
    types: ['application/pdf'],
    extensions: ['.pdf']
  },
  {
    format: 'msword',
    types: ['application/msword'],
    extensions: ['.doc']
  },
  {
    format: 'msword',
    types: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    extensions: ['.docx']
  },
  {
    format: 'odt',
    types: ['application/vnd.oasis.opendocument.text'],
    extensions: ['.odt']
  },
  {
    format: 'ods',
    types: ['application/vnd.oasis.opendocument.spreadsheet'],
    extensions: ['.ods']
  },
  {
    format: 'odp',
    types: ['application/vnd.oasis.opendocument.presentation'],
    extensions: ['.odp']
  },
];
