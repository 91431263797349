import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';

import { killEvent } from '@helpers/utils/event.utils';
import { MediaPlayerConsumerComponent } from '@models/common/media-player/media-player-consumer.component';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { PlayMediaEvent } from '@models/common/media-player/play-media.event';
import { MediaState } from '@models/common/media-player/media-state.enum';
import { MediaPlayerService } from '@services/media-player/media-player.service';

const WAVE_RANGE_SIZE: number = 24;

@Component({
  selector: 'table-media-player',
  templateUrl: './table-media-player.component.html',
  styleUrls: ['./table-media-player.component.sass']
})
export class EtlTableMediaPlayerComponent
  implements OnInit, OnDestroy, MediaPlayerConsumerComponent {
    // tslint:disable-next-line:naming-convention
    MediaState: typeof MediaState = MediaState;

    uid: string;
    waveRange: string[];

    mediaState: MediaState;
    mediaTime: number = 0;

    collapsed: boolean = true;

    @Input() selectedMedia: MediaFileModel = null;

    @Output() toggleMedia: EventEmitter<PlayMediaEvent> = new EventEmitter();
    @Output() playTimeChange: EventEmitter<number> = new EventEmitter();

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _mediaService: MediaPlayerService
  ) {
    this.updateWaveRange();
    this._mediaService.register(this);
    this.mediaState = MediaState.STOPPED;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this._mediaService.remove(this);
  }

  // -- event handlers --------------------------------------------------------

  onTogglePlay(): void {
    if (this.mediaState === MediaState.PLAYING && this.collapsed) {
      return;
    }
    this.toggleMedia.emit({
      componentUid: this.uid,
      mediaFile: this.selectedMedia
    });
  }

  onClick(event: Event): void {
    killEvent(event);
  }

  onPlayTimeChange(idx: number): void {
    if (this.mediaState === MediaState.PLAYING) {
      const playTime = (this.selectedMedia.duration / WAVE_RANGE_SIZE) * idx;
      this.playTimeChange.emit(playTime);
    }
  }

  onToggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  // -- MediaPlayerConsumerComponent interface --------------------------------

  setState(state: MediaState): void {
    this.mediaState = state;
  }

  setTime?(time: number): void {
    this.mediaTime = time;
    this.updateWaveRange();
  }

  markMediaAsUnavailable?(mediaId: number): void {
  }

  // -- general methods -------------------------------------------------------

  updateWaveRange(): void {
    this.waveRange = [];
    for (let i = 0; i < WAVE_RANGE_SIZE; ++i) {
      let className = (i + 2) % 3 === 0 ? 'tall' : 'small';
      if (this.mediaTime && this.selectedMedia) {
        className += ((this.mediaTime / this.selectedMedia.duration) * WAVE_RANGE_SIZE > i)
          ? ' active'
          : '';
      }
      this.waveRange.push(className);
    }
  }
}
