import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  forwardRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
  Injector
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { EtlSelectComponent } from '@elements/etl-select/etl-select.component';
import { MediaPlayerConsumerComponent } from '@models/common/media-player/media-player-consumer.component';
import { MediaPlayerService } from '@services/media-player/media-player.service';
import { PlayMediaEvent } from '@models/common/media-player/play-media.event';
import { MediaState } from '@models/common/media-player/media-state.enum';
import { MediaFileType } from '@shared/constant/media/media-file-type.enum';
import { MediaSelectorType } from '@shared/constant/media/media-selector-type.enum';
import { MediaSelectionMode } from '@models/common/media-player/media-selection-mode.enum';


@Component({
  selector: 'media-file-selector',
  templateUrl: './media-file-selector.component.html',
  styleUrls: ['./media-file-selector.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaFileSelectorComponent),
      multi: true
    }
  ]
})
export class MediaFileSelectorComponent
  extends EtlBaseFormControlComponent
  implements MediaPlayerConsumerComponent, ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  // tslint:disable-next-line:naming-convention
  MediaFileType: typeof MediaFileType = MediaFileType;
  // tslint:disable-next-line:naming-convention
  MediaSelectorType: typeof MediaSelectorType = MediaSelectorType;

  uid: string;
  selectedMedia: MediaFileModel = null;

  playButtonText: string = 'Play';

  @Input() mediaFiles: MediaFileModel[];
  @Input() useIdAsValue: boolean = true;

  @Output() toggleMedia: EventEmitter<PlayMediaEvent> = new EventEmitter();
  @Output() upload: EventEmitter<File[]> = new EventEmitter();

  @ViewChild('select', { static: false }) selectControl: EtlSelectComponent;

  private _playEnabled: boolean = true;

  // -- properties ------------------------------------------------------------

  get isPlayDisabled(): boolean {
    return !this._playEnabled || !this.mediaPlayable;
  }

  get mediaPlayable(): boolean {
    return !!this.selectedMedia
      && this.selectedMedia.converted === 1
      && this.selectedMedia.available;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    protected injector: Injector,
    private _changeDetector: ChangeDetectorRef,
    private _mediaService: MediaPlayerService
  ) {
    super(injector);

    this._mediaService.selectionMode = MediaSelectionMode.STOP_AND_RESET;
    this._mediaService.register(this);
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.value) {
      if (this.useIdAsValue && this.mediaFiles) {
        this.selectedMedia = this.mediaFiles
          .find(file => file.id === +this.value);
        this.value = this.selectedMedia;
      } else {
        this.selectedMedia = this.value;
      }
      this._changeDetector.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.selectedMedia = null;
    this._mediaService.remove(this);
  }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(obj: any): void {
    this.value = obj;
    this.selectMedia(obj);
    this._playEnabled = true;
  }

  // -- event handlers --------------------------------------------------------

  onChangeValue(event: any): void {
    this.selectMedia(this.value);
    this._playEnabled = true;

    const valueChanges = this.useIdAsValue
      ? event.id
      : event;

    this.onChange(valueChanges);
    this.onTouched();
  }

  onFocus(event?: any): void {
    if (!this.focused) {
      this.focused = true;
      this.focus.emit(event);
    }
  }

  onBlur(event?: any): void {
    if (this.focused) {
      this.focused = false;
      this.blur.emit(event);
      this.onTouched();
    }
  }

  onTogglePlay(): void {
    if (this.selectedMedia && !this.isPlayDisabled) {
      this.toggleMedia.emit({
        componentUid: this.uid,
        mediaFile: this.selectedMedia
      });
    }
  }

  onUpload(files: File[]): void {
    this.upload.emit(files);
  }

  // -- MediaPlayerConsumerComponent interface --------------------------------

  selectMedia(obj: any): void {
    if (this.mediaFiles) {
      if (this.useIdAsValue) {
        this.selectedMedia = this.mediaFiles
          .find(mf => mf.id === +obj);
      } else {
        this.selectedMedia = this.mediaFiles
          .find(mf => mf === obj);
      }
    }
  }

  setState(state: MediaState): void {
    switch (state) {
      case MediaState.UNAVAILABLE:
        this.playButtonText = 'Play';
        this._playEnabled = false;
        break;
      case MediaState.PLAYING:
        this.playButtonText = 'Pause';
        this._playEnabled = true;
        break;
      case MediaState.STOPPED:
        this.playButtonText = 'Play';
        this._playEnabled = true;
        break;
      case MediaState.LOADING:
        this.playButtonText = 'Loading';
        this._playEnabled = false;
        break;
    }
  }

  markMediaAsUnavailable(mediaId: number): void {
    this.mediaFiles.forEach(file => {
      if (file.id === mediaId) {
        file.available = false;
      }
    });
  }
}
