import {Pipe, PipeTransform} from '@angular/core';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { USER_LANG } from '@shared/constant/storage.const';
import { TranslateService } from '@services/translate/translate.service';

@Pipe({
  name: 'calculateDuration',
  pure: true
})
export class CalculateDurationPipe implements PipeTransform {
  
  engAbbreviations: string = 'd|h|m|s';
  rusAbbreviations: string = 'д|ч|м|с';

  constructor(
    private _localStorage: BrowserStorageService,
    private _translate: TranslateService
  ) { }

  /**
   * @description
   * Transforms number from time
   * example use number from time: Number | calculateDuration
   *
   * @param value number
   * @param args additional transofrmation parameters
   */
  transform(value: number, args?: any) {
    const sec = value % 60;
    const min = ((value - sec) / 60) % 60;
    const hr = ((value - (sec + (min * 60))) / 60) / 60;

    const lang = this._translate.currentLang;
    const abbreviations = lang === 'ru'
      ? this.rusAbbreviations
      : this.engAbbreviations;
    const [ aD, aH, aM, aS ] = abbreviations.split('|');

    if (hr)  { return `${hr}${aH} ${min}${aM} ${sec}${aS}`; }
    if (min) { return `${min}${aM} ${sec}${aS}`; }
    if (sec) { return `${sec}${aS}`; }

    return null;
  }
}
