import { ValidationErrorType } from './validation-error-type.enum';

export const SETTINGS_EMAIL_VALIDATION_MESSAGES = [
  {
    name: 'email',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your email address'
  },
  {
    name: 'email',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct email address'
  },
  {
    name: 'code',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter a confirmation code'
  },
  {
    name: 'code',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter the correct confirmation code'
  },
  {
    name: 'code',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Please enter the correct confirmation code'
  },
  {
    name: 'code',
    error: ValidationErrorType.MINLENGTH,
    message: 'Confirmation code is too short. Please use at least 6 characters'
  },
];
