import { ValidationErrorType } from './validation-error-type.enum';

export const EXTENSIONS_VALIDATION_MESSAGES = [
  {
    name: 'outer',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose a phone number'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Extension' }
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.MINLENGTH,
    message: 'Extension can contain 3 numbers only'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Extension can contain 3 numbers only'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.MIN,
    message: 'Your extension must be over 200'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the extension'
  },
  {
    name: 'user.firstName',
    error: ValidationErrorType.MAXLENGTH,
    message: 'First name can\'t contain over 190 characters'
  },
  {
    name: 'user.firstName',
    error: ValidationErrorType.PATTERN,
    message: 'invalidCharsDigitsSymbolsMsg',
    params: { field: 'First name', symbols: ' \'-.' }
  },
  {
    name: 'user.firstName',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'firstCharErrorMsg_Nt',
    params: { field: 'First name' }
  },
  {
    name: 'user.lastName',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Last name can\'t contain over 190 characters'
  },
  {
    name: 'user.lastName',
    error: ValidationErrorType.PATTERN,
    message: 'invalidCharsDigitsSymbolsMsg',
    params: { field: 'Last name', symbols: ' \'-.' }
  },
  {
    name: 'user.lastName',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'firstCharErrorMsg_Fm',
    params: { field: 'Last name' }
  },
  {
    name: 'user.email',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter email address'
  },
  {
    name: 'user.email',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter a valid email address'
  },
  {
    name: 'faxEmail',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter a valid email address'
  },
  {
    name: 'displayname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Display name can\'t contain over 32 characters'
  },
  {
    name: 'displayname',
    error: ValidationErrorType.PATTERN,
    message: 'You can use letters, numbers and the following characters: \'-_.'
  }
];
