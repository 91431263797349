import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CallRulesService } from '@services/call-rules/call-rules.service';
import { RingGroupModel } from '@models/local/ring-group/ring-group.model';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';

@Component({
  selector: 'ring-group-redirection',
  templateUrl: './ring-group-redirection.component.html',
  styleUrls: ['./ring-group-redirection.component.sass']
})
export class RingGroupRedirectionComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() index: number;
  @Input() service: CallRulesService | CrmIntegrationRulesService;

  get ringGroups(): RingGroupModel[] {
    if (this.service instanceof CallRulesService) {
      return this.service.ringGroups;
    }
    return null;
  }

  get placeholder(): string {
    return this.ringGroups.length === 0
      ? 'You have no groups'
      : 'choose one';
  }

  constructor() { }

  ngOnInit() { }
}
