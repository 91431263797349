import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';
import { TariffPlanServices } from '@models/local/tariff-plan/tariff-plan-services';
import { TariffPlanModel } from '@models/local/tariff-plan/tariff-plan.model';
import { ModalService } from '@services/modal/modal.service';
import { NotificationService } from '@services/notification/notification.service';
import { RefillBalanceService } from '@services/refill-balance/refill-balance.service';
import { TariffPlansService } from '@services/tariff-plans/tariff-plans.service';
import { TranslateService } from '@services/translate/translate.service';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { DLG_RESULT_OK } from '@shared/constant/dialog.constants';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {isComVersion} from '@helpers/utils/app.utils';
import {MONEY_ICON_EUR, MONEY_ICON_RUB} from '@shared/constant/money-icons.constants';


@Component({
  selector: 'app-tariff-plans',
  templateUrl: './tariff-plans.component.html',
  styleUrls: [
    './tariff-plans.component.sass',
    './tariff-plans.white-theme.sass',
    './tariff-plans.dark-theme.sass',
  ]
})
export class TariffPlansComponent implements OnInit, OnDestroy {

  tariffs: TariffPlanModel[];
  tariffsShow: TariffPlanModel[];
  currentTariffId: number = 1;
  isAuthentificated: boolean = false;
  showBackButton = false;
  moneyIcon: string;

  get currentTariff(): TariffPlanModel {
    return this.tariffs.find(t => t.id === this.currentTariffId);
  }

  constructor(
    public service: TariffPlansService,
    private _user: AuthenticationService,
    private _routes: Router,
    private _dialog: ModalService,
    private _location: Location,
    private _notification: NotificationService,
    private _router: Router,
    private _translate: TranslateService,
    private _refillService: RefillBalanceService,
  ) {

    this.service.getTariffPlans().subscribe();
    this.service.tariffs$
      .subscribe(res => {
        this.tariffs = res;
        this.tariffsShow = res.filter(tariff => tariff.hidden === false);
      });

    if (this._user.isAuthenticated) {
      this._user.currentUser.takeUntil(componentDestroyed(this)).subscribe((u) => {
        this.currentTariffId = u.tariffPlan.id;
      });
      this.isAuthentificated = true;
    }

    if (this._routes.url !== '/cabinet/tariff') {
      this.showBackButton = true;
    }
  }

  goback() {
    this._location.back();
  }

  ngOnInit() {
    this.moneyIcon = isComVersion() ? MONEY_ICON_EUR : MONEY_ICON_RUB;
  }

  ngOnDestroy(): void {
    this.tariffs = undefined;
  }

  chooseTariff(tariff: TariffPlanModel) {
    if (!this._user.isAuthenticated) {
      this.service.currentTariff = tariff;
      this._routes.navigate(['sign-up'], { queryParams: { tariffId: tariff.id } });
      return false;
    }

    const changeTariff$ = (this.currentTariff.tariffPrice > tariff.tariffPrice)
      ? this.downgradeTariffConfirm(this.currentTariffId, tariff.id)
      : this.upgradeTariffConfirm();

    changeTariff$
      .pipe(
        switchMap(_ => this.service.changeTariff(tariff.id))
      )
      .subscribe(
        () => {
          this._user.initMenuAndUserData().subscribe();
        },
        (error) => {
          if (error.error) {
            if (error.error.code && error.error.code === 1) {
              this._notification.error(error.error.messageTemplate, {
                object: error.error.object,
                objectName: error.error.objectName
              });
            } else {
              this._notification.error(error.error.message);
            }
          } else {
            console.error(error);
            this._notification.error('Something wrong');
          }
        });
  }

  downgradeTariffConfirm(curTariffId: number, newTariffId: number): Observable<any> {
    return this.service
    .getTariffChanges(curTariffId, newTariffId)
    .pipe(
      map(
        (res: TariffPlanServices[]) => {
          const [cur, dst] = res;
          const lostServices: any = {
            ivrs: cur.ivrs.filter(i => !dst.ivrs.includes(i)),
            queues: cur.queues.filter(i => !dst.queues.includes(i)),
            rules: cur.rules.filter(i => !dst.rules.includes(i)),
            autoCalls: cur.autoCalls.filter(i => !dst.autoCalls.includes(i)),
            extensionsLimitOld: cur.tariff.extensionsLimit,
            extensionsLimitNew: dst.tariff.extensionsLimit
          };
          return lostServices;
        }
      ),
      map(res => {
        const spanClass = `<i>,</i></span><span class="service-list">`;
        let lostServicesMessage: string = '';
        if (res.ivrs.length) {
          lostServicesMessage += res.ivrs.map(i => this.getServicePrefix('IVR') + `"<b>${i}</b>"`).join(spanClass);
        }
        if (res.queues.length) {
          lostServicesMessage += spanClass;
          lostServicesMessage += res.queues.map(i => this.getServicePrefix('Call Queue') + `"<b>${i}</b>"`).join(spanClass);
        }
        if (res.rules.length) {
          lostServicesMessage += spanClass;
          lostServicesMessage += res.rules.map(i => this.getServicePrefix('Call Rule') + `"<b>${i}</b>"`).join(spanClass);
        }
        if (res.autoCalls.length) {
          lostServicesMessage += spanClass;
          lostServicesMessage += res.autoCalls.map(i => this.getServicePrefix('AutoCall') + `"<b>${i}</b>"`).join(spanClass);
        }
        return lostServicesMessage ?
          `<br/>${this._translate.instant('lostExtensionsAndServices', { count: res.extensionsLimitNew })}` +
          `<div class="tariff-modal-service"><span class="service-list">${lostServicesMessage}</span></div>`
          : `<br/>${this._translate.instant('lostExtensions', { count: res.extensionsLimitNew })}`;
      }),
      switchMap((res: string) => this.confirmChangeDlg(res))
    );
  }

  upgradeTariffConfirm(): Observable<any> {
    return this.confirmChangeDlg(null);
  }

  getServicePrefix(name: string): string {
    return this._translate.instant(name) + ' - ';
  }

  confirmChangeDlg(lostServices: string): Observable<any> {
    let body = `<b>${this._translate.instant('The new tariff will be applied next month')}</b>`;
    if (lostServices) {
      body += lostServices;
    }
    return this._dialog
      .showConfirm({
        title: 'Confirm the change of the tariff plan',
        okBtn: { title: 'Yes', result: DLG_RESULT_OK },
        body: body
      })
      .pipe(
        filter(res => res === DLG_RESULT_OK)
      );
  }

  confirmRefillBalanceDlg(): Observable<any> {
    return this._dialog
      .showConfirm({
        okBtn: {title: 'Refill', result: DLG_RESULT_OK},
        body: 'You do not have enough money to change the tariff.'
      })
      .pipe(
        filter(res => res === DLG_RESULT_OK)
      );
  }
}
