import {
  Component,
  OnInit,
  forwardRef,
  ViewChild,
  Input,
  Injector
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { killEvent } from '@helpers/utils/event.utils';
import { EtlBaseMaskedFormControlComponent } from '@elements/etl-base-form/etl-base-masked-form-control.component';

@Component({
  selector: 'etl-input',
  templateUrl: './etl-input.component.html',
  styleUrls: ['./etl-input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlInputComponent),
      multi: true
    }
  ],
})
export class EtlInputComponent
  extends EtlBaseMaskedFormControlComponent
  implements OnInit, ControlValueAccessor {

  floatLabel: 'never' | 'always' | 'auto' = 'never';
  formFieldAppearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'legacy';
  showPass = false;

  @Input() requireText: string = ' *';
  @Input() type: string = 'text';
  @Input() color: string = 'primary';
  @Input() clearable: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() onlyDigit: boolean = false;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() autocomplete: boolean = false;

  @Input() set appearance(value: 'general' | 'sidebar') {
    if (value === 'sidebar') {
      this.floatLabel = 'always';
      this.formFieldAppearance = 'outline';
    } else {
      this.floatLabel = 'never';
      this.formFieldAppearance = 'legacy';
    }
  }

  @ViewChild(MatInput, { static: false }) input: MatInput;

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  // -- event handlers --------------------------------------------------------

  setFocus(): void {
    this.focused = true;
    this.input.focus();
  }

  reset(event: Event): void {
    killEvent(event);
    this.value = '';
    this.onChange(this.value);
    this.onTouched();
  }

  onInput() {
    super.onInput(this.value);
  }

  showPassword() {
    if (this.type === 'password') {
      this.showPass = !this.showPass;
    }
  }
}

