import {Injectable, Injector} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { environment } from '@env/environment';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import {CURRENT_USER, MAIN_MENU} from '@shared/constant/storage.const';
import { UserDataModel } from '@models/local/user/user-data.model';
import { SupportStatusUpdateModel } from '@models/local/support/support-status-update.model';
import { SupportStatusUpdateMapper } from '@mappers/support/support-status-update.mapper';
import { CdrModel } from '@models/local/cdr/cdr.model';
import { CdrMapper } from '@mappers/cdr/cdr.mapper';

const SERVER_URL = environment.ws;

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  balance$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  cdr$: BehaviorSubject<CdrModel> = new BehaviorSubject<CdrModel>(null);
  supportStatusUpdate$: BehaviorSubject<SupportStatusUpdateModel> = new BehaviorSubject<SupportStatusUpdateModel>(null);
  logOut$: Subject<string> = new Subject();
  verified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  minutes$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  service$: Subject<any> = new Subject<any>();
  monitor$: Subject<any> = new Subject<any>();
  fleshCalls$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _socket;

  constructor(
    private _storage: BrowserStorageService
  ) { }

  /**
   * @description
   * Observable socket event with message from server
   * example use:
   * private onBalance(): void {
   *   this._socket.on('balance', (data: string) => {
   *     this.balance$.next(JSON.parse(data).balance);
   *   });
   * }
   *
   * Observable socket event from server
   * example use:
   * private onBalance(): void {
   *   this._socket.on('balance', () => {
   *     this.balance$.next();
   *   });
   * }
   */
  public initSocket(): void {
    this._socket = new Socket({
      url: SERVER_URL,
      options: {
        path: '/',
        reconnectionDelay: 10000,
        reconnectionAttempts: 5
      }
    });
  }

  public send(channel: string, message: any): void {

    this._socket.emit(channel, message);
  }

  /**
   * @description
   * Authorization function and space to add subscriptions functions
   *
   * example use subscription function: onBalance()
   *
   * @param token string The user token use
   */
  authenticate(token: string): void {
    console.log('web socket');
    if (token) {
      this.send('authenticate', { token: token });
      this.onChannels();
      this.onBalance();
      this.onCdr();
      this.onSupport();
      this.onNotification();
      this.onService();
      this.onMonitor();
    }
  }

  close() {
    this.balance$.next(null);
    this.cdr$.next(null);
    this.supportStatusUpdate$.next(null);
    this.minutes$.next(null);
    this.service$.next(null);
    this.fleshCalls$.next(null);
    this._socket.disconnect();
  }

  /**
   * @description
   * Get available channels from the server and subscribe to them
   */
  private onChannels(): void {
    this._socket.on('channels', (data) => {
      this.subscribeToChannel(data.channel);
      switch (data.channel) {
        case 'message':
          this.getMessages();
          break;
        case 'chat':
          this.getChats();
          break;
      }
    });
  }

  getMessages() {
    this.send('get-messages', {});
    // return this.messages;
  }

  getChats() {
    this.send('get-chats', {});
    // return this.chats;
  }

  private subscribeToChannel(channel: string) {
    this.send('subscribe-to-channel', { channel: channel });
  }

  private onBalance(): void {
    this._socket.on('balance', (data: string) => {
      const user = this._storage.readItem(CURRENT_USER) as UserDataModel;
      const parsedData = JSON.parse(data);
      this._storage.writeItem(CURRENT_USER, user);
      if (parsedData.balance) {
        this.balance$.next(parsedData.balance);
      }
      if (parsedData.minutes) {
        this.minutes$.next(parsedData.minutes);
      }
      if (parsedData.fleshCalls) {
        this.fleshCalls$.next(parsedData.fleshCalls);
      }
    });
  }

  private onCdr(): void {
    this._socket.on('cdr', (data: string) => {
      this.cdr$
        .next(
          CdrMapper.from(JSON.parse(data).callDetail)
        );
    });
  }

  private onSupport(): void {
    this._socket.on('support', (data: string) => {
      this.supportStatusUpdate$
        .next(
          SupportStatusUpdateMapper.from(JSON.parse(data).message)
        );
    });
  }

  private onNotification(): void {
    this._socket.on('notification', (data) => {
      // console.log('<<< notification', data);
      if (typeof data === 'string') {
        data = JSON.parse(data);
        this.notificationParse(data);
      }
    });
  }

  private notificationParse(data) {
    if (data.message === 'logOut') {
      this.logOut$.next(data.token);
    }

    if (data.message && data.message.verification) {
      const user = this._storage.readItem(CURRENT_USER) as UserDataModel;
      user.verificationStatus = +data.message.verification;
      this._storage.writeItem(CURRENT_USER, user);
      this.verified$.next(+data.message.verification === 1);
    }
  }

  private onService(): void {
    this._socket.on('service', (data: string) => {
      // console.log(data);
      this.service$.next(data);
    });
  }

  private onMonitor(): void {
    this._socket.on('monitor', (data: string) => {
      console.log('============================data');
      console.log(data);
      this.monitor$.next(data);
    });
  }
}
