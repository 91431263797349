import { OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Observable, MonoTypeOperatorFunction } from 'rxjs';

export function componentDestroyed(component: OnDestroy) {
  const original = component.ngOnDestroy;
  const destroyed$ = new ReplaySubject<void>(1);
  component.ngOnDestroy = () => {
    original.apply(component);
    destroyed$.next(undefined);
    destroyed$.complete();
  };
  return destroyed$;
}

// Use:         observable$.pipe(alive(this)).subscribe(...)
// instead of:  observable$.takeUntil(componentDestroyed(this)).subscribe(...)
export function alive<T>(component: OnDestroy): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => source.takeUntil(componentDestroyed(component));
}
