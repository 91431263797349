export enum ModuleName {
  RENT_ACCOUNT = 'rentAccount',
  DETAILS_AND_RECORDS = 'detailsRecords',
  EXTENSIONS = 'extensions',
  PHONE_NUMBERS = 'phoneNumbers',
  INVOICES = 'invoices',
  MARKETPLACE = 'marketplace',
  ADDRESS_BOOK = 'addressBook',
  CALL_RULES = 'callRules',
  IVR = 'ivr',
  CALL_QUEUES = 'callQueues',
  CALL_RECORD = 'callRecord',
  RING_GROUP = 'ringGroup',
  COMPANY = 'company',
  AUDIO_CONFERENCE = 'audioConference',
  DISA = 'disa'
}
