import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const PHONE_NUMBERS_VALIDATION_MESSAGES = [
  {
    name: 'phoneNumber',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter phone number'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'Contact phone is too short. Please use at least 10 characters' : 'Contact phone is too short. Please use at least 11 characters'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.MAXLENGTH,
    message: isComVersion() ? 'Contact phone is too long. Use no more than 16 numbers' : 'Contact phone is too long. Use no more than 11 numbers'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.PATTERN,
    message: 'Contact phone contains invalid characters. You can use numbers only'
  },
  {
    name: 'password',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter password'
  },
  {
    name: 'password',
    error: ValidationErrorType.MINLENGTH,
    message: 'The password is too short. The password must be at least 6 characters long'
  },
  {
    name: 'password',
    error: ValidationErrorType.MAXLENGTH,
    message: 'The password is too long. Please don\'t use over 20 characters'
  },
  {
    name: 'host',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter host'
  },
  {
    name: 'host',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter the correct host'
  },
  {
    name: 'port',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter port'
  },
  {
    name: 'port',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Port' }
  },
  {
    name: 'port',
    error: ValidationErrorType.MAX,
    message: 'The port is too big. Please don\'t use value over 65535'
  },
  {
    name: 'nigol',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter username'
  },
  {
    name: 'displayname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Display name can\'t contain over 32 characters'
  },
  {
    name: 'displayname',
    error: ValidationErrorType.PATTERN,
    message: 'You can use letters, numbers and the following characters: \'-_.'
  }
];
