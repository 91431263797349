import { ValidationErrorType } from './validation-error-type.enum';
import { isComVersion } from '@helpers/utils/app.utils';

export const CALL_RULES_VALIDATION_MESSAGES = [
  {
    name: 'name',
    error: ValidationErrorType.PATTERN,
    message: 'invalidCharsDigitsSymbolsMsg',
    params: { field: 'Rule name', symbols: ' \'-_.&' }
  },
  {
    name: 'name',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter rule name',
  },
  {
    name: 'name',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'The name of the rule is too short. Please use at least 1 character',
  },
  {
    name: 'name',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Rule name can\'t contain over 150 characters',
  },
  {
    name: 'sipId',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Phone number'
  },
  {
    name: 'lastAction',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Action'
  },
  {
    name: 'ruleActions.*.timeout',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter a value from 5 to 300'
  },
  {
    name: 'ruleActions.*.timeout',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter a value from 5 to 300'
  },
  {
    name: 'ruleActions.*.timeout',
    error: ValidationErrorType.RANGE,
    message: 'Please enter a value from 5 to 300'
  },
  {
    name: 'ruleActions.*.scheduleDate',
    error: ValidationErrorType.DAYS,
    message: 'Please choose at least one day of the week'
  },
  {
    name: 'ruleActions.*.scheduleTime',
    error: ValidationErrorType.START_TIME,
    message: 'Start time cannot be greater than end time'
  },
  {
    name: 'ruleActions.*.scheduleTime',
    error: ValidationErrorType.EQUAL_TIME,
    message: 'Start time cannot be the same as end time'
  },
  {
    name: 'ruleActions.*.scheduleTime',
    error: ValidationErrorType.INVALID_RANGE,
    message: 'Invalid time range format'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the External number',
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'External number' }
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'External number is too short. Use at least 10 numbers' : 'External number is too short. Use at least 11 numbers'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.MAXLENGTH,
    message: 'External number is too long. Use no more than 16 numbers'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.DUPLICATED,
    message: 'You can not redirect to the same extension 2 times in a row'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.EXTENSION_REQUIRED,
    message: 'Please choose the Extension number'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.CALL_QUEUE_REQUIRED,
    message: 'Please choose the Call Queue'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.CALL_GROUP_REQUIRED,
    message: 'Please choose the Ring Group'
  },
  {
    name: 'ruleActions.*.parameter',
    error: ValidationErrorType.VOICE_FILE_REQUIRED,
    message: 'Please choose the Voice Greeting'
  }
];
