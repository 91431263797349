import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const CALL_QUEUES_VALIDATION_MESSAGES = [
  {
    name: 'name',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the call queue name',
  },
  {
    name: 'name',
    error: ValidationErrorType.MINLENGTH,
    message: 'Call queue name is too short. Please use at least 4 characters',
  },
  {
    name: 'name',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Call queue name is too long. Please don\'t use over 40 characters',
  },
  {
    name: 'name',
    error: ValidationErrorType.PATTERN,
    message: 'invalidCharsDigitsSymbolsMsg',
    params: { field: 'Call queue name', symbols: ' \'-_.&' }
  },
  {
    name: 'sipId',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose a phone number'
  },
  {
    name: 'strategyId',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the ring strategy'
  },
  {
    name: 'strategy',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the ring strategy'
  },
  {
    name: 'ringTime',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter a value from 15 to 600'
  },
  {
    name: 'ringTime',
    error: ValidationErrorType.PATTERN,
    message: 'Invalid data. This field may contain numbers only'
  },
  {
    name: 'ringTime',
    error: ValidationErrorType.RANGE,
    message: 'Please enter a value from 15 to 600'
  },
  {
    name: 'maxCallers',
    error: ValidationErrorType.REQUIRED,
    message: 'You need to choose at least one caller'
  },
  {
    name: 'maxCallers',
    error: ValidationErrorType.PATTERN,
    message: 'Invalid data. This field may contain numbers only'
  },
  {
    name: 'maxCallers',
    error: ValidationErrorType.RANGE,
    message: 'Please enter a value from 1 to 100'
  },
  {
    name: 'externalPhone',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'External phone is too short. Please use at least 10 characters' : 'External phone is too short. Please use at least 11 characters'
  },
  {
    name: 'externalPhone',
    error: ValidationErrorType.MAXLENGTH,
    message: isComVersion() ? 'External phone is too long. Use no more than 16 numbers' : 'External phone is too long. Use no more than 11 numbers'
  },
  {
    name: 'externalPhone',
    error: ValidationErrorType.PATTERN,
    message: 'External phone contains invalid characters. You can use numbers only'
  },
  {
    name: 'timeout',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter valid timeout'
  },
  {
    name: 'title',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter title',
  },
];
