import { Mapper } from '@mappers/mapper';
import { QueueMemberModel } from '@models/local/call-queue/queue-member.model';
import { QueueMemberServerModel } from '@models/server/call-queue/queue-member-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { SipInnerMapper } from '@mappers/sip/sip-inner.mapper';

// tslint:disable-next-line:naming-convention
export let QueueMemberMapper: Mapper<QueueMemberModel, QueueMemberServerModel> = {

  from(obj: QueueMemberServerModel): QueueMemberModel {
    if (!obj) { return null; }
    return {
      id: obj.id,
      inner: SipInnerMapper.from(obj.inner),
      externalPhone: obj.externalPhone,
    };
  },

  fromArray(arr: QueueMemberServerModel[]): QueueMemberModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: QueueMemberModel): QueueMemberServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
