import { SERVER_DATE_FORMAT } from '@shared/app-settings/app.settings';
import { Moment } from 'moment';
import { RangeDate } from '@elements/etl-datepicker/etl-range-date';
import { isNull } from 'util';

export const formatDate = (strDate: string): string => {
    return 'formatedDate';
};

export function date2string(date: Moment, format: string = SERVER_DATE_FORMAT): string {
  return !!date ? date.format(format) : null;
}

export function isDateRangeEmpty(range: RangeDate): boolean {
  return !range || (range.startDate === null && range.endDate === null);
}
