import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { EtlAutocompleteComponent } from './etl-autocomplete/etl-autocomplete.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { EtlInputComponent } from './etl-input/etl-input.component';
import { EtlSelectComponent } from './etl-select/etl-select.component';
import { EtlSelectNewComponent } from './etl-select-new/etl-select-new.component';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { EtlBaseFormComponent } from './etl-base-form/etl-base-form.component';
import { EtlNotificationComponent } from './etl-notification/etl-notification.component';
import { EtlCheckboxComponent } from './etl-checkbox/etl-checkbox.component';
import { EtlDataTableComponent } from './etl-data-table/etl-data-table.component';
import { DEFAULT_PERFECT_SCROLLBAR_CONFIG } from '@shared/app-settings/app.settings';
import { EtlMediaPlayerComponent } from '@elements/etl-media-player/etl-media-player.component';
import { EtlDatepickerComponent } from './etl-datepicker/etl-datepicker.component';
import { EtlTextareaComponent } from './etl-textarea/etl-textarea.component';
import { EtlTagSelectorComponent } from './etl-tag-selector/etl-tag-selector.component';
import { CircleComponent } from './etl-circle/etl-circle.component';
import { EtlTextSpeechGeneratorComponent } from './etl-text-speech-generator/etl-text-speech-generator.component';
import { MatPaginatorIntlTranslate } from './etl-data-table/mat-paginator.translate';
import { NgSelectModule } from '@ng-select/ng-select';
import { EtlDigitOnlyDirective } from '@directives/etl-digit-only/etl-digit-only.directive';
import { EclipseDirective } from '@directives/tooltip/eclipse.directive';
import { EtlBaseTablePageComponent } from './etl-base-page/etl-base-table-page.component';
import { EtlBaseMaskedFormControlComponent } from './etl-base-form/etl-base-masked-form-control.component';
import { EtlBaseFilterComponent } from './etl-base-filter/etl-base-filter.component';
import { EtlGuideComponent } from './etl-guide/etl-guide.component';
import { EtlDadataComponent } from './etl-dadata/etl-dadata.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [
    EtlInputComponent,
    EtlTextareaComponent,
    EtlSelectComponent,
    EtlSelectNewComponent,
    EtlCheckboxComponent,
    EtlBaseFormControlComponent,
    EtlBaseFormComponent,
    EtlNotificationComponent,
    EtlDataTableComponent,
    EtlMediaPlayerComponent,
    EtlAutocompleteComponent,
    EtlDatepickerComponent,
    EtlTagSelectorComponent,
    CircleComponent,
    EtlTextSpeechGeneratorComponent,
    EtlDigitOnlyDirective,
    EclipseDirective,
    EtlBaseTablePageComponent,
    EtlBaseMaskedFormControlComponent,
    EtlBaseFilterComponent,
    EtlGuideComponent,
    EtlDadataComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    PerfectScrollbarModule,
    VgCoreModule,
    VgBufferingModule,
    VgStreamingModule,
    VgControlsModule,
    VgOverlayPlayModule,
    AngularSvgIconModule,
    SatDatepickerModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
    MatDialogModule
  ],
  exports: [
    EtlInputComponent,
    EtlTextareaComponent,
    EtlSelectComponent,
    EtlSelectNewComponent,
    EtlCheckboxComponent,
    EtlNotificationComponent,
    TranslateModule,
    EtlDataTableComponent,
    EtlMediaPlayerComponent,
    EtlAutocompleteComponent,
    EtlDatepickerComponent,
    EtlTagSelectorComponent,
    CircleComponent,
    EtlTextSpeechGeneratorComponent,
    EtlGuideComponent,
    EtlDadataComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new MatPaginatorIntlTranslate();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ]
})
export class ElementsModule { }
