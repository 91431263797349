import { Component, OnInit, forwardRef, Input, Injector, Renderer2, ElementRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { MediaFileType } from '@shared/constant/media/media-file-type.enum';
import { MediaSelectorType } from '@shared/constant/media/media-selector-type.enum';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';


@Component({
  selector: 'doc-file-uploader',
  templateUrl: './doc-file-uploader.component.html',
  styleUrls: ['./doc-file-uploader.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocFileUploaderComponent),
      multi: true
    }
  ]
})
export class DocFileUploaderComponent
  extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor {
  // tslint:disable-next-line:naming-convention
  MediaFileType: typeof MediaFileType = MediaFileType;
  // tslint:disable-next-line:naming-convention
  MediaSelectorType: typeof MediaSelectorType = MediaSelectorType;

  private _inFocus: boolean = false;

  file: File;

  @Input() readonly: boolean;

  // -- properties ------------------------------------------------------------

  get fileName(): string {
    return this.file ? this.file.name : null;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(value: File): void {
    this.file = value;
  }

  // -- event handlers --------------------------------------------------------

  onFocus(event: any) {
    this._inFocus = true;
    this.focus.emit(event);
  }

  @HostListener('window:focus', ['$event'])
  onBlur(event: any) {
    if (this._inFocus) {
      setTimeout(() => this.onTouched(), 100);
    }
    this._inFocus = false;
    this.blur.emit(event);
  }

  onUpload(file: File) {
    this.file = file;
    this.onChange(this.file);
  }

  onClear() {
    this.file = null;
    this.onChange(this.file);
  }
}
