import { Component, OnInit, forwardRef, Input, Injector, ViewChild, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import moment, { isMoment } from 'moment';

import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { RangeDate } from './etl-range-date';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { TranslateService } from '@services/translate/translate.service';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { WEEK_DAYS, MONTHS } from '@shared/constant/date.constants';
import { RAW_SERVER_DATE_FORMAT } from '@shared/app-settings/app.settings';
import { isDateRangeEmpty, date2string } from '@helpers/utils/date.utils';
import { killEvent } from '@helpers/utils/event.utils';
import { EtlBaseMaskedFormControlComponent } from '@elements/etl-base-form/etl-base-masked-form-control.component';

// documentation for component ngxDaterangepickerMd
// https://www.npmjs.com/package/ngx-daterangepicker-material
@Component({
  selector: 'etl-datepicker',
  templateUrl: './etl-datepicker.component.html',
  styleUrls: ['./etl-datepicker.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlDatepickerComponent),
      multi: true
    }
  ],
})
export class EtlDatepickerComponent
  extends EtlBaseMaskedFormControlComponent
  implements OnInit, ControlValueAccessor {

  value: RangeDate;
  initialValue: RangeDate;

  private _initialized: boolean = false;

  locale: any;
  dateFormat: string;

  ranges: any = {};

  @Input() singleDatePicker: boolean = false;
  @Input() readonly: boolean = false;
  @Input() showYear: boolean = false;
  @Input() linked: boolean = false;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() set initialRange(value: RangeDate) {
    if (value) {
      this.initialValue = value;
      this._initialized = true;
    }
  }

  @Output() clear: EventEmitter<void> = new EventEmitter();

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector,
    private _auth: AuthenticationService,
    private _translate: TranslateService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.dateFormat = this._auth.user.dateFormat.toUpperCase().split(' ')[0];

    this.fillLocale(this.dateFormat, false);
    this.fillRanges();
    this._translate.onLangChange
      .subscribe(() => {
        if (this._auth.user !== undefined) {
          this.fillLocale(this.dateFormat, true);
          this.fillRanges();
        }
      });
  }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(value: any): void {
    if (!this._initialized) {
      setTimeout(() => {
        if (value) {
          this.value = this.tryParseValue(value);
        }
        this.initialValue = this.value;

        if (!!this.minDate && this.value && !!this.value.startDate
          && this.minDate > this.value.startDate) {
          this.minDate = this.value.startDate;
        }

        if (!!this.maxDate && !!this.value && !!this.value.endDate
          && this.maxDate < this.value.endDate) {
          this.maxDate = this.value.endDate;
        }

        this._initialized = true;
      }, 0);
    } else {
      this.value = this.tryParseValue(value);
    }
  }

  tryParseValue(value: any): any {
    if (!this.singleDatePicker) {
      return value;
    }
    if (typeof value === 'string') {
      // `YYYY-MM-DD` format expected only
      const date = moment(value, RAW_SERVER_DATE_FORMAT);
      return {
        startDate: date,
        endDate: date
      };
    }
    if (isMoment(value)) {
      return {
        startDate: value,
        endDate: value
      };
    } else {
      return value;
    }
  }

  // -- event handlers --------------------------------------------------------

  onChangeValue(value: RangeDate): void {
    if (!value) { return; }

    // `Clear` action detected
    if (isDateRangeEmpty(value)
      && !isDateRangeEmpty(this.initialValue)) {

      if (!this.singleDatePicker) {
        setTimeout(() => {
          this.value = this.initialValue;
          this.sendDateChange(this.value);
        }, 0);
      }
      this.clear.emit();
    } else if (!isDateRangeEmpty(value)) {
      this.sendDateChange(value);
    }
  }

  sendDateChange(value: RangeDate): void {
    let emitValue: any = value;
    if (this.singleDatePicker) {
      emitValue = value.startDate
        ? (value.startDate.isValid() ? value.startDate.format(RAW_SERVER_DATE_FORMAT) : null)
        : null;
    }

    this.onChange(emitValue);
    this.onTouched();

    this.change.emit(value);
  }

  onInputChange(event: any): void {
    killEvent(event);

    const value = moment(event.target.value, this.dateFormat, true);
    if (value.isValid) {
      this.onChangeValue({ startDate: value, endDate: value });
    }

    this.onTouched();
    this.keyup.emit(event);
  }

  onBlur(event: any): void {
    this.focused = false;
    this.onTouched();
    this.blur.emit(event);
    this.createPlaceholder(this.placeholder);
  }

  onOpen(event: any) {
    if (!this.readonly) {
      if (!this.pickerDirective.picker.isShown) {
        setTimeout(() => {
          this.pickerDirective.open(event);
        }, 50);
      }
    }
  }

  // -- general methods -------------------------------------------------------

  fillLocale(formatDate, changeLang): void {
    const daysOfWeek = WEEK_DAYS.map(wd => this._translate.instant(wd));

    if (changeLang) {
      daysOfWeek.push(daysOfWeek.shift());
    }

    this.locale = {
      firstDay: 1,
      applyLabel: this._translate.instant('Apply'),
      clearLabel: this._translate.instant('Clear'),
      format: formatDate,
      daysOfWeek: daysOfWeek,
      monthNames: MONTHS.map(m => this._translate.instant(m))
    };
  }

  createPlaceholder(value: string): void {
    let displayValue = '';
    if (value) {
      displayValue = this.translate.instant(value);
    }
    this.localPlaceholder = displayValue ? `[${displayValue}]` : '';
  }

  fillRanges(): void {
    this._translate.get(['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Last Month']).subscribe((results: string[]) => {
      this.ranges = {
        [results['Today']]: [moment(), moment()],
        [results['Yesterday']]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [results['Last 7 Days']]: [moment().subtract(6, 'days'), moment()],
        [results['Last 30 Days']]: [moment().subtract(29, 'days'), moment()],
        [results['Last Month']]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

      };
    });
  }
}
