import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'etl-circle',
  templateUrl: './etl-circle.html',
  styleUrls: ['./etl-circle.sass']
})

export class CircleComponent implements OnInit, OnChanges {

  @Input() value: number;

  private _radius = 76;
  private _circumference = 2 * Math.PI * this._radius;
  private _dashoffset: number;

  get radius() {
    return this._radius;
  }

  get circumference() {
    return this._circumference;
  }

  get dashoffset() {
    return this._dashoffset;
  }

  constructor() {
    this.progress(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue !== changes.value.previousValue) {
      this.progress(changes.value.currentValue);
    }
  }

  ngOnInit() { }

  private progress(value: number) {
    const progress = value / 100;
    this._dashoffset = this._circumference * (1 - progress);
  }
}
