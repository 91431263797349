import { FormValidationMessages } from '@models/local/validation/form-validation-messages';
import { ValidationErrorType } from './validation-messages/validation-error-type.enum';
import { PASSWORD_AND_CONFIRM } from './validation-messages/password-and-confirm-validation.messages';
import { fillValidationLevels } from './validation-messages/ivr-level-validation.messages';
import { LOGIN_VALIDATION_MESSAGES } from './validation-messages/login-validation.messages';
import { SIGN_UP_VALIDATION_MESSAGES } from './validation-messages/sign-up-validation.messages';
import { COMPANY_VALIDATION_MESSAGES } from './validation-messages/company-validation.messages';
import { CALL_RULES_VALIDATION_MESSAGES } from './validation-messages/call-rules-validation.messages';
import { CALL_FORWARDING_VALIDATION_MESSAGES } from './validation-messages/call-forwarding-validation.messages';
import { CALL_QUEUES_VALIDATION_MESSAGES } from './validation-messages/call-queues-validation.messages';
import { EXTENSIONS_VALIDATION_MESSAGES } from './validation-messages/extensions-validation.messages';
import { CONFIRMATION_CODE_VALIDATION_MESSAGES } from './validation-messages/confirmation-code-validation.messages';
import { PHONE_NUMBERS_VALIDATION_MESSAGES } from './validation-messages/phone-numbers-validation.messages';
import { ADDRESS_BOOK_VALIDATION_MESSAGES } from './validation-messages/address-book-validation.messages';
import { ACCOUNT_VALIDATION_MESSAGES } from './validation-messages/account-validation.messages';
import { SUPPORT_VALIDATION_MESSAGES } from './validation-messages/support-validation.messages';
import { AUTO_CALL_VALIDATION_MESSAGES } from './validation-messages/auto-call-validation.messages';
import { SETTINGS_PROFILE_VALIDATION_MESSAGES } from './validation-messages/settings-profile-validation.messages';
import { SETTINGS_EMAIL_VALIDATION_MESSAGES } from './validation-messages/settings-email-change-validation.messages';
import { SETTINGS_PASSWORD_VALIDATION_MESSAGES } from './validation-messages/settings-password-change-validation.messages';
import { ACCOUNT_NOTIFICATION_VALIDATION_MESSAGES } from './validation-messages/account-notification-validation.messages';
import { BITRIX_INTEGRATION_VALIDATION_MESSAGES } from './validation-messages/bitrix-integration-validation.messages';


export enum ValidationFormName {
  LOGIN = 'login',
  SIGN_UP = 'signUp',
  CALL_RULES = 'callRules',
  CALL_FORWARDING = 'callForwarding',
  CALL_QUEUES = 'callQueues',
  CONFIRMATION_CODE = 'confirmationCode',
  RESET_PASSWORD = 'resetPassword',
  COMPANY = 'company',
  PHONE_NUMBERS = 'phoneNumbers',
  IVR = 'ivr',
  EXTENSIONS = 'extensions',
  ADDRESS_BOOK = 'addressBook',
  ACCOUNT = 'account',
  SUPPORT = 'support',
  AUTO_CALL = 'autoCall',
  SETTINGS_PROFILE = 'settingsProfile',
  SETTINGS_EMAIL_CHANGE = 'settingsEmailChange',
  SETTINGS_PASSWORD_CAHNGE = 'settingsPasswordChange',
  ACCOUNT_NOTIFICATION = 'accountNotification',
  BITRIX_INTEGRATION = 'bitrixIntegration'
}

/**
 * Contais list of custom validation messages for application forms. The
 * form is presented by form name.
 */
export const VALIDATION_MESSAGES: FormValidationMessages = {
  account: [...ACCOUNT_VALIDATION_MESSAGES],
  accountNotification: [...ACCOUNT_NOTIFICATION_VALIDATION_MESSAGES],
  addressBook: [...ADDRESS_BOOK_VALIDATION_MESSAGES],
  autoCall: [...AUTO_CALL_VALIDATION_MESSAGES],
  bitrixIntegration: [...BITRIX_INTEGRATION_VALIDATION_MESSAGES],
  callRules: [...CALL_RULES_VALIDATION_MESSAGES],
  callForwarding: [...CALL_FORWARDING_VALIDATION_MESSAGES],
  callQueues: [...CALL_QUEUES_VALIDATION_MESSAGES],
  company: [...COMPANY_VALIDATION_MESSAGES],
  confirmationCode: [...CONFIRMATION_CODE_VALIDATION_MESSAGES],
  extensions: [...EXTENSIONS_VALIDATION_MESSAGES],
  ivr: fillValidationLevels(),
  login: [...LOGIN_VALIDATION_MESSAGES],
  phoneNumbers: [...PHONE_NUMBERS_VALIDATION_MESSAGES],
  resetPassword: [
    ...PASSWORD_AND_CONFIRM
  ],
  settingsEmailChange: [...SETTINGS_EMAIL_VALIDATION_MESSAGES],
  settingsPasswordChange: [
    ...SETTINGS_PASSWORD_VALIDATION_MESSAGES,
    ...PASSWORD_AND_CONFIRM
  ],
  settingsProfile: [...SETTINGS_PROFILE_VALIDATION_MESSAGES],
  signUp: [
    ...SIGN_UP_VALIDATION_MESSAGES,
    ...PASSWORD_AND_CONFIRM
  ],
  support: [...SUPPORT_VALIDATION_MESSAGES],
};
