import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const ACCOUNT_VALIDATION_MESSAGES = [
  {
    name: 'actualAddress',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Actual address',
  },
  {
    name: 'actualAddress',
    error: ValidationErrorType.MINLENGTH,
    message: 'Actual address is too short. Please use at least 5 characters',
  },
  {
    name: 'actualAddress',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Actual address is too short. Please use at least 5 characters',
  },
  {
    name: 'actualAddress',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Actual address can\'t contain over 255 characters',
  },
  {
    name: 'bankName',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Name of the bank',
  },
  {
    name: 'bankName',
    error: ValidationErrorType.MINLENGTH,
    message: 'Name of the bank is too short. Please use at least 3 characters',
  },
  {
    name: 'bankName',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Name of the bank is too short. Please use at least 3 characters',
  },
  {
    name: 'bankName',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Name of the bank can\'t contain over 255 characters',
  },
  {
    name: 'bik',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the BIC',
  },
  {
    name: 'bik',
    error: ValidationErrorType.MINLENGTH,
    message: 'BIC is too short. Please use at least 9 characters'
  },
  {
    name: 'bik',
    error: ValidationErrorType.MAXLENGTH,
    message: 'BIC is too long. Use no more than 9 numbers'
  },
  {
    name: 'bik',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'BIC' }
  },
  {
    name: 'birthDate',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Date of birth',
  },
  {
    name: 'birthDate',
    error: ValidationErrorType.INVALID_DATE,
    message: 'Invalid date format',
  },
  {
    name: 'birthDate',
    error: ValidationErrorType.AGE,
    message: 'You have to be at least 18 years old',
  },
  {
    name: 'city',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the City',
  },
  {
    name: 'city',
    error: ValidationErrorType.MINLENGTH,
    message: 'City is too short. Please use at least 3 characters',
  },
  {
    name: 'city',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'City is too short. Please use at least 3 characters',
  },
  {
    name: 'contactPersonName',
    error: ValidationErrorType.MINLENGTH,
    message: 'Contact person is too short. Please use at least 3 characters',
  },
  {
    name: 'contactPersonName',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Contact person is too short. Please use at least 3 characters',
  },
  {
    name: 'contactPersonName',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Contact person can\'t contain over 190 characters',
  },
  {
    name: 'contactPersonNumber',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'Contact phone is too short. Please use at least 10 characters' : 'Contact phone is too short. Please use at least 11 characters'
  },
  {
    name: 'contactPersonNumber',
    error: ValidationErrorType.MAXLENGTH,
    message: isComVersion() ? 'Contact phone is too long. Use no more than 16 numbers' : 'Contact phone is too long. Use no more than 11 numbers'
  },
  {
    name: 'contactPersonNumber',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Contact phone' }
  },
  {
    name: 'correspondentAccount',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Correspondent account'
  },
  {
    name: 'correspondentAccount',
    error: ValidationErrorType.MINLENGTH,
    message: 'Correspondent account is too short. Please use at least 20 characters'
  },
  {
    name: 'correspondentAccount',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Correspondent account is too long. Use no more than 20 numbers'
  },
  {
    name: 'correspondentAccount',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Correspondent account' }
  },
  {
    name: 'country',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Country',
  },
  {
    name: 'email',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter a valid email address'
  },
  {
    name: 'email',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Email can\'t contain over 190 characters'
  },
  {
    name: 'firstName',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the First name',
  },
  {
    name: 'generalManager',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the General Director (full name)',
  },
  {
    name: 'generalManager',
    error: ValidationErrorType.MINLENGTH,
    message: 'General Director (full name) is too short. Please use at least 5 characters',
  },
  {
    name: 'generalManager',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'General Director (full name) is too short. Please use at least 5 characters',
  },
  {
    name: 'generalManager',
    error: ValidationErrorType.MAXLENGTH,
    message: 'General Director (full name) can\'t contain over 255 characters',
  },
  {
    name: 'juridicalAddress',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Legal address',
  },
  {
    name: 'juridicalAddress',
    error: ValidationErrorType.MINLENGTH,
    message: 'Legal address is too short. Please use at least 5 characters',
  },
  {
    name: 'juridicalAddress',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Legal address is too short. Please use at least 5 characters',
  },
  {
    name: 'juridicalAddress',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Legal address can\'t contain over 255 characters',
  },
  {
    name: 'kpp',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Reason for Registration Code',
  },
  {
    name: 'kpp',
    error: ValidationErrorType.MINLENGTH,
    message: 'Reason for Registration Code is too short. Please use at least 9 characters'
  },
  {
    name: 'kpp',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Reason for Registration Code is too long. Use no more than 9 numbers'
  },
  {
    name: 'kpp',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Reason for Registration Code' }
  },
  {
    name: 'lastName',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Last name',
  },
  {
    name: 'mailingAddress',
    error: ValidationErrorType.MINLENGTH,
    message: 'Document delivery address is too short. Please use at least 5 characters',
  },
  {
    name: 'mailingAddress',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Document delivery address is too short. Please use at least 5 characters',
  },
  {
    name: 'mailingAddress',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Document delivery address can\'t contain over 255 characters',
  },
  {
    name: 'middleName',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Middle name is too short. Please use at least 1 character',
  },
  {
    name: 'ogrn',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Principle State Registration Number',
  },
  {
    name: 'ogrn',
    error: ValidationErrorType.MINLENGTH,
    message: 'Principle State Registration Number is too short. Please use at least 13 characters'
  },
  {
    name: 'ogrn',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Principle State Registration Number is too long. Use no more than 13 numbers'
  },
  {
    name: 'ogrn',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Principle State Registration Number' }
  },
  {
    name: 'ogrnip',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Primary State Registration Number of the Individual Entrepreneur',
  },
  {
    name: 'ogrnip',
    error: ValidationErrorType.MINLENGTH,
    message: 'Primary State Registration Number of the Individual Entrepreneur is too short. Please use at least 15 characters'
  },
  {
    name: 'ogrnip',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Primary State Registration Number of the Individual Entrepreneur is too long. Use no more than 15 numbers'
  },
  {
    name: 'ogrnip',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Primary State Registration Number of the Individual Entrepreneur' }
  },
  {
    name: 'okpo',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Russian National Nomenclator of Businesses and Organisations',
  },
  {
    name: 'okpo',
    error: ValidationErrorType.MINLENGTH,
    message: 'Russian National Nomenclator of Businesses and Organisations is too short. Please use at least 8 characters'
  },
  {
    name: 'okpo',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Russian National Nomenclator of Businesses and Organisations is too long. Use no more than 8 numbers'
  },
  {
    name: 'okpo',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Russian National Nomenclator of Businesses and Organisations' }
  },
  {
    name: 'oktmo',
    error: ValidationErrorType.MINLENGTH,
    message: 'Russian National Classification of Municipal Territories is too short. Please use at least 11 characters'
  },
  {
    name: 'oktmo',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Russian National Classification of Municipal Territories is too long. Use no more than 11 numbers'
  },
  {
    name: 'oktmo',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Russian National Classification of Municipal Territories' }
  },
  {
    name: 'orderOfAppointment',
    error: ValidationErrorType.REQUIRED,
    message: 'Please upload Order on the appointment of the head of the organization',
  },
  {
    name: 'passportIssueDate',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Passport date of issue',
  },
  {
    name: 'passportIssueDate',
    error: 'invalidDate',
    message: 'Invalid date format',
  },
  {
    name: 'passportNumber',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Passport number',
  },
  {
    name: 'passportNumber',
    error: ValidationErrorType.MINLENGTH,
    message: 'Passport number is too short. Please use at least 6 characters',
  },
  {
    name: 'passportNumber',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Passport number' }
  },
  {
    name: 'passportScan',
    error: ValidationErrorType.REQUIRED,
    message: 'Please upload Passport scan (page with photo)',
  },
  {
    name: 'phone',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Contact phone',
  },
  {
    name: 'phone',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'Phone is too short. Please use at least 10 characters' : 'Phone is too short. Please use at least 11 characters'
  },
  {
    name: 'phone',
    error: ValidationErrorType.MAXLENGTH,
    message: isComVersion() ? 'Phone is too long. Use no more than 16 numbers' : 'Phone is too long. Use no more than 11 numbers'
  },
  {
    name: 'phone',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Phone' }
  },
  {
    name: 'registrationAddress',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Address of registration',
  },
  {
    name: 'registrationAddress',
    error: ValidationErrorType.MINLENGTH,
    message: 'Address of registration is too short. Please use at least 5 characters',
  },
  {
    name: 'registrationAddress',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Address of registration is too short. Please use at least 5 characters',
  },
  {
    name: 'registrationAddress',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Address of registration can\'t contain over 255 characters',
  },
  {
    name: 'scanRegistration',
    error: ValidationErrorType.REQUIRED,
    message: 'Please upload Passport scan (page with registration)',
  },
  {
    name: 'seriesPassport',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Passport series',
  },
  {
    name: 'seriesPassport',
    error: ValidationErrorType.MINLENGTH,
    message: 'Passport series is too short. Please use at least 4 characters',
  },
  {
    name: 'seriesPassport',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Passport series' }
  },
  {
    name: 'settlementAccount',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Checking account'
  },
  {
    name: 'settlementAccount',
    error: ValidationErrorType.MINLENGTH,
    message: 'Checking account is too short. Please use at least 20 characters'
  },
  {
    name: 'settlementAccount',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Checking account is too long. Use no more than 20 numbers'
  },
  {
    name: 'settlementAccount',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Checking account' }
  },
  {
    name: 'vatId',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Individual Taxpayer Number',
  },
  {
    name: 'vatId',
    error: ValidationErrorType.MINLENGTH,
    message: 'Individual Taxpayer Number is too short. Please use at least 12 characters'
  },
  {
    name: 'vatId',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Individual Taxpayer Number is too long. Use no more than 12 numbers'
  },
  {
    name: 'vatId',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Individual Taxpayer Number' }
  },
];
