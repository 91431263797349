import { Mapper } from '@mappers/mapper';
import { MediaFileServerModel } from '@models/server/storage/media-file-server.model';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';

// tslint:disable-next-line:naming-convention
export let MediaFileMapper: Mapper<MediaFileModel, MediaFileServerModel> = {

  from(obj: MediaFileServerModel): MediaFileModel {
    if (!obj) { return null; }

    const minutes = Math.floor(obj.duration / 60);
    const seconds = obj.duration % 60;

    const usages = [];
    if (obj.accountFileIvrTrees.length > 0) {
      obj.accountFileIvrTrees.forEach(_ => usages.push({ module: 'ivr', name: _.ivr.name }));
    }
    if (obj.outerRuleAction.length > 0) {
      obj.outerRuleAction.forEach(_ => usages.push({ module: 'call-rule', name: _.description || '-' }));
    }

    return {
      id: obj.id,
      available: true,
      converted: obj.converted || 1,
      created: obj.created,
      currentTime: 0,
      downloadHash: obj.downloadHash,
      duration: obj.duration,
      durationTime: minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0'),
      externalId: obj.externalId,
      fileLink: obj.fileLink,
      fileName: obj.fileName,
      fileSize: obj.fileSize,
      md5: obj.md5,
      originalFileName: obj.originalFileName,
      sizeMb: Math.round(obj.fileSize / 1024 / 1024 * 100) / 100,
      type: obj.type,
      usages
    };
  },

  fromArray(arr: MediaFileServerModel[]): MediaFileModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: MediaFileModel): MediaFileServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
