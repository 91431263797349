import { ValidationErrorType } from './validation-error-type.enum';

export const CONFIRMATION_CODE_VALIDATION_MESSAGES = [
  {
    name: 'confirmationCode',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your confirmation code'
  },
  {
    name: 'confirmationCode',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter the correct confirmation code'
  }
];
