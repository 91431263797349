import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { TranslateService } from '@services/translate/translate.service';
import { SipInnerServerModel } from '@models/server/sip/sip-inner-server.model';
import { PageInfoModel } from '@models/common/table-models/page-info.model';
import { SipServerModel } from '@models/server/sip/sip-server.model';
import { CallRuleModel } from '@models/local/call-rule/call-rule.model';
import { convertPageInfo } from '@mappers/page-info.mapper';
import { SipModel } from '@models/local/sip/sip.model';
import { SipInnerModel } from '@models/local/sip/sip-inner.model';
import { SipMapper } from '@mappers/sip/sip.mapper';
import { SipInnerMapper } from '@mappers/sip/sip-inner.mapper';
import { AbstractCallRulesService } from './abstract-call-rule.service';


@Injectable({
  providedIn: 'root'
})
export class CallRulesService extends AbstractCallRulesService {

  constructor(
    protected http: HttpClient,
    protected translate: TranslateService
  ) {
    super(http, translate);
  }

  // -- BaseService implementation --------------------------------------------

  baseURL = `${environment.urlWithVersion}/outer_rule`;

  getItems(pageInfo: PageInfoModel<CallRuleModel>): Observable<PageInfoModel<CallRuleModel>> {
    return super.getItems(pageInfo)
      .pipe(
        tap(res => res.items = res.items.filter(i => i.sip !== null)),
      );
  }

  getItem(id: number): Observable<CallRuleModel> {
    return super.getItem(id)
      .pipe(
        mergeMap(rule => this
          .getExtensions(rule.sipId)
          .pipe(map(() => rule))
        ),
      );
  }

  setCallRuleStatus(id: number, enabled: boolean): Observable<void> {
    const request = { status: enabled };
    return this.http
      .put<void>(`${this.baseURL}/${id}/status`, request);
  }

  // -- References ------------------------------------------------------------

  getReferences(forceLoad: boolean = false): Observable<any> {
    return forkJoin([
      this.getCallRuleActions(forceLoad),
      this.getPhoneNumbers(forceLoad),
      this.getCallQueues(forceLoad),
      this.getMediaFiles(forceLoad),
      this.getNextQueues()
    ]);
  }

  getPhoneNumbers(forceLoad: boolean): Observable<SipModel[]> {
    if (this.phoneNumbers && !forceLoad) {
      return of(this.phoneNumbers);
    }
    return this.http
      .get<SipServerModel[]>(`${this.baseURL}/outers?limit=1000`)
      .pipe(
        map(res => SipMapper.fromArray(res)),
        tap(res => this.phoneNumbers = res)
      );
  }

  getExtensions(id: number): Observable<SipInnerModel[]> {
    let sipOuter;
    if (this.phoneNumbers) {
      sipOuter = this.phoneNumbers.find(sip => sip.id === id);
    }
    if (sipOuter && sipOuter.sipInners) {
      this.extensions = sipOuter.sipInners;
      return of(sipOuter.sipInners);
    } else {
      return this.http
        .get<PageInfoModel<SipInnerServerModel>>(`${this.baseURL}/inners?sipOuter=${id}`)
        .pipe(
          map(res => convertPageInfo(res, SipInnerMapper).items),
          tap(res => this.extensions = res)
        );
    }
  }
}
