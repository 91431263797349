import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'table[eclipseTooltip]'
})
export class EclipseDirective implements OnDestroy {

  toolTipElem: any;
  currentElement: {
    text: string;
    element: any;
    textElement: any;
    table: any;
  };
  event: any;
  constructor(@Inject(DOCUMENT) private _document: Document, private _renderer: Renderer2) {
    this.event = fromEvent(document, 'mouseout').takeUntil(componentDestroyed(this)).pipe(
      debounceTime(100)
    ).subscribe((e) => {
      this.onmouseOut(e);
    });
  }

  @HostListener('mouseover', ['$event'])
  onmouseover(e) {
    const path = this.composedPath(e.target); // e.path || e.composedPath();
    const elem = path.find(x => x.localName === 'th' || x.localName === 'td');
    const table = path.find(x => x.localName === 'table');
    if (elem) {
      this.resetTooltip();
      this.currentElement = {
        text: elem.innerText,
        element: elem,
        textElement: this.findTextElement(elem, elem.innerText),
        table
      };
      // Dirty crutch for edge
      let offset = 0;
      if (/Edge/.test(navigator.userAgent)) {
        offset = 1;
      }
      if (this.currentElement && this.currentElement.textElement.offsetWidth < this.currentElement.textElement.scrollWidth - offset
        && !this.currentElement.textElement.classList.contains('mat-checkbox-ripple')) {
        this.addTooltip();
      } else {
        this.currentElement = undefined;
      }
    }
  }

  @HostListener('mousewheel', ['$event'])
  onmousewheel(e) {
    this.onmouseover(e);
  }


  onmouseOut(e) {
    const path = this.composedPath(e.relatedTarget);
    if (this.currentElement && path && !path.some(x => x === this.currentElement.element || x === this.toolTipElem)) {
        this.resetTooltip();
    }
  }

  resetTooltip() {
    this.deleteTooltip();
    this.currentElement = undefined;
    this.toolTipElem = undefined;
  }

  addTooltip() {
    const elementRect = this.currentElement.element.getBoundingClientRect();
    const tableRect = this.currentElement.table.getBoundingClientRect();
    this.toolTipElem = this._renderer.createElement('div');
    const restrictionTooltip = (tableRect.left + tableRect.width + window.pageXOffset) / 3 * 2;
    this._renderer.addClass(this.toolTipElem, 'tooltip');
    const div = this._renderer.createElement('div');
    const span = this._renderer.createElement('span');
    this._renderer.appendChild(span, this._renderer.createText(this.currentElement.text));
    this._renderer.appendChild(this.toolTipElem, div);
    this._renderer.appendChild(this.toolTipElem, span);
    this._renderer.appendChild(this._document.body, this.toolTipElem);
    const maxWidth = tableRect.width / 3;
    let left = elementRect.left + window.pageXOffset;

    if (left > restrictionTooltip) {
      const tooltipRect = this.toolTipElem.getBoundingClientRect();
      this._renderer.addClass(this.toolTipElem, 'right');
      left = left - (tooltipRect.width > maxWidth ? maxWidth : tooltipRect.width) + 50;
    }
    this._renderer.setAttribute(this.toolTipElem,
      'style',
      `left:${left}px;
      top:${elementRect.top + window.pageYOffset + elementRect.height + 6}px;z-index:100;max-width:${maxWidth}px;word-wrap:break-word;`);

  }

  deleteTooltip() {
    if (this.toolTipElem) {
      this._renderer.removeChild(this._document.body, this.toolTipElem);
    }
  }

  private findTextElement(elem, text) {
    if (elem.childNodes.length) {
      for (let i = 0; i < elem.childNodes.length; ++i) {
        const item = elem.childNodes[i];
        if (item.innerText && item.innerHTML.trim() === text.trim()) {
          return item;
        }
        if (item.childNodes.length) {
          return this.findTextElement(item, text);
        }
      }
    }
    return elem;
  }

  ngOnDestroy(): void {
    this.deleteTooltip();
  }

  composedPath(el) {
    const path = [];

    while (el) {

      path.push(el);

      if (el.tagName === 'HTML') {

        path.push(document);
        path.push(window);

        return path;
      }

      el = el.parentElement;
    }
  }
}
