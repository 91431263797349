import { Mapper } from '@mappers/mapper';
import { CallQueueModel } from '@models/local/call-queue/call-queue.model';
import { CallQueueServerModel } from '@models/server/call-queue/call-queue-server.model';
import { QueueMemberMapper } from '../sip/queue-member.mapper';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { QueueMemberModel } from '@models/local/call-queue/queue-member.model';

// tslint:disable-next-line:naming-convention
export let CallQueueMapper: Mapper<CallQueueModel, CallQueueServerModel> = {

  from(obj: CallQueueServerModel): CallQueueModel {
    if (!obj) { return null; }

    let members: QueueMemberModel[];
    if (obj.queueMembers) {
      members = QueueMemberMapper.fromArray(obj.queueMembers);
    }

    return {
      id: obj.id,
      announceHoldtime: obj.announceHoldtime,
      announcePosition: obj.announcePosition,
      description: obj.description,
      externalQueueGroup: obj.externalQueueGroup,
      maxCallers: obj.maxMembersCount,
      memberCount: obj.memberCount,
      name: obj.name,
      queueMembers: members,
      ringTime: obj.timeout,
      strategyId: obj.strategy,
      strategyName: obj.strategyName,
    };
  },

  fromArray(arr: CallQueueServerModel[]): CallQueueModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: CallQueueModel): CallQueueServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
