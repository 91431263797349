import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const ADDRESS_BOOK_VALIDATION_MESSAGES = [
  {
    name: 'firstname',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter first name'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'First name can contain a maximum of 190 characters'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.PATTERN,
    message: 'You can use letters, numbers and the following characters: \'-_.'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'firstCharErrorMsg_Nt',
    params: { field: 'First name' }
  },
  {
    name: 'lastname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Last Name can\'t contain over 190 characters'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.PATTERN,
    message: 'You can use letters, numbers and the following characters: \'-_.'
  },
  {
    name: 'lastname',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'firstCharErrorMsg_Fm',
    params: { field: 'Last name' }
  },
  {
    name: 'contactPhone.*.value',
    error: ValidationErrorType.MINLENGTH,
    message: 'Please use at least ' + (isComVersion() ? 10 : 11) + ' characters'
  },
  {
    name: 'contactPhone.*.value',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Please don\'t use over ' + (isComVersion() ? 16 : 11) + ' characters'
  },
  {
    name: 'contactPhone.*.value',
    error: ValidationErrorType.PATTERN,
    message: 'Contact phone contains invalid characters. You can only use numbers and the following characters: #+'
  },
  {
    name: 'contactEmail.*.value',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter a valid email address'
  },
  {
    name: 'contactEmail.*.value',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Email can\'t contain over 190 characters'
  },
  {
    name: 'company',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Company name can\'t contain over 190 characters'
  },
  {
    name: 'department',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Department can\'t contain over 190 characters'
  },
  {
    name: 'position',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Position can\'t contain over 190 characters'
  },
  {
    name: 'address',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Address can\'t contain over 190 characters'
  },
  {
    name: 'address',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'firstCharErrorMsg_Ml',
    params: { field: 'Address' }
  },
  {
    name: 'address',
    error: ValidationErrorType.PATTERN,
    message: 'You can use letters, numbers and the following characters: -_.'
  }
];
