import { Component, OnInit, Input, forwardRef, ViewChild, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CallRulesService } from '@services/call-rules/call-rules.service';
import { clone } from '@helpers/utils/transformation.utils';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { EtlSelectComponent } from '@elements/etl-select/etl-select.component';
import { ScheduleDayModel } from '@models/local/call-rule/schedule-day.model';
import { CallRuleScheduleDateType } from '@shared/constant/call-rule.constants';
import { ListItemModel } from '@models/common/list-item.model';


@Component({
  selector: 'schedule-date',
  templateUrl: './schedule-date.component.html',
  styleUrls: ['./schedule-date.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleDateComponent),
      multi: true
    }
  ],
})
export class ScheduleDateComponent
  extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor {

  value: string;
  scheduleDays: ScheduleDayModel[];

  private _selectedType: number = CallRuleScheduleDateType.ALWAYS;
  get selectedType(): number {
    return this._selectedType;
  }
  @Input() set selectedType(val: number) {
    this._selectedType = +val;
  }

  @ViewChild('select', { static: false }) selectControl: EtlSelectComponent;

  // -- properties ------------------------------------------------------------

  get scheduleDateTypes(): ListItemModel[] {
    return this._service.scheduleDateTypes;
  }

  get daysVisible(): boolean {
    return this.selectedType === CallRuleScheduleDateType.WEEK_DAYS;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _service: CallRulesService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.scheduleDays = clone(this._service.scheduleDays);
    this.translate.register('schedule-date', this.scheduleDays, 'title');
  }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(value: any): void {
    if (!value || typeof value !== 'string') {
      return;
    }

    if (value === '*') {
      this.selectedType = CallRuleScheduleDateType.ALWAYS;
    } else {
      this.selectedType = CallRuleScheduleDateType.WEEK_DAYS;
      const days = value.split('&');
      this.scheduleDays
        .forEach(d => d.selected = days.includes(d.code));
    }
  }

  setValue(): void {
    let value: string;

    if (this.selectedType === CallRuleScheduleDateType.ALWAYS) {
      value = '*';
    } else if (this.selectedType === CallRuleScheduleDateType.WEEK_DAYS) {
      value = this.scheduleDays
        .filter(day => day.selected)
        .map(day => day.code)
        .join('&');
    }

    this.value = value;

    this.onChange(this.value);
  }

  // -- event handlers --------------------------------------------------------

  onFocus(event?: any): void {
    if (!this.focused) {
      this.focused = true;
      this.focus.emit(event);
    }
  }

  onBlur(event?: any): void {
    if (!this.selectControl.focused && this.focused) {
      this.focused = false;
      this.blur.emit(event);
    }
  }

  onDayClick(day: ScheduleDayModel): void {
    day.selected = !day.selected;
    this.setValue();
  }
}
