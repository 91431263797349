import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';

import { ResultValue } from '@shared/constant/result.value.constant';
import { MatInput } from '@angular/material/input';
import {clone} from '@helpers/utils/transformation.utils';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';

@Component({
  selector: 'etl-autocomplete',
  templateUrl: './etl-autocomplete.component.html',
  styleUrls: ['./etl-autocomplete.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlAutocompleteComponent),
      multi: true
    }
  ]
})
export class EtlAutocompleteComponent extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor {


  inputValue: string;
  changeTrigger$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredOptions: any[] = [];

  @Input() resultValue = ResultValue.FIELD;
  @Input() searchStartWith = true;
  @Input() options: any[];
  @Input() optionFieldName: string;
  @Input() findFunction: ((term: string) => any[]) | null = null;

  @Output() selected: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatInput, { static: false }) input: MatInput;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  writeValue(obj: any): void {
    if (this.resultValue === ResultValue.ID) {
      this.value = obj;
      const opt = this.options.find(o => o.id === +obj);
      if (opt) {
        this.inputValue = opt[this.optionFieldName];
      }
    } else {
      this.value = obj;
      this.inputValue = obj;
    }
    if (this.changeTrigger$.observers && this.changeTrigger$.observers.length) {
      this.changeTrigger$.next(this.inputValue);
    }
  }

  ngOnInit() {
    this.filteredOptions = this.options;
    // maybe we will add debounce and other pipe function
    this.changeTrigger$.takeUntil(componentDestroyed(this)).subscribe((term) => {
      // if not external find function use local.
      this.filteredOptions = this.findFunction ? this.findFunction(term) :
        this.localFilter(term);
    });
  }

  formater = (val: any): string => {
    if (typeof val === 'string') {
      return val;
    }
    return val ?
      (this.optionFieldName ? val[this.optionFieldName] : val)
      : '';
  }

  onChangeValue(e: any): void {
    this.value = this.getResult(e);
    this.onChange(this.value);
    this.selected.emit(this.value);
  }

  onInputChange(e: any): void {
    if (typeof e === 'string') {
      this.changeTrigger$.next(e);
    }
    this.onChangeValue(e);
  }

  // filter for options
  localFilter(term: string) {
    const val = term ? term.toString().toLowerCase() : '';
    if (this.optionFieldName) {
      return this.options.filter(x => {
        const res = x[this.optionFieldName].toLowerCase().indexOf(val);
        return this.searchStartWith ? res === 0 : res > -1;
      });
    }
    // if some element not string return all option
    if (this.options.some(x => typeof x !== 'string')) {
      return this.options;
    } else {
      return this.options.filter(x => {
        const res = x.toLowerCase().indexOf(val);
        return this.searchStartWith ? res === 0 : res > -1;
      });
    }
  }

  setFocus(): void {
    this.focused = true;
    this.input.focus();
  }

  onFocus(event) {
    this.focused = true;
    this.focus.emit(event);
  }

  onBlur(event) {
    this.focused = false;
    this.blur.emit(event);
    this.onTouched();
  }

  clear() {
    this.input.ngControl.reset(null);
    this.options = clone(this.options);
  }

  private getResult(val: any) {
    if (!val) {
      return;
    }
    switch (this.resultValue) {
      case ResultValue.ID:
        if (Object.keys(val).some(x => x === 'id')) {
          return val['id'];
        } else if (typeof val === 'string') {
          const opt = this.options.find(o => o[this.optionFieldName] === val);
          return opt ? opt.id : null;
        }
        throw new Error('Field id not exist');
      case ResultValue.FIELD:
        if (Object.keys(val).some(x => x === this.optionFieldName)) {
          return val[this.optionFieldName];
        } else {
          if (typeof val === 'string') {
            return val;
          }
        }
        throw new Error(`Field ${this.optionFieldName} not exist`);
      default:
        return val;
    }
  }
}
