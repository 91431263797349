import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HeaderComponent } from '@components/header/header.component';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { ElementsModule } from '@elements/elements.module';
import { EtlValidationDirective } from '@directives/etl-validation/etl-validation.directive';
import { FooterComponent } from '@components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from '@components/dialogs/confirm-dialog/confirm-dialog.component';
import { CustomDatePipe } from '@shared/pipes/custom-date.pipe';
import { ClickOutsideDirective } from '@directives/click-outside/click-outside.directive';
import { CalculateDurationPipe } from '@shared/pipes/calculate-duration.pipe';
import { DEFAULT_PERFECT_SCROLLBAR_CONFIG } from './app-settings/app.settings';
import { MediaFileSelectorComponent } from '@components/media-file-selector/media-file-selector.component';
import { MediaFilesUploaderComponent } from '@components/media-files-uploader/media-files-uploader.component';
import { PlayerTimePipe } from './pipes/player-time.pipe';
import { EtlTableMediaPlayerComponent } from '@components/table-media-player/table-media-player.component';
import { DocFileUploaderComponent } from './components/doc-file-uploader/doc-file-uploader.component';
import { TariffPlansComponent } from '@components/tariff-plans/tariff-plans.component';
import { ScheduleTimeComponent } from '@components/schedule-time/schedule-time.component';
import { ScheduleDateComponent } from '@components/schedule-date/schedule-date.component';
import { PhoneFormatterPipe } from './pipes/phone-formatter.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { CallRulesActionListComponent } from '@components/call-rules/action-list/call-rules-action-list.component';
import { CallQueueRedirectionComponent } from '@components/call-rules/call-queue-redirection/call-queue-redirection.component';
import { MediaFilePlaybackComponent } from '@components/call-rules/media-file-playback/media-file-playback.component';
import { SipInnerRedirectionComponent } from '@components/call-rules/sip-inner-redirection/sip-inner-redirection.component';
import { SipOuterRedirectionComponent } from '@components/call-rules/sip-outer-redirection/sip-outer-redirection.component';
import { RingGroupRedirectionComponent } from '@components/call-rules/ring-group-redirection/ring-group-redirection.component';
import { BaseCallRuleSidebarComponent } from '@components/call-rules/sidebar/base-call-rule-sidebar.component';
import { PageMediaPlayerComponent } from '@components/page-media-player/page-media-player.component';
import { ScheduleTimeMinutesComponent } from '@components/schedule-time-minutes/schedule-time-minutes.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';


const routes: Routes = [];
const importAndExport = [
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatInputModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  ElementsModule,
  TranslateModule,
  AngularSvgIconModule,
  FormsModule,
  ReactiveFormsModule,
  MatDialogModule,
  PerfectScrollbarModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatExpansionModule,
  MatRadioModule,
  MatBadgeModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ...importAndExport
  ],
  exports: [
    ...importAndExport,
    ClickOutsideDirective,
    CalculateDurationPipe,
    MatSlideToggleModule,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    EtlValidationDirective,
    ConfirmDialogComponent,
    CustomDatePipe,
    ClickOutsideDirective,
    MediaFileSelectorComponent,
    MediaFilesUploaderComponent,
    EtlTableMediaPlayerComponent,
    PlayerTimePipe,
    DocFileUploaderComponent,
    TariffPlansComponent,
    ScheduleDateComponent,
    ScheduleTimeComponent,
    ScheduleTimeMinutesComponent,
    PhoneFormatterPipe,
    FilterPipe,
    CallRulesActionListComponent,
    SipInnerRedirectionComponent,
    SipOuterRedirectionComponent,
    CallQueueRedirectionComponent,
    MediaFilePlaybackComponent,
    RingGroupRedirectionComponent,
    BaseCallRuleSidebarComponent,
    PageMediaPlayerComponent
  ],
  declarations: [
    HeaderComponent,
    NavigationComponent,
    EtlValidationDirective,
    FooterComponent,
    ConfirmDialogComponent,
    CustomDatePipe,
    ClickOutsideDirective,
    CalculateDurationPipe,
    MediaFileSelectorComponent,
    MediaFilesUploaderComponent,
    EtlTableMediaPlayerComponent,
    PlayerTimePipe,
    DocFileUploaderComponent,
    TariffPlansComponent,
    ScheduleDateComponent,
    ScheduleTimeComponent,
    ScheduleTimeMinutesComponent,
    PhoneFormatterPipe,
    FilterPipe,
    CallRulesActionListComponent,
    SipInnerRedirectionComponent,
    SipOuterRedirectionComponent,
    CallQueueRedirectionComponent,
    MediaFilePlaybackComponent,
    RingGroupRedirectionComponent,
    BaseCallRuleSidebarComponent,
    PageMediaPlayerComponent
  ],

  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [
    ConfirmDialogComponent
  ]
})
export class SharedModule { }
