import { ValidationErrorType } from './validation-error-type.enum';

export const PASSWORD_AND_CONFIRM = [
  {
    name: 'password',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your password'
  },
  {
    name: 'password',
    error: ValidationErrorType.MINLENGTH,
    // tslint:disable-next-line:max-line-length
    message: `The password is too simple. Please create another that contains at least 8 characters, both upper and lower case letters, at least one number, and one special character.`
  },
  {
    name: 'password',
    error: ValidationErrorType.PATTERN,
    // tslint:disable-next-line:max-line-length
    message: `The password is too simple. Please create another that contains at least 8 characters, both upper and lower case letters, at least one number, and one special character.`
  },
  {
    name: 'password',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Your password is too long. Please don\'t use over 20 characters'
  },
  {
    name: 'password_confirmation',
    error: ValidationErrorType.REQUIRED,
    message: 'Please confirm your password'
  },
  {
    name: 'password_confirmation',
    error: ValidationErrorType.MUST_MATCH,
    message: 'The passwords do not match'
  },
];
