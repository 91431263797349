import { Mapper } from '@mappers/mapper';
import { ContactEmailModel } from '@models/local/address-book/contact.model';
import { ContactEmailServerModel } from '@models/server/address-book/contact-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';


// tslint:disable-next-line:naming-convention
export let ContactEmailMapper: Mapper<ContactEmailModel, ContactEmailServerModel> = {
  
  from(obj: ContactEmailServerModel): ContactEmailModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      status: obj.status,
      typeId: obj.typeId,
      value: obj.value
    };
  },

  fromArray(arr: ContactEmailServerModel[]): ContactEmailModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: ContactEmailModel): ContactEmailServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
