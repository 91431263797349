import { ValidationErrorType } from './validation-error-type.enum';

export const ACCOUNT_NOTIFICATION_VALIDATION_MESSAGES = [
  {
    name: 'balance_near_zero_value',
    error: ValidationErrorType.MIN,
    message: 'Balance near zero value cannot be less than 10',
  },
  {
    name: 'balance_near_zero_value',
    error: ValidationErrorType.MAX,
    message: 'Balance near zero value cannot exceed 50000',
  },
  {
    name: 'balance_near_zero_value',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Balance near zero value' }
  },
  {
    name: 'minutes_near_zero_value',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'minutes_near_zero_value' }
  },
  {
    name: 'minutes_near_zero_value',
    error: ValidationErrorType.MIN,
    message: 'Minutes near zero value cannot be less than 50'
  },
  {
    name: 'minutes_near_zero_value',
    error: ValidationErrorType.MAX,
    message: 'Minutes near zero value cannot exceed 250',
  },
  {
    name: 'how_many_days',
    error: ValidationErrorType.MIN,
    message: 'How many days cannot be less than 3',
  },
  {
    name: 'how_many_days',
    error: ValidationErrorType.MAX,
    message: 'How many days cannot exceed 15',
  },
  {
    name: 'how_many_days',
    error: ValidationErrorType.PATTERN,
    message: 'How many days contains invalid characters. You can only use numbers'
  },
  {
    name: 'how_many_days',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter How Many Days'
  }
];
