import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { MainMenuModel, MenuType } from '@models/local/main-menu/main-menu.model';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CountryListServerModel } from '@models/server/country/country-list-server.model';

export const APP_TITLE = 'ENCRY Telecom';

export const LOADER_SETTINGS: NgxUiLoaderConfig = {
  'bgsColor': '#101515',
  'bgsOpacity': 0.5,
  'bgsPosition': 'bottom-right',
  'bgsSize': 60,
  'bgsType': 'rectangle-bounce',
  'blur': 50,
  'fgsColor': '#00ACC1',
  'fgsPosition': 'center-center',
  'fgsSize': 60,
  'fgsType': 'rectangle-bounce',
  'gap': 121,
  'logoPosition': 'center-center',
  'logoSize': 120,
  'logoUrl': '',
  'masterLoaderId': 'master',
  'overlayBorderRadius': '0',
  'overlayColor': 'rgba(30,29,29,0.8)',
  'pbColor': '#00ACC1',
  'pbDirection': 'rtl',
  'pbThickness': 5,
  'hasProgressBar': false,
  'text': '',
  'textColor': '#FFFFFF',
  'textPosition': 'center-center'
};

export const MAIN_MENU_ITEMS: MainMenuModel[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    icon: '/assets/icons/_menu/dashboard.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'refill',
    title: 'Refill balance',
    icon: '/assets/icons/_menu/refill_balance.svg',
    menuType: MenuType.ITEM
  },
  {
    menuType: MenuType.DIVIDER,
  },
  {
    path: 'address-book',
    title: 'Address Book',
    icon: '/assets/icons/_menu/address_book.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'phone-numbers',
    title: 'Phone Numbers',
    icon: '/assets/icons/_menu/phone_numbers.svg',
    menuType: MenuType.ITEM,
  },
  {
    menuType: MenuType.DIVIDER,
  },
  {
    path: 'call-forwarding',
    title: 'Call Forwarding',
    icon: '/assets/icons/_menu/call_forwarding.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'call-rules',
    title: 'Call Rules',
    icon: '/assets/icons/_menu/call_rules.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'call-forwarding',
    title: 'Call Forwarding',
    icon: '/assets/icons/_menu/call_forwarding.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'call-queues',
    title: 'Call Queues',
    icon: '/assets/icons/_menu/call_queues.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'new-call-queues',
    title: 'Call Queues',
    icon: '/assets/icons/_menu/call_queues.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'ivr',
    title: 'IVR',
    icon: '/assets/icons/_menu/ivr.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'transfer',
    title: 'Transfer with Support',
    icon: '/assets/icons/_menu/audio_conference.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'auto-call',
    title: 'Auto Call',
    icon: '/assets/icons/_menu/auto_call.svg',
    menuType: MenuType.ITEM,
  },
  {
    menuType: MenuType.DIVIDER,
  },
  // {
  //   path: 'company',
  //   title: 'Company',
  //   icon: 'computer',
  //   menuType: MenuType.ITEM,
  // },
  {
    path: 'account',
    title: 'Account',
    icon: '/assets/icons/_menu/company.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'extensions',
    title: 'Extensions',
    icon: '/assets/icons/_menu/extensions.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'tariff',
    title: 'Tariff plan',
    icon: '/assets/icons/_menu/tariff_plan.svg',
    menuType: MenuType.ITEM,
  },
  {
    menuType: MenuType.DIVIDER,
  },
  {
    path: 'cdr',
    title: 'Details and Records',
    icon: '/assets/icons/_menu/details_and_records.svg',
    menuType: MenuType.ITEM
  },
  {
    path: 'invoices',
    title: 'Invoices',
    icon: '/assets/icons/_menu/invoices.svg',
    menuType: MenuType.ITEM
  },
  {
    path: 'marketplace',
    title: 'Marketplace',
    icon: '/assets/icons/_menu/marketplace.svg',
    menuType: MenuType.ITEM
  },
  {
    menuType: MenuType.DIVIDER,
  },
  {
    path: 'storage',
    title: 'Storage',
    icon: '/assets/icons/_menu/storage.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'support',
    title: 'Support',
    icon: '/assets/icons/_menu/support.svg',
    menuType: MenuType.ITEM,
  },
  {
    path: 'settings',
    title: 'Settings',
    icon: '/assets/icons/_menu/settings.svg',
    menuType: MenuType.ITEM,
  }
];

export const EXTERNAL_PHONE_COUNTRIES: CountryListServerModel = {
  countries: [
    {id: 101, code: 'US', phoneCode: '1', title: 'United States'},
    {id: 21, code: 'CA', phoneCode: '1', title: 'Canada'},
    {id: 15, code: 'BE', phoneCode: '32', title: 'Belgium'},
    {id: 28, code: 'DK', phoneCode: '45', title: 'Denmark'},
    {id: 133, code: 'DM', phoneCode: '1767', title: 'Dominica'},
    {id: 29, code: 'DO', phoneCode: '1829', title: 'Dominican Republic'},
    {id: 45, code: 'ID', phoneCode: '62', title: 'Indonesia'},
    {id: 32, code: 'EE', phoneCode: '372', title: 'Estonia'},
    {id: 48, code: 'IL', phoneCode: '972', title: 'Israel'},
    {id: 50, code: 'JM', phoneCode: '1876', title: 'Jamaica'},
    {id: 57, code: 'LT', phoneCode: '370', title: 'Lithuania'},
    {id: 91, code: 'TN', phoneCode: '216', title: 'Tunisia'},
    {id: 78, code: 'RU', phoneCode: '7', title: 'Russian Federation'},
    {id: 11, code: 'AU', phoneCode: '61', title: 'Australia'},
    {id: 23, code: 'CR', phoneCode: '506', title: 'Costa Rica'},
    {id: 27, code: 'CZ', phoneCode: '420', title: 'Czech Republic'},
    {id: 28, code: 'DK', phoneCode: '45', title: 'Denmark'},
    {id: 33, code: 'FI', phoneCode: '358', title: 'Finland'},
    {id: 36, code: 'DE', phoneCode: '49', title: 'Germany'},
    {id: 47, code: 'IE', phoneCode: '353', title: 'Ireland'},
    {id: 49, code: 'IT', phoneCode: '39', title: 'Italy'},
    {id: 68, code: 'NL', phoneCode: '31', title: 'Netherlands'},
    {id: 69, code: 'NZ', phoneCode: '64', title: 'New Zealand'},
    {id: 82, code: 'SG', phoneCode: '65', title: 'Singapore'},
    {id: 83, code: 'SI', phoneCode: '386', title: 'Slovenia'},
    {id: 87, code: 'SE', phoneCode: '46', title: 'Sweden'},
    {id: 88, code: 'CH', phoneCode: '41', title: 'Switzerland'},
    {id: 96, code: 'GB', phoneCode: '44', title: 'United Kingdom'}
  ]
};

export const PROGILE_MENU_ITEMS: MainMenuModel[] = [
  {
    path: '/logout',
    title: 'Exit',
    icon: '/assets/icons/_menu/exit.svg',
    menuType: MenuType.ITEM
  },
];

export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

export const WIDTH_FROM_SECOND_TABLE_BUY_EXTERNAL_PHONE_NUMBERS = 1320;

export const DEBOUNCE_TIME = 600;
export const SHORT_DEBOUNCE_TIME = 300;

export const MAX_IVR_LEVEL = 5;

export const NOTIFICATION_MSG_SHOW_TIME: number = 5000;
// export const NOTIFICATION_MSG_SHOW_TIME: number = 995000;

export const MAX_RESULT_ROWS = 65535;
export const MAX_PAGE_ROWS_COUNT = 10;

export const SERVER_DATE_FORMAT = 'DD-MM-YYYY';
export const RAW_SERVER_DATE_FORMAT = 'YYYY-MM-DD';
