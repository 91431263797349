import { Component, OnInit, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';

import { StorageService } from '@services/storage/storage.service';
import { TtsRequestModel } from '@models/local/storage/tts-request.model';
import { TtsGender } from '@models/local/storage/tts-gender.enum';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { TtsProvider } from '@models/local/storage/tts-provider.enum';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/notification/notification.service';


@Component({
  selector: 'etl-text-speech-generator',
  templateUrl: './etl-text-speech-generator.component.html',
  styleUrls: ['./etl-text-speech-generator.component.sass']
})
export class EtlTextSpeechGeneratorComponent implements OnInit {

  tts: TtsRequestModel = {
    gender: TtsGender.MALE,
    provider: TtsProvider.YANDEX,
    text: null
  };

  @Output() create: EventEmitter<MediaFileModel> = new EventEmitter();

  // -- properties ------------------------------------------------------------

  get generateDisabled(): boolean {
    return !this.tts.text || !this.tts.text.trim()
      || !this.tts.gender;
  }

  get isPlayDisabled(): boolean {
    return false;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    public storage: StorageService,
    public dialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void { }

  // -- event handlers --------------------------------------------------------

  onGenerate(): void {
    this.storage
      .textToSpeech(this.tts)
      .subscribe(
        media => this.create.emit(media),
        err => {
          const message  = err.error ? err.error.message : err.message;
          this.notification.error(message);
        });
  }

  showHelp(templateRef: TemplateRef<any>) {
    // console.log(templateRef);
    this.dialog.open(templateRef);
  }
}
