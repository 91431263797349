import { Mapper } from '@mappers/mapper';
import { NavigationItemModel } from '@models/server/user/navigationMenuModel';
import { MainMenuModel, MenuType } from '@models/local/main-menu/main-menu.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { MAIN_MENU_ITEMS } from '@shared/app-settings/app.settings';

// tslint:disable-next-line:naming-convention
export let MainMenuMapper: Mapper<MainMenuModel[], NavigationItemModel[][]> = {

  from(obj: NavigationItemModel[][]): MainMenuModel[] {
    const result = [] as MainMenuModel[];

    obj.forEach(element => {
      element.forEach(menu => {
        const menuElem = MAIN_MENU_ITEMS.find(x => x.path === menu.url);
        if (menuElem) {
          menuElem.disabled = menu.disabled;
          result.push(menuElem);
        } else {
          console.warn('dont find menu element', menu);
        }
      });
      result.push({ menuType: MenuType.DIVIDER });
    });

    // TODO: dev purposes. remove it.
    // const item = MAIN_MENU_ITEMS.find(x => x.path === 'call-forwarding');
    // if (item) {
    //   result.push(item);
    // }

    return result;
  },

  to(obj: MainMenuModel[]): NavigationItemModel[][] {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
