import { Mapper } from '@mappers/mapper';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { TariffModel } from '@models/local/tariff-plan/tariff.model';
import { TariffServerModel } from '@models/server/tariff-plan/tariff-server.model';
import { ModuleModel } from '@models/local/tariff-plan/module.model';
import { ModuleMapper } from '@mappers/user/module.mapper';
import { TariffPlanModel } from '@models/local/tariff-plan/tariff-plan.model';
import { TariffPlanMapper } from '@mappers/tariff-plan/tariff-plan.mapper';

// tslint:disable-next-line:naming-convention
export let TariffMapper: Mapper<TariffModel, TariffServerModel> = {

  from(obj: TariffServerModel): TariffModel {
    if (!obj) {
      return null;
    }

    const serviceTemplate: ModuleModel[] = ModuleMapper.fromArray(obj.services);
    const tariffPlans: TariffPlanModel[] = TariffPlanMapper.fromArray(obj.tariffs);

    serviceTemplate.forEach(service => {
      tariffPlans.map(tariff => {
        const equalName = tariff.services.find(s => s.name === service.name);
        const forward = tariff.services.find(s => s.name === 'callForwarding');
        const ivrLite = tariff.services.find(s => s.name === 'ivrLite');

        if (equalName) {
          equalName.exists = 1;
        } else if (forward && service.name === 'callRules') {
          forward.exists = 1;
        } else if (ivrLite && service.name === 'ivr') {
          ivrLite.exists = 1;
        } else if (!equalName) {
          tariff.services.push(service);
        }

      });
    });

    tariffPlans.map(tp => {
      tp.services.sort(function(obj1, obj2) {
        return obj1.sort - obj2.sort;
      });
    });

    return {
      tariffPlans: tariffPlans
    };
  },

  to(obj: TariffModel): any {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
