import { Mapper } from '@mappers/mapper';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { CdrModel } from '@models/local/cdr/cdr.model';
import { CdrServerModel } from '@models/server/cdr/cdr-server.model';
import { CdrType } from '@models/local/cdr/cdr-type.enum';
import { CdrStatus } from '@models/local/cdr/cdr-status.enum';
import { MediaFileMapper } from '@mappers/storage/media-file.mapper';
import { ContactMapper } from '@mappers/address-book/contact.mapper';
import { capitalizeWords } from '@helpers/utils/string.utils';
import { RE_NUMBER } from '@shared/constant/regexp';

// tslint:disable-next-line:naming-convention
export let CdrMapper: Mapper<CdrModel, CdrServerModel> = {
  from(obj: CdrServerModel): CdrModel {
    if (!obj) { return null; }

    let type: CdrType;
    switch (obj.type) {
      case 0:
        type = CdrType.INCOMING;
        break;
      case 1:
        type = CdrType.OUTGOING;
        break;
    }

    let status: CdrStatus;
    switch (obj.status) {
      case 0:
        status = CdrStatus.ANSWERED;
        break;
      case 1:
        status = CdrStatus.NO_ANSWER;
        break;
      case 2:
        status = CdrStatus.BUSY;
        break;
      case 3:
        status = CdrStatus.FAILED;
        break;
      case 4:
      default:
        status = CdrStatus.UNKNOWN;
        break;
    }

    let mod: string;
    let modName: string;
    if (obj.linkedObject) {
      [mod, modName] = obj.linkedObject.split(':');
      mod = capitalizeWords(mod.trim());
    }

    let name = null;

    if (obj.name) {
      name = obj.name;
    } else if (obj.type === 0) {
      name = obj.contactSrc ? obj.contactSrc.name : null;
    } else {
      name = obj.contactDst ? obj.contactDst.name : null;
    }

    const res = {
      id: obj.id,
      callDate: obj.callDate,
      canBlock: obj.canBlock,
      contact: null,
      contactSrc: ContactMapper.from(obj.contactSrc),
      contactDst: ContactMapper.from(obj.contactDst),
      currencySymbol: null,
      source: obj.source,
      sourceIsView: false,
      sourceView: obj.source,
      destination: obj.destination,
      destinationIsView: false,
      destinationView: obj.destination,
      duration: obj.duration,
      extension: obj.lastExten,
      isNew: !!obj.isNewCdr,
      linkedModule: mod,
      linkedName: modName,
      media: MediaFileMapper.from(obj.accountFile),
      price: obj.price,
      status: status,
      type: type,
      name: name,
      tag: obj.tag,
    };

    if (res.contactSrc && res.contactSrc.name && res.contactSrc.name !== res.source) {
      res.sourceIsView = true;
      res.sourceView = res.contactSrc.name;
    }

    if (res.contactDst && res.contactDst.name && res.contactDst.name !== res.destination) {
      res.destinationIsView = true;
      res.destinationView = res.contactDst.name;
    }

    res.contact = res.contactSrc || res.contactDst;

    return res;
  },

  fromArray(arr: CdrServerModel[]): CdrModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: CdrModel): CdrServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
