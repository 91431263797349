import {Component, OnInit, Input, Renderer2, RendererFactory2, OnDestroy} from '@angular/core';
import { TranslateService } from '@services/translate/translate.service';

import { MainMenuModel, MenuType } from '@models/local/main-menu/main-menu.model';
import { environment } from '@env/environment';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { ThemeService } from '@services/theme/theme.service';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { UserDataModel } from '@models/local/user/user-data.model';
import { GuideService } from '@services/guide/guide.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { WebSocketService } from '@services/web-socket/web-socket.service';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';


@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  itemType = MenuType;
  version: string;
  user: UserDataModel;
  menuShowHide: boolean = false;
  currentYear = new Date().getFullYear();
  avatar: string = '../../../assets/images/avatar/no_avatar.jpg';
  balance: number;

  @Input() title: string;
  @Input() profileMenu: MainMenuModel[];

  constructor(
    private _translate: TranslateService,
    private _storage: BrowserStorageService,
    private _themeService: ThemeService,
    private _authService: AuthenticationService,
    private _navService: NavigationService,
    public guide: GuideService,
    private _ws: WebSocketService
  ) {
    this.version = environment.version;
    this._authService.currentUser.subscribe((u) => {
      if (u && this._authService.token) {
        this.user = u;
        this.avatar = this.user.avatar + '?accessToken=' + this._authService.token.accessTokenInline;
        this.balance = this.user.balance.value;
      }
    });
  }

  ngOnDestroy(): void {}


  ngOnInit() {
    this.webSocketBalance();
  }

  sidenavToggle() {
    this._navService.toggle();
  }

  showHide(outside) {
    if (outside) {
      if (this.menuShowHide !== false) {
        return this.menuShowHide = !this.menuShowHide;
      }
    } else {
      return this.menuShowHide = !this.menuShowHide;
    }
  }

  onChangeLanguage(lang: string): void {
    this._translate.use(lang);
    this._storage.writeItem('user_lang', lang);
  }

  onChangeTheme(theme: string) {
    this._themeService.setTheme(theme);
  }

  webSocketBalance(): void {
    this._ws.balance$.takeUntil(componentDestroyed(this)).subscribe(
      (data) => {
        if (data) {
          this.balance = data;
        }
      });
  }
}
