import { Mapper } from '@mappers/mapper';
import { UserDataModel } from '@models/local/user/user-data.model';
import { UserDataServerModel } from '@models/server/user/user-data-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { BalanceMapper } from '@mappers/balance/balance.mapper';
import { TariffPlanMapper } from '@mappers/tariff-plan/tariff-plan.mapper';
import { ModuleMapper } from './module.mapper';

// tslint:disable-next-line:naming-convention
export let UserDataMapper: Mapper<UserDataModel, UserDataServerModel> = {

  from(obj: UserDataServerModel): UserDataModel {
    if (!obj) { return null; }

    return {
      id: obj.user.id,
      balance: BalanceMapper.from(obj.balance),
      dateFormat: obj.dateFormat,
      timeFormat: obj.timeFormat,
      avatar: obj.user.avatar,
      email: obj.user.email,
      phone: obj.user.phone,
      firstname: obj.user.firstname,
      lastname: obj.user.lastname,
      patronymic: obj.user.patronymic,
      tariffPlan: TariffPlanMapper.from(obj.user.tariffPlan),
      nextTariffPlanId: obj.user.nextTariffPlanId,
      services: ModuleMapper.fromArray(obj.services),
      personalAccount: obj.user.personalAccount,
      accountStatus: obj.user.accountStatus,
      verificationStatus: obj.verificationStatus,
      accountVerification: obj.accountVerification,
      firstNumber: obj.firstNumber,
      timeZoneOffset: obj.timeZoneOffset,
      isBlocked: obj.isBlocked
    };
  },

  fromArray(arr: UserDataServerModel[]): UserDataModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: UserDataModel): UserDataServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
