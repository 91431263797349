import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RefillDataServerModel, RefillMethodServerModel } from '@models/server/refill/refillModel';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { tap, map } from 'rxjs/operators';
import { PaymentModel } from '@models/server/refill/paymentModel';

@Injectable({
  providedIn: 'root'
})
export class RefillBalanceService {

  refillAmount: number = 0;

  constructor(private _http: HttpClient) {

  }

  getRefillMethods(): Observable<RefillDataServerModel> {
    return this._http.get<RefillDataServerModel>(`${environment.urlWithVersion}/balance/refill`).pipe(
      map(res => {
        if (this.refillAmount) {
          res.refillSum = this.refillAmount;
          this.refillAmount = 0;
        } else {
          res.refillSum = Math.round(res.tariffPlan.sum);
        }
        return res;
      })
    );
  }

  getRefillMethod(id: number, amount: number): Observable<RefillMethodServerModel> {
    return this._http.get<any>(`${environment.urlWithVersion}/balance/refill/${id}?amount=${amount}`).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  setRefillMethod(id: number, amount: number, returnAddress: string): Observable<any> {
    return this._http.post<any>(`${environment.urlWithVersion}/balance/refill`,
      { refillMethodId: id, amount: amount, returnAddress: returnAddress }).pipe(
        map(res => {
          if (res.result.formUrl) {
            sessionStorage.setItem('payment', 'processing');
            window.open(res.result.formUrl, '_self');
          }
          return res['result'];
        })
      );
  }

  getPaymentSuccess(id: number, orderId: any): Observable<PaymentModel> {
    return this._http.get<PaymentModel>(`${environment.urlWithVersion}/balance/invoice/success/${id}?orderId=${orderId}`);
  }

  getPaymentFail(id: number, orderId: any): Observable<PaymentModel> {
    return this._http.get<PaymentModel>(`${environment.urlWithVersion}/balance/invoice/fail/${id}?orderId=${orderId}`);
  }
}
