export interface CodeErrorResponse {
  code: ErrorResponseCode;
  object: string;
  objectName: string;
  messageTemplate: string;
  message: string;
}

export enum ErrorResponseCode {
  IN_USE = 1,
  IS_LARGE = 2
}
