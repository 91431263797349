import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';
import { USER_LANG } from '@shared/constant/storage.const';
import { TranslateService } from '@services/translate/translate.service';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.sass',
    './footer.component.ru.sass',
    './footer.component.en.sass'
  ]
})
export class FooterComponent implements OnInit {

  languages: any[];
  currentLang: any;
  constructor(
    private _translate: TranslateService,
    private _storage: BrowserStorageService
  ) {
    this.languages = [
      { id: 'en', title: 'English (United States)' },
      { id: 'ru', title: 'Русский' },
    ];
    const curLang = this._translate.currentLang || this._storage.readItem(USER_LANG) || environment.defaultLang;
    this.currentLang = curLang;
  }

  ngOnInit() {
  }

  onChangeLanguage(lang: any): void {
    if (lang.value) {
      this._translate.use(lang.value.id);
      this._storage.writeItem(USER_LANG, lang.value.id);
    } else {
      this._translate.use(lang.id);
      this._storage.writeItem(USER_LANG, lang.id);
    }
  }

  getLink(fileName: string) {
    return `./assets/pdf/${this._translate.currentLang}/${fileName}`;
  }

}
