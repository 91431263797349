export const TIME_RULES_DEFAULT = '*|*|*|*';

export enum CallRuleScheduleDateType {
  ALWAYS = 1,
  WEEK_DAYS = 3
}

export enum CallRuleScheduleTimeType {
  ALWAYS = 1,
  CERTAIN_TIME = 2
}

export enum CallRuleActionType {
  SIP_INNER_REDIRECTION = 1,
  SIP_OUTER_REDIRECTION = 2,
  CALL_QUEUE_REDIRECTION = 3,
  CALL_TERMINATION = 4,
  MEDIA_FILE_PLAYBACK = 5,
  RING_GROUP_REDIRECTION = 6,
  PLAY_VOICE_FILE_TO_PARTY = 7,
  REDIRECT_TO_NEXT_QUEUE = 10
}

export const NON_SCHEDULED_ACTIONS = [
  CallRuleActionType.CALL_TERMINATION
];
