import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Injector,
} from '@angular/core';
import 'rxjs/add/operator/takeUntil';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { BaseModel } from '@models/local/base.model';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';


@Component({
  selector: 'etl-select',
  templateUrl: './etl-select.component.html',
  styleUrls: ['./etl-select.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlSelectComponent),
      multi: true
    }
  ]
})
export class EtlSelectComponent
  extends EtlBaseFormControlComponent
  implements OnInit, OnChanges, ControlValueAccessor {

  @Input() value: any;
  @Input() disabled: boolean = false;
  @Input() options: BaseModel[];
  @Input() optionFieldName: string;
  @Input() useIdAsValue: boolean = true;

  @ViewChild(MatSelect, { static: false }) input: MatSelect;

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.input) {
      this.input.stateChanges
        .takeUntil(componentDestroyed(this))
        .subscribe(() => {
          this.onStateChanges(this.input.focused);
        });
    }
    // this.input.overlayDir.positionChange
    //   .takeUntil(this.destroy$)
    //   .subscribe(res => {
    //     const isTop = res.connectionPair.overlayY === 'top';
    //     let ofsetY = this.input.trigger.nativeElement.offsetHeight + 1;
    //     ofsetY = isTop ? ofsetY : -ofsetY - 40;
    //     this.input.overlayDir.offsetY = ofsetY;
    //   });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      const options = changes.options.currentValue;
      if (this.useIdAsValue && this.value != null
        && options[0].hasOwnProperty('id')) {
        if (typeof this.value === 'object') {
          this.value = options.find(o => o.id === this.value.id);
        } else {
          this.value = options.find(o => o.id === this.value);
        }
      }
    }
  }

  // -- ControlValueAccessor overrides ----------------------------------------

  writeValue(value: any): void {
    if (this.isFindById()) {
      const opt = this.options
        .find(o => o.id.toString() === value?.toString());
      this.value = opt;
    } else {
      this.value = value;
    }
  }

  // -- event handlers --------------------------------------------------------

  onChangeValue(event: MatSelectChange): void {
    this.setValue(event.value);
    this.change.emit(event);
  }

  onStateChanges(state: boolean): void {
    if (state) {
      this.onFocus(state);
    } else if (this.focused) {
      this.onBlur(state);
    }
  }

  // -- general methods -------------------------------------------------------

  setFocus(): void {
    this.focused = true;
    this.input.focus();
  }

  setValue(value: any): void {
    this.value = value;
    const valueChanges = this.useIdAsValue ? value.id : value;
    this.onChange(valueChanges);
    this.onTouched();
  }

  reset(): void {
    this.input.value = null;
    this.onChange(null);
    this.onTouched();
  }

  private isFindById(): boolean {
    return this.options
      && this.options.length > 0
      && Object.keys(this.options[0]).some(k => k === 'id');
  }

  createPlaceholder(value: string): void {
    if (value) {
      this.localPlaceholder = `[${this.translate.instant(value)}]`;
    } else {
      this.localPlaceholder = '';
    }
  }
}
