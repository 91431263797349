import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  OnDestroy,
  Renderer2,
  OnChanges,
  SimpleChanges,
  QueryList,
  ContentChildren,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ValidationService } from '@services/validation/validation.service';
import { EtlSelectComponent } from '@elements/etl-select/etl-select.component';
import { EtlInputComponent } from '@elements/etl-input/etl-input.component';
import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { EtlCheckboxComponent } from '@elements/etl-checkbox/etl-checkbox.component';
import { ScheduleDateComponent } from '@components/schedule-date/schedule-date.component';
import { ScheduleTimeComponent } from '@components/schedule-time/schedule-time.component';
import { CustomValidationMessage } from '@models/local/validation/custom-validation-message';
import { MediaFileSelectorComponent } from '@components/media-file-selector/media-file-selector.component';
import { EtlAutocompleteComponent } from '@elements/etl-autocomplete/etl-autocomplete.component';
import { DocFileUploaderComponent } from '@components/doc-file-uploader/doc-file-uploader.component';
import { EtlTextareaComponent } from '@elements/etl-textarea/etl-textarea.component';
import { EtlDatepickerComponent } from '@elements/etl-datepicker/etl-datepicker.component';
import { LangChangeEvent } from '@ngx-translate/core';
import { TranslateService } from '@services/translate/translate.service';
import { EtlSelectNewComponent } from '@elements/etl-select-new/etl-select-new.component';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';
import { EtlDadataComponent } from '@elements/etl-dadata/etl-dadata.component';


export const ETL_CONTROLS_SELECTOR: string[] = [
  'etl-input',
  'etl-textarea',
  'etl-select',
  'etl-select-new',
  'etl-checkbox',
  'etl-datepicker',
  'etl-autocomplete',
  'schedule-date',
  'schedule-time',
  'schedule-time-minutes',
  'media-file-selector',
  'doc-file-uploader',
  'etl-dadata'
];
export const FORM_CONTAINER_SELECTOR: string[] = [
  'personal-form,legal-form,entrepreneur-form',
  '.form-container > .mat-drawer-inner-container',
  '.mat-drawer-content'
];
export const CTRL_VISIBILITY_RATE: number = 0.7;

@Directive({
  selector: '[etlValidation]'
})
export class EtlValidationDirective implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  invalidControls: string[] = [];
  errorControl: string;

  handlers: any[] = [];
  domHandlers: Function[] = [];
  invalidControlsSubs: Subscription;
  errorChangesSubs: Subscription;
  scrollToSubs: Subscription;
  reInitSubs: Subscription;

  @Input() formGroup: FormGroup;
  @Input() formControlPath: any[] = [];
  @Input() validationMessages: CustomValidationMessage[];
  @Input() serverErrors: any;

  @ContentChildren(EtlInputComponent) inputComponents: QueryList<EtlInputComponent>;
  @ContentChildren(EtlTextareaComponent) textareaComponents: QueryList<EtlTextareaComponent>;
  @ContentChildren(EtlSelectComponent) selectComponents: QueryList<EtlSelectComponent>;
  @ContentChildren(EtlSelectNewComponent) selectNewComponents: QueryList<EtlSelectNewComponent>;
  @ContentChildren(EtlCheckboxComponent) checkboxComponents: QueryList<EtlCheckboxComponent>;
  @ContentChildren(ScheduleDateComponent) scheduleDateComponents: QueryList<ScheduleDateComponent>;
  @ContentChildren(ScheduleTimeComponent) scheduleTimeComponents: QueryList<ScheduleTimeComponent>;
  @ContentChildren(MediaFileSelectorComponent) mediaFileSelectorComponents: QueryList<MediaFileSelectorComponent>;
  @ContentChildren(EtlAutocompleteComponent) autocompleteComponents: QueryList<EtlAutocompleteComponent>;
  @ContentChildren(DocFileUploaderComponent) docUploaderComponents: QueryList<DocFileUploaderComponent>;
  @ContentChildren(EtlDatepickerComponent) datepickerComponents: QueryList<EtlDatepickerComponent>;
  @ContentChildren(EtlDadataComponent) dadataComponents: QueryList<EtlDadataComponent>;
  // -- component lifecycle methods -------------------------------------------

  constructor(
    private _element: ElementRef,
    private _renderer: Renderer2,
    private _service: ValidationService,
    private _translate: TranslateService
  ) { }

  /**
   * @description
   * Initializes validation service with form custom validation messages
   * passed by input parameters.
   * Subscribes on invalidControlsChanges ans errorVisibilityChanges service
   * events to redraw related component view.
   */
  ngOnInit(): void {
    // Init validation service with form and custom validation messages.
    if (this.formGroup) {
      this._service.init(this.formGroup, this.validationMessages);
    }

    // Subscribe on service invalid control list changes. Validation service
    // informs that invalid controls list has been changed.
    this.invalidControlsSubs = this._service.invalidControlsChanges$
      .subscribe(() => {
        this.onInvalidControlsChanges();
      });

    // Subscribe on service error visibility changes. Validation service
    // informs that visible error has been changed.
    this.errorChangesSubs = this._service.errorVisibilityChanges$
      .subscribe(() => {
        this.onErrorVisibilityChanges();
      });

    // Subscribe on scroll to first error notification. The service informs
    // that form needs to be scrolled to the first error element.
    this.scrollToSubs = this._service.scrollToFirstError$
      .subscribe((name) => {
        this.onScrollToFirstError(name);
      });

    // Subscribe to reInitDirective service notification. It is used to provide
    // ability directive to correctly handle dynamic form changes.
    this.reInitSubs = this._service.reInitDirective$
      .subscribe(() => {
        setTimeout(() => this.reInitDirective(), 0);
      });

    this._translate.onLangChange.takeUntil(componentDestroyed(this)).subscribe(() => {
      this.errorControl = '';
      this.onErrorVisibilityChanges();
    });
  }

  /**
   * @description
   * Subscribes validation service on form components focus, blur, mouseenter,
   * mouseleave events.
   * Adds subscription on document click event to handle 'click form outside'
   * state.
   * Determines form controls that are visible at the current state.
   */
  ngAfterViewInit(): void {
    this.iterateComponents((component) => {
      this.subscribeComponentEvents(component);
    });

    let fn = this._renderer.listen(document, 'click', () => {
      this.onClick();
    });
    this.domHandlers.push(fn);

    setTimeout(() => {
      const container = this.getScrollContainer();
      if (container) {
        fn = this._renderer
          .listen(container, 'scroll', () => {
            this.checkVisibleComponents();
            this._service.updateValidationState();
          });
        this.domHandlers.push(fn);
      }
    }, 0);

    setTimeout(() => {
      this.checkVisibleComponents();
    }, 0);
  }

  /**
   * @description
   * Detremines currently visible form controls (when form has scroll) and
   * passes visible control list t the validation service.
   * The only controls that are shown more than 50% {visibilityRate} of
   * theirs area will be treated as visible.
   */
  checkVisibleComponents(): void {
    const visibilities = [];

    const container = this.getScrollContainer();
    if (!container) {
      return;
    }

    const visibleHeight = container.scrollHeight || container.clientHeight;
    const containerOffset = container.scrollTop;

    this.iterateComponents((component) => {
      const componentTop = component.element.nativeElement.offsetTop;
      const positionTop = componentTop - containerOffset;
      const [rect1, rect2] = component.element.nativeElement.getClientRects();
      const rect = rect2 || rect1;

      let state = true; // false. TODO: turn on for debug purposes
      if (rect) {
        if (positionTop / rect.height + 1 >= CTRL_VISIBILITY_RATE
          && (visibleHeight - positionTop) / rect.height >= CTRL_VISIBILITY_RATE) {
          state = true;
        }
      }
      visibilities.push({
        name: this.getFormControlName(component.formControlName),
        visible: state
      });
    });

    this._service.setVisibleControls(visibilities);
  }

  /**
   * @description
   * Unsubsribes all subscriptions, resets validation service.
   */
  ngOnDestroy(): void {
    this.handlers.forEach(subscr => subscr.unsubscribe());
    this.domHandlers.forEach(fn => fn());

    this.invalidControlsSubs.unsubscribe();
    this.errorChangesSubs.unsubscribe();
    this.reInitSubs.unsubscribe();

    if (this.formGroup) {
      this._service.reset();
    }
  }

  /**
   * @description
   * Handles serverError property changes, passes changed value to the
   * validation service.
   * @param changes - the changed properties
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.serverErrors) {
      this._service.setServerErrors(changes.serverErrors.currentValue);
    }
    if (changes.formControlPath
      && changes.formControlPath.currentValue !== changes.formControlPath.previousValue
      && !changes.formControlPath.firstChange) {
      this.reInitDirective();
    }
    if (changes.formGroup
      && changes.formGroup.currentValue !== changes.formGroup.previousValue
      && !changes.formGroup.firstChange) {
      this.reInitDirective();
    }
  }

  /**
   * @description
   * Re-Initializes directive subscriptions
   */
  reInitDirective(): void {
    this.clearInvalidStates();
    this.ngOnDestroy();
    this.ngOnInit();
    this.ngAfterViewInit();
  }

  // -- event handlers --------------------------------------------------------

  /**
   * @description
   * When mouse click occured somewhere on the form, the validation service
   * should be managed to re-determine visible error control.
   */
  onClick(): void {
    if (this._service) {
      this._service.updateValidationState();
    }
  }

  /**
   * @description
   * Validation service invalidControlsChanges event handler. Event occurs when
   * validation service detects form controls state modified.
   * Updates invalid controls view.
   */
  onInvalidControlsChanges(): void {
    const controls: string[] = this._service.controls
      .filter(i => i.error)
      .map(i => i.name);

    // set invalid state
    controls.forEach(name => {
      if (!this.invalidControls.includes(name)) {
        this.invalidControls.push(name);
        this.setInvalidState(name, true);
      }
    });

    // remove invalid state
    this.invalidControls.forEach(name => {
      if (!controls.includes(name)) {
        const pos: number = this.invalidControls.findIndex(c => c === name);
        if (pos > -1) {
          this.invalidControls.splice(pos, 1);
        }
        this.setInvalidState(name, false);
      }
    });
  }

  /**
   * @description
   * Resets invalid state of current form controls
   */
  clearInvalidStates(): void {
    const controls: string[] = this._service.controls
      .map(i => i.name);
    controls.forEach(name => {
      this.setInvalidState(name, false);
    });
  }

  /**
   * @description
   * Validation service errorVisibilityChanges event handler. Event occurs when
   * validation service detects visible error control changed.
   * Shows or hides validation error message.
   */
  onErrorVisibilityChanges(): void {
    const controlName: string = this._service.visibleErrorControl;
    if (controlName) {
      if (controlName !== this.errorControl) {
        if (this.errorControl) {
          this.setErrorVisibile(this.errorControl, false);
        }
        this.errorControl = controlName;
        this.setErrorVisibile(this.errorControl, true);
      }
    } else {
      if (this.errorControl) {
        this.setErrorVisibile(this.errorControl, false);
        this.errorControl = null;
      }
    }
  }

  /**
   * @description
   * Validation service scrollToFirstError event handler. Scrolls form's
   * first error element to visible area.
   * @param controlName - first error's control name
   */
  onScrollToFirstError(controlName: string): void {
    const element = this.findDOMElement(controlName);
    if (element) {
      // const componentTop: number = element.offsetTop;
      // const container = this.getScrollContainer();
      // container.scrollTop = componentTop;
      element.scrollIntoView({ block: 'center', inline: 'end', behavior: 'auto' });
    }
  }

  // -- helpers ---------------------------------------------------------------

  /**
   * @description
   * Iterates through EtlInput<> and EtlSelect<> component lists and calls
   * callback function for each component.
   * @param callback - function to be called
   */
  iterateComponents(callback: (component: EtlBaseFormControlComponent) => void): void {
    if (this.inputComponents.length) {
      this.inputComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.textareaComponents.length) {
      this.textareaComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.selectComponents.length) {
      this.selectComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.selectNewComponents.length) {
      this.selectNewComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.checkboxComponents.length) {
      this.checkboxComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.scheduleDateComponents.length) {
      this.scheduleDateComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.scheduleTimeComponents.length) {
      this.scheduleTimeComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.mediaFileSelectorComponents.length) {
      this.mediaFileSelectorComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.autocompleteComponents.length) {
      this.autocompleteComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.docUploaderComponents.length) {
      this.docUploaderComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.datepickerComponents.length) {
      this.datepickerComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
    if (this.dadataComponents.length) {
      this.dadataComponents.forEach(component => {
        if (component.formControlName) {
          callback(component);
        }
      });
    }
  }

  /**
   * @description
   * Subscribes validation service on form component's focus, blur, mouseenter,
   * mouseleave events.
   * @param component - form component
   */
  subscribeComponentEvents(component: EtlBaseFormControlComponent): void {
    const formControlName = this.getFormControlName(component.formControlName);

    let subscr = component.focus.subscribe(
      () => this._service.onControlFocus(formControlName));
    this.handlers.push(subscr);

    subscr = component.keyup.subscribe(
      () => this._service.onControlFocus(formControlName));
    this.handlers.push(subscr);

    subscr = component.blur.subscribe(
      () => this._service.onControlBlur(formControlName));
    this.handlers.push(subscr);

    subscr = component.mouseenter.subscribe(
      () => this._service.onControlMouseOver(formControlName));
    this.handlers.push(subscr);

    subscr = component.mouseleave.subscribe(
      () => this._service.onControlMouseOut(formControlName));
    this.handlers.push(subscr);
  }

  /**
   * @description
   * Modifies DOM element, related to the specified control, to reflect actual
   * validation state.
   * @param controlName - form control name
   * @param state - form control's invalid state
   */
  private setInvalidState(controlName: string, state: boolean): void {
    const control: any = this.findDOMElement(controlName);
    if (control) {
      let borderEl = control.querySelector('.mat-ctrl-active');
      if (!borderEl) {
        borderEl = control;
      }

      let labelEl: any = control.querySelector('.mat-label-active');
      if (!labelEl) {
        labelEl = control.querySelector('mat-label,.mat-checkbox-label,.datepicker_label,.select-label');
      }

      const placeholderEl: any = control.querySelector('.mat-form-field-empty');
      const arrowEl: any = control.querySelector('.mat-select-arrow');
      const textEl: any = control.querySelector('.mat-select-value-text');

      if (state) {
        if (borderEl) { this._renderer.addClass(borderEl, 'etl-invalid-control'); }
        if (labelEl) { this._renderer.addClass(labelEl, 'etl-invalid-label'); }
        if (placeholderEl) { this._renderer.addClass(placeholderEl, 'etl-invalid-label'); }
        if (arrowEl) { this._renderer.addClass(arrowEl, 'etl-invalid-label'); }
        if (textEl) { this._renderer.addClass(textEl, 'etl-invalid-label'); }
      } else {
        if (borderEl) { this._renderer.removeClass(borderEl, 'etl-invalid-control'); }
        if (labelEl) { this._renderer.removeClass(labelEl, 'etl-invalid-label'); }
        if (placeholderEl) { this._renderer.removeClass(placeholderEl, 'etl-invalid-label'); }
        if (arrowEl) { this._renderer.removeClass(arrowEl, 'etl-invalid-label'); }
        if (textEl) { this._renderer.removeClass(textEl, 'etl-invalid-label'); }
      }
    }
  }

  /**
   * @description
   * Modifies DOM element, related to the specified control, to show or hide
   * validation error messsage.
   * @param controlName - form control name
   * @param visible - error message visibility flag
   */
  private setErrorVisibile(controlName: string, visible: boolean): void {
    const control = this.findDOMElement(controlName);
    if (control) {
      let displayValue = 'block';

      let containerEl = control.querySelector('.mat-ctrl-active .etl-control-error');
      if (!containerEl) {
        containerEl = control.querySelector('.etl-control-error');
      }

      if (!containerEl) {
        containerEl = control.querySelector('.etl-control-error-float');
        displayValue = 'inline-flex';
      }

      if (containerEl) {
        if (visible) {
          const errorMessage = this._service.getErrorMessage(controlName);
          this._renderer.setProperty(containerEl, 'innerHTML', errorMessage);
          this._renderer.setStyle(containerEl, 'display', displayValue);
        } else {
          this._renderer.setStyle(containerEl, 'display', 'none');
        }
      }
    }
  }

  /**
   * @description
   * Returns DOM element related to the specified form control.
   * @param controlName - form control name
   */
  private findDOMElement(controlName: string): any {
    let control: any = null;
    if (this._element.nativeElement.nodeName !== '#comment') {
      this._element.nativeElement
        .querySelectorAll(ETL_CONTROLS_SELECTOR.join(','))
        .forEach(node => {
          const name: string = this.getDOMElementControlName(node);
          if (controlName === name) {
            return control = node;
          }
        });
    }
    return control;
  }

  /**
   * @description
   * Returns DOM element form control name.
   * @param node - DOM element
   */
  private getDOMElementControlName(node: any): string {
    let controlName: string;
    Array.from(node.attributes).forEach((attr: any) => {
      if (attr.nodeName.toLowerCase() === 'etl-control-name') {
        controlName = this.getFormControlName(attr.nodeValue);
      }
    });
    return controlName;
  }

  /**
   * @description
   * Builds full path of specified form control name.
   * @param controlName - form control name
   */
  private getFormControlName(controlName: string): string {
    const path = [...this.formControlPath, controlName];
    return path.join('.');
  }

  /**
 * @description
 * Returns form control by control's path and name.
 * @param controlName - form control name
 */
  findFormControl(controlName: string): FormControl {
    if (this.formControlPath.length === 0) {
      return <FormControl>this._service.form.controls[controlName];
    }

    let base: any = this._service.form;
    this.formControlPath.forEach(path => {
      base = Number.isInteger(path) ? base.at(path) : base.controls[path];
    });
    const control = <FormControl>base.controls[controlName];

    return control;
  }

  getScrollContainer(): any {
    let container;
    for (let i = 0; i < FORM_CONTAINER_SELECTOR.length; ++i) {
      container = document.querySelector(FORM_CONTAINER_SELECTOR[i]);
      if (container) {
        break;
      }
    }
    return container;
  }
}
