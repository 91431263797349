import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { validateFormControls } from '@helpers/utils/form.utils';
import { FormSnapshotService } from '@services/forms/form-snapshot.service';
import { ValidationMessageService } from '@services/validation/validation-message.service';
import { BaseService } from '@models/common/base-service.interface';
import { ValidationFormName } from '@shared/constant/form-validation-messages';
import { ValidationService } from '@services/validation/validation.service';
import { BaseModel } from '@models/local/base.model';
import { CustomValidationMessage } from '@models/local/validation/custom-validation-message';
import { ModalService } from '@services/modal/modal.service';
import { METHOD_MUST_BE_OVERRIDDEN } from '@shared/constant/app-notification.constants';
import { filter, map, catchError, tap } from 'rxjs/operators';
import { ErrorResponse } from '@models/local/responses/error-response';
import { alive } from '@helpers/utils/componentDestroyed';
import { GuideService } from '@services/guide/guide.service';
import { GuideEventFactory } from '@models/common/guide/guide.interfaces';


@Component({
  selector: 'app-etl-base-form',
  template: ''
})
export class EtlBaseFormComponent<TModel extends BaseModel, TService extends BaseService<TModel>>
  implements OnInit, OnDestroy {

  form: FormGroup;
  model: TModel;
  service: TService;
  serverErrors: any;

  protected formKey: ValidationFormName;

  protected snapshotService: FormSnapshotService;
  protected validationMessageService: ValidationMessageService;
  protected validation: ValidationService;
  protected dialog: ModalService;
  protected guide: GuideService;

  protected modelLoaded$: BehaviorSubject<TModel> = new BehaviorSubject(null);
  protected subscriptions: Subscription[] = [];

  // -- properties ------------------------------------------------------------

  get validationMessages(): CustomValidationMessage[] {
    return this.validationMessageService.getByFormKey(this.formKey);
  }

  get editMode(): boolean {
    return this.model && this.model.id && <number>this.model.id > 0;
  }

  get modelLoaded(): boolean {
    return !!this.modelLoaded$.value;
  }

  // -- component lifecycle methods -------------------------------------------

  constructor(
    protected injector: Injector
  ) {
    this.snapshotService = this.injector.get(FormSnapshotService);
    this.validationMessageService = this.injector.get(ValidationMessageService);
    this.validation = this.injector.get(ValidationService);
    this.dialog = this.injector.get(ModalService);
    this.guide = this.injector.get(GuideService);
  }

  ngOnInit(): void {
    this.createForm();
    this.serverErrors = null;
    this.snapshotService.init(this.form);
  }

  ngOnDestroy(): void {
    this.snapshotService.reset();
    this.serverErrors = null;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // -- event handlers --------------------------------------------------------

  onSave(): void {
    this.updateFormValidity();

    if (this.checkFormValid()) {
      this
        .saveModel()
        .subscribe(
          (res) => {
            if (!res || !res.errors) {
              this.saveFormState();
              this.mapFormToModel();
              this.afterSave();
              this.guide.notify(GuideEventFactory.setParam({ key: 'model', value: res }));
            } else {
              this.serverErrors = res.errors || res.internal.errors;
              this.handleError(res);
              console.error('Errors in success response.', res);
            }
          },
          (err: ErrorResponse) => {
            if (err && err.hasErrors) {
              this.serverErrors = err.errors;
            }
            this.handleError(err);
          });
    }
  }

  onClose(): void {
    if (!this.validation.checkFormChanged()) {
      this.afterClose();
      return;
    }
    this.validation
      .canDeactivate()
      .pipe(
        filter(res => res)
      )
      .subscribe(
        () => this.afterClose()
      );
  }

  // -- base methods ----------------------------------------------------------

  protected createForm(): void {
    throw Error(`createForm() ${METHOD_MUST_BE_OVERRIDDEN}`);
  }

  protected initModel(): void {
    const route = this.injector.get(ActivatedRoute);
    const id = route.snapshot.params.id;

    this
      .getModel(id)
      .pipe(
        tap(data => {
          this.model = data;
          this.mapModelToForm(this.model);
          this.afterInitModel();
          this.saveFormState();
          this.notifyModelLoaded();
        }),
        catchError((err) => {
          this.handleError(err);
          throw err;
        })
      )
      .subscribe();
  }

  protected notifyModelLoaded(): void {
    setTimeout(() => this.modelLoaded$.next(this.model), 500);
  }

  protected getModel(id: number): Observable<TModel> {
    if (id && <number>id > 0) {
      return this.service.getItem(id);
    } else {
      return this.service
        .getReferences()
        .pipe(
          map(() => this.getEmptyModel())
        );
    }
  }

  protected getEmptyModel(): TModel {
    throw Error(`getEmptyModel() ${METHOD_MUST_BE_OVERRIDDEN}`);
  }

  protected saveModel(): Observable<any> {
    return this.service.saveItem(this.form.value);
  }

  protected mapModelToForm(model: TModel): void {
    this.form.patchValue(model);
    this.saveFormState();
  }

  protected mapFormToModel(): void {
    Object.keys(this.form.value).forEach(key => {
      if (this.model.hasOwnProperty(key)) {
        this.model[key] = this.form.value[key];
      }
    });
  }

  protected saveFormState(): void {
    this.snapshotService.save();
  }

  protected checkFormChanged(): boolean {
    return this.snapshotService.check();
  }

  protected checkFormValid(): boolean {
    return this.form.valid;
  }

  /**
   * @description
   * Handles server side errors. Does nothing by default, but may be overridden
   * in derived class.
   * @param error - error object
   */
  protected handleError(error: any): void { }

  protected afterInitModel(): void { }

  protected afterSave(): void { }

  protected afterClose(): void { }

  protected updateFormValidity(): void {
    this.form.updateValueAndValidity();
    validateFormControls(this.form);
    this.validation.updateValidationState();

    if (!this.form.valid) {
      this.validation.emitScrollToFirstError();
    }
  }
}
