import { Injectable } from '@angular/core';
import { Observable, merge, fromEvent, Subject, interval, Subscription } from 'rxjs';

const IDLE_SECONDS = 500;
@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private _handleEvents$: Observable<any>;
  private _handleEventsSubscription: Subscription;
  private _timer$ = interval(1000);
  private _timerSubscription: Subscription;
  private _generateEvenAfterCountSecond = IDLE_SECONDS;
  public idleSecondsCount = 0;
  public idleEvent$: Subject<number> = new Subject();

  constructor() {
    this._handleEvents$ = merge(
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(window, 'mousemove')
    );
  }

  isIdle(): boolean {
    return this.idleSecondsCount >= this._generateEvenAfterCountSecond;
  }

  start(forceAfterSeconds?: number): void {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    this._generateEvenAfterCountSecond = forceAfterSeconds || IDLE_SECONDS;
    this._timerSubscription = this._timer$.subscribe(() => {
      this.idleSecondsCount++;
      if (this.isIdle()) {
        this.idleEvent$.next(this.idleSecondsCount);
      }
    });

    this._handleEventsSubscription = this._handleEvents$.subscribe(() => {
      this.idleSecondsCount = 0;
    });
  }

  end(): void {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    if (this._handleEventsSubscription) {
      this._handleEventsSubscription.unsubscribe();
    }
  }


}
