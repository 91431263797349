import { Component, OnInit, forwardRef, Input, ViewChild, Renderer2, ElementRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatInput } from '@angular/material/input';

import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { killEvent } from '@helpers/utils/event.utils';


@Component({
  selector: 'etl-textarea',
  templateUrl: './etl-textarea.component.html',
  styleUrls: ['./etl-textarea.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlTextareaComponent),
      multi: true
    }
  ],
})
export class EtlTextareaComponent
  extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor {

  floatLabel: 'never' | 'always' | 'auto' = 'never';
  formFieldAppearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'legacy';

  @Input() rows: number = 1;
  @Input() maxlength: number;

  @Input() set appearance(value: 'general' | 'sidebar') {
    if (value === 'sidebar') {
      this.floatLabel = 'always';
      this.formFieldAppearance = 'outline';
    } else {
      this.floatLabel = 'never';
      this.formFieldAppearance = 'legacy';
    }
  }

  @ViewChild(MatInput, { static: false }) input: MatInput;

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector
    ) {
      super(injector);
    }

  ngOnInit(): void { }

  // -- event handlers --------------------------------------------------------

  setFocus(): void {
    this.focused = true;
    this.input.focus();
  }

  reset(event: Event): void {
    killEvent(event);
    this.value = '';
    this.onChange(this.value);
  }

  onInput() {
    this.onChange(this.value);
  }
}
