import { Mapper } from '@mappers/mapper';
import { SupportStatus } from '@models/local/support/support-status.enum';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';

// tslint:disable-next-line:naming-convention
export let SupportStatusMapper: Mapper<SupportStatus, number> = {
  from(obj: number): SupportStatus {
    switch (obj) {
      case 1:
        return SupportStatus.NEW;
      case 2:
        return SupportStatus.IN_PROGRESS;
      case 3:
        return SupportStatus.COMPLETED;
      case 4:
        return SupportStatus.REOPEN;
      default:
        return SupportStatus.UNKNOWN;
    }
  },
  
  fromArray(arr: number[]): SupportStatus[] {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  },
  
  to(obj: SupportStatus): number {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
