import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  OnDestroy,
  Injector,
  HostBinding
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@services/translate/translate.service';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';

@Component({
  selector: 'app-etl-base-form-component',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlBaseFormControlComponent),
      multi: true
    }
  ],
})
export class EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor, OnDestroy {

  value: any;
  localformControlName: string;

  // focused: boolean = false;

  protected renderer: Renderer2;
  protected elementRef: ElementRef;
  protected translate: TranslateService;

  localPlaceholder: string;
  private _placeholder: string;

  @HostBinding('class.focused') focused: boolean = false;

  @Input() set placeholder(val: string) {
    this._placeholder = val;
    this.createPlaceholder(val);
  }
  get placeholder() {
    return this._placeholder;
  }

  @Input() set formControlName(val: string) {
    this.localformControlName = val;
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'etl-control-name',
      val
    );
  }
  get formControlName() {
    return this.localformControlName;
  }

  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() floatError: boolean;
  @Input() readonly: boolean = false;

  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() mouseenter: EventEmitter<any> = new EventEmitter();
  @Output() mouseleave: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() keyup: EventEmitter<any> = new EventEmitter();

  get element(): ElementRef {
    return this.elementRef;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector
  ) {
    this.renderer = injector.get(Renderer2);
    this.elementRef = injector.get(ElementRef);
    this.translate = injector.get(TranslateService);
    this.translate.onLangChange.takeUntil(componentDestroyed(this)).subscribe(() => {
      this.createPlaceholder(this._placeholder);
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {

  }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(obj: any): void {
    this.value = obj;
  }

  onChange: any = () => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched: any = () => { };
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // -- event handlers --------------------------------------------------------

  onKeyup(e) {
    this.keyup.emit(e);
  }

  onBlur(e) {
    this.focused = false;
    this.onTouched();
    this.blur.emit(e);
    this.createPlaceholder(this.placeholder);
  }

  onFocus(e) {
    this.focused = true;
    this.focus.emit(e);
    this.localPlaceholder = '';
  }

  onMouseEnter(e) {
    this.mouseenter.emit(e);
  }

  onMouseLeave(e) {
    this.mouseleave.emit(e);
  }

  // -- general methods -------------------------------------------------------

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  createPlaceholder(value: string): void {
    let displayValue = '';
    if (value) {
      displayValue = this.translate.instant(value);
    }
    this.localPlaceholder = `${displayValue}`;
  }

  setFocus() {}
}
