import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseModel } from '@models/local/base.model';
import { BaseService } from '@models/common/base-service.interface';
import { ModalService } from '@services/modal/modal.service';
import { NotificationService } from '@services/notification/notification.service';
import { createPageInfo } from '@helpers/utils/pageInfo.utils';
import { PageInfoModel } from '@models/common/table-models/page-info.model';
import { ErrorResponse } from '@models/local/responses/error-response';
import { killEvent } from '@helpers/utils/event.utils';
import { DLG_RESULT_OK } from '@shared/constant/dialog.constants';
import { SelectableType } from '@elements/etl-data-table/selectable-type.enum';
import { TableSelectionEvent } from '@models/local/events/table-selection.event';
import { EtlDataTableComponent } from '@elements/etl-data-table/etl-data-table.component';
import { FilterInfoOptionModel } from '@models/common/table-models/filter-info.model';
import { ModalButtonStyle } from '@models/common/modal-data/modal-data.model';
import { GuideService } from '@services/guide/guide.service';


@Component({
  selector: 'app-etl-base-table-page',
  template: ''
})
export class EtlBaseTablePageComponent<TModel extends BaseModel, TService extends BaseService<TModel>>
  implements OnInit, OnDestroy {
  // tslint:disable-next-line:naming-convention
  SelectableType: typeof SelectableType = SelectableType;

  data: PageInfoModel<TModel>;
  selected: TModel;
  service: TService;

  filter: any;
  filterOptions: FilterInfoOptionModel[] = [];

  protected dialog: ModalService;
  protected notification: NotificationService;
  protected guide: GuideService;

  protected dataLoaded$: BehaviorSubject<PageInfoModel<TModel>> = new BehaviorSubject(null);

  protected deleteConfirmationMessage: string;
  protected deleteSuccessMessage: string;
  protected deleteMessageParams: any;

  @ViewChild(EtlDataTableComponent, { static: false }) table: EtlDataTableComponent<TModel>;

  // -- properties ------------------------------------------------------------

  get hasData(): boolean {
    return this.dataLoaded$.value
      && this.data.itemsCount > 0;
  }

  get hasNoData(): boolean {
    return this.dataLoaded$.value
      && this.data.itemsCount === 0 && !this.filter;
  }

  get isFirstLoading(): boolean {
    return (this.table && typeof this.table.firstLoading === 'boolean') ? this.table.firstLoading : true;
  }

  get emptyResult(): boolean {
    return this.dataLoaded$.value
      && this.data.itemsCount === 0 && this.filter;
  }

  get sidebarVisible(): boolean {
    return !!this.table && this.table.selectionCount > 0;
  }

  get hasSelection(): boolean {
    return !!this.table && this.table.selectionCount > 0;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    protected injector: Injector
  ) {
    this.dialog = this.injector.get(ModalService);
    this.notification = this.injector.get(NotificationService);
    this.guide = this.injector.get(GuideService);
  }

  ngOnInit(): void {
    if (this.table) {
      this.table.firstLoading = false;
    }
    this.onRefreshData();
  }

  ngOnDestroy(): void { }

  // -- "after" methods -------------------------------------------------------

  afterUpdate(): void { }
  afterDelete(): void { }

  handleError(error: ErrorResponse): void {
    this.notification.error(error.internal ? error.internal.message : error.message);
  }

  // -- event handlers --------------------------------------------------------

  onRefreshData(): void {
    if (!this.data) {
      this.initDataModel();
    }
    this.loadData();
  }

  onDelete(model: TModel, event?: MouseEvent): void {
    killEvent(event);
    this.showDeleteDialog()
    .pipe(
      filter(res => res === DLG_RESULT_OK),
      mergeMap(() => this.service.deleteItem(model.id))
    )
    .subscribe(
      () => {
        this.checkPageChangeAfterDeletion();
        this.loadData();
        this.notification.success(this.deleteSuccessMessage, this.deleteMessageParams);
        this.onSidebarClose();
        this.afterDelete();
      },
      (error: ErrorResponse) => {
        this.handleError(error);
      }
  );
  }

  onSelectionChange(event: TableSelectionEvent<TModel>): void {
    this.selected = event.value;
  }

  onSidebarClose(): void {
    if (this.table) {
      this.table.clearSelection();
    }
    this.selected = null;
  }

  onFilterChange(value: any): void {
    this.filter = value;
    this.data.page = 1;
    this.data.filter = value;

    if (this.table && this.table.selectable === SelectableType.MULTIPLE) {
      this.table.clearSelection();
    }

    if (this.table && this.table.paginator) {
      this.table.paginator.firstPage();
    }

    this.setSorting(this.filter);

    this.loadData();
  }

  // -- general methods -------------------------------------------------------

  showDeleteDialog(): Observable<any> {
    return this.dialog
      .showConfirm({
        okBtn: { title: 'Delete', result: DLG_RESULT_OK, style: ModalButtonStyle.WARNING },
        body: this.deleteConfirmationMessage,
        bodyParams: this.deleteMessageParams
      });
  }

  updateSelection(): void {
    if (this.selected && this.table.selectionCount > 0) {
      setTimeout(() => {
        this.table.setSelected([this.selected]);
      }, 0);
    }
  }

  setSelection(): void {
    if (this.selected && this.table) {
      setTimeout(() => {
        this.table.setSelected([this.selected]);
      }, 0);
    }
  }

  checkPageChangeAfterDeletion(deletedCount: number = 1): void {
    if (this.data.items.length <= deletedCount && this.data.page > 1) {
      this.table.paginator.previousPage();
    }
  }

  // -- data methods ----------------------------------------------------------

  updateData(data: PageInfoModel<TModel>): void {
    this.data = _.merge(this.data, { page: data. page, limit: data.limit, sort: data.sort });
    this.data.page = data.page;
    this.data.limit = data.limit;
    this.data.sort = data.sort;
    this.loadData();
  }

  protected loadData(): void {
    this.dataLoaded$.next(null);
    this.service
      .getItems(this.data)
      .subscribe(
        (res: PageInfoModel<TModel>) => {
          this.data = _.merge(res, { filter: this.data.filter, limit: this.data.limit, sort: this.data.sort });

          if (this.data.filterInfo && this.data.filterInfo.options) {
            this.filterOptions = this.data.filterInfo.options;
          }
          this.updateSelection();
          this.afterUpdate();
          if (this.table) {
            this.table.firstLoading = false;
          }

          this.dataLoaded$.next(this.data);
        },
        error => {
          if (error.status === 401) {
            return;
          }
          this.notification.error(error.internal ? error.internal.message : error.message);
          });
  }

  protected initDataModel(): void {
    this.data = createPageInfo<TModel>();
  }

  protected setSorting(filterValue: any): void { }
}
