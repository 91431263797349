import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { CdrModel } from '@models/local/cdr/cdr.model';
import { MediaState } from '@models/common/media-player/media-state.enum';
import { MediaPlayerConsumerComponent } from '@models/common/media-player/media-player-consumer.component';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { MediaPlayerService } from '@services/media-player/media-player.service';
import { MediaSelectionMode } from '@models/common/media-player/media-selection-mode.enum';
import { PlayMediaEvent } from '@models/common/media-player/play-media.event';


@Component({
  selector: 'page-media-player',
  templateUrl: './page-media-player.component.html',
  styleUrls: [
    'page-media-player.component.sass',
    'page-media-player.dark-theme.component.sass',
    'page-media-player.white-theme.component.sass'
  ]
})
export class PageMediaPlayerComponent implements OnInit, OnDestroy, OnChanges, MediaPlayerConsumerComponent {
  // tslint:disable-next-line:naming-convention
  MediaState: typeof MediaState = MediaState;

  uid: string;
  selectedMedia: MediaFileModel;

  mediaState: MediaState;
  mediaTime: number = 0;

  private _mediaProgress: number = 0;
  private _volume: number = 0.5;

  @Input() items: CdrModel[];
  @Input() selectedIndex: number;

  @Output() mediaChanged: EventEmitter<CdrModel> = new EventEmitter();
  @Output() toggleMedia: EventEmitter<PlayMediaEvent> = new EventEmitter();
  @Output() playTimeChange: EventEmitter<number> = new EventEmitter();
  @Output() download: EventEmitter<CdrModel> = new EventEmitter();

  // -- properties ------------------------------------------------------------

  get model(): CdrModel {
    return this.items && this.items.length
      ? this.items[this.selectedIndex]
      : null;
  }

  get canPrevious(): boolean {
    return this.selectedIndex > 0;
  }

  get canNext(): boolean {
    return this.items && this.selectedIndex !== this.items.length - 1;
  }

  get progress(): string {
    return this._mediaProgress + '%';
  }

  get volume(): string {
    return this._volume * 100 + '%';
  }

  get muted(): boolean {
    return this._volume === 0;
  }

  get pauseVisible(): boolean {
    return this.mediaState === MediaState.PLAYING;
  }

  get playVisible(): boolean {
    return this.mediaState === MediaState.STOPPED || this.mediaState === MediaState.LOADING;
  }

  get playDisabled(): boolean {
    return this.mediaState === MediaState.LOADING || !this.model
      || !this.model.media || !this.model.media.available;
  }

  get canDownload(): boolean {
    return !!this.model.media;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _mediaService: MediaPlayerService
  ) { }

  ngOnInit(): void {
    this._mediaService.selectionMode = MediaSelectionMode.STOP_AND_RESET;
    this._mediaService.register(this);
    this.mediaState = MediaState.STOPPED;
  }

  ngOnDestroy(): void {
    this._mediaService.remove(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (((changes.items && changes.items.currentValue && changes.items.currentValue.length)
      || (changes.selectedIndex && changes.selectedIndex.currentValue != null)) && this.mediaState !== MediaState.PLAYING) {
      this.selectMedia();
    }
  }

  // -- event handlers --------------------------------------------------------

  onPrevious(): void {
    if (this.canPrevious) {
      this.selectedIndex--;
      this.selectMedia();
      this.mediaChanged.emit(this.model);
    }
  }

  onNext(): void {
    if (this.canNext) {
      this.selectedIndex++;
      this.selectMedia();
      this.mediaChanged.emit(this.model);
    }
  }

  onTogglePlay(): void {
    if (this.selectedMedia) {
      this.toggleMedia.emit({
        componentUid: this.uid,
        mediaFile: this.selectedMedia
      });
    }
  }

  onProgressClick(event: any): void {
    const path = event.path || (event.composedPath && event.composedPath());
    const container = path.find(p => p.className === 'player__audio-progress');
    if (container) {
      const width = container.clientWidth;

      const pos = event.offsetX;
      this.mediaTime = pos / width * this.model.media.duration;
      this._mediaService.playerSetTime(this.mediaTime);
      this.updateProgress();
    }
  }

  onVolumeClick(event: any): void {
    const path = event.path || (event.composedPath && event.composedPath());
    const container = path.find(p => p.className === 'player__volume-level');
    if (container) {
      const width = container.clientWidth;
      const pos = event.offsetX;

      this._volume = pos / width;
      this._mediaService.playerSetVolume(this._volume);
    }
  }

  toggleMute(): void {
    if (this.muted) {
      this._volume = 0.5;
    } else {
      this._volume = 0;
    }
    this._mediaService.playerSetVolume(this._volume);
  }

  onDownload(): void {
    this.download.emit(this.model);
  }

  // -- MediaPlayerConsumerComponent ------------------------------------------

  selectMedia(): void {
    if (this.mediaState === MediaState.PLAYING) {
      this._mediaService.playerStop();
    }
    setTimeout(() => {
      this.mediaTime = 0;
      this.updateProgress();
      if (this.model && this.model.media) {
        this.selectedMedia = this.model.media;
      }
    }, 0);
  }

  setState(state: MediaState): void {
    this.mediaState = state;
  }

  setTime?(time: number): void {
    this.mediaTime = time;
    this.updateProgress();
  }

  markMediaAsUnavailable?(mediaId: number): void {
    this.model.media.available = false;
    this.mediaState = MediaState.STOPPED;
  }

  updateProgress(): void {
    if (!this.model || !this.model.media) {
      this._mediaProgress = 0;
      return;
    }
    const playerTime = Math.min(this.mediaTime, this.model.media.duration);
    this._mediaProgress = Math.floor(playerTime / this.model.media.duration * 100);
  }

  getDuration(element: CdrModel): number {
    return (!element || !element.media || isNaN(element.duration)) ? 0 : element.duration;
  }
}
