import { Injectable, ViewContainerRef, ComponentFactoryResolver, Type, ComponentRef } from '@angular/core';
import { Subscription, Observable, of, Subject } from 'rxjs';
import { VgStates } from '@videogular/ngx-videogular/core';
import { tap, catchError, mergeMap, filter } from 'rxjs/operators';

import { MediaPlayerComponent } from '@models/common/media-player/media-player.component';
import { MediaPlayerConsumerComponent } from '@models/common/media-player/media-player-consumer.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { PlayMediaEvent } from '@models/common/media-player/play-media.event';
import { MediaState } from '@models/common/media-player/media-state.enum';
import { NotificationService } from '@services/notification/notification.service';
import { MediaSelectionMode } from '@models/common/media-player/media-selection-mode.enum';
import {AuthenticationService} from '@services/user/authentication-service.service';


@Injectable({
  providedIn: 'root'
})
export class MediaPlayerService {

  selectionMode: MediaSelectionMode = MediaSelectionMode.PLAY_AND_KEEP;

  private _container: ViewContainerRef;
  private _player: MediaPlayerComponent;

  private _components: MediaPlayerConsumerComponent[] = [];
  private _subscriptions: { [key: string]: Subscription } = {};
  private _mediaStreams: { [key: number]: MediaFileModel } = {};
  private _consumerPlayTimes: { [key: string]: number } = {};

  private _currentUid: string;
  private _currentMedia: MediaFileModel;

  private _playerStateSubs: Subscription;
  private _playerTimeSubs: Subscription;

  private _playerStopped: Subject<void> = new Subject();

  // -- properties ------------------------------------------------------------

  get activeConsumer(): MediaPlayerConsumerComponent {
    return this._components.find(c => c.uid === this._currentUid);
  }

  get mediaStartTime(): number {
    return this.selectionMode === MediaSelectionMode.STOP_AND_RESET
      ? 0
      : this._mediaStreams[this._currentMedia.id].currentTime;
  }

  get currentMediaState(): MediaState {
    return this.activeConsumer && this.activeConsumer.mediaState;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _resolver: ComponentFactoryResolver,
    private _http: HttpClient,
    private _notification: NotificationService,
    private _authService: AuthenticationService
  ) { }

  get accessToken(): string {
    return this._authService.token.accessTokenInline;
  }

  // -- media player ----------------------------------------------------------

  createComponent(type: Type<MediaPlayerComponent>, container: ViewContainerRef): void {
    this._container = container;
    this._container.clear();

    const factory = this._resolver
      .resolveComponentFactory(type);
    const componentRef: ComponentRef<MediaPlayerComponent> = this._container
      .createComponent(factory);

    this._player = componentRef.instance;

    if (this._playerStateSubs) {
      this._playerStateSubs.unsubscribe();
    }
    this._playerStateSubs = this._player.stateChange
      .subscribe(event => {
        this.onPlayerStateChange(event);
      });

    if (this._playerTimeSubs) {
      this._playerTimeSubs.unsubscribe();
    }
    this._playerTimeSubs = this._player.timeUpdate
      .subscribe(time => {
        if (this._currentMedia
          && this._mediaStreams[this._currentMedia.id]) {
          this._mediaStreams[this._currentMedia.id]
            .currentTime = time;
        }
        this.onPlayerTimeUpdate(time);
      });
  }

  // -- media player consumers ------------------------------------------------

  register(component: MediaPlayerConsumerComponent): void {
    if (!this._components.some(c => c.uid === component.uid)) {
      component.uid = this.getUID();
      this._components.push(component);
    }
    this._subscriptions[component.uid] = component.toggleMedia
      .subscribe((event: PlayMediaEvent) => {
        this.onToggleMedia(event);
      });
    if (component.playTimeChange) {
      this._subscriptions[component.uid] = component.playTimeChange
      .subscribe((time: number) => {
        this.playerSetTime(time);
      });
    }
  }

  remove(component: MediaPlayerConsumerComponent): void {
    if (component.uid === this._currentUid) {
      this.playerStop();
    }

    this._subscriptions[component.uid].unsubscribe();
    delete this._subscriptions[component.uid];

    this._components = this._components
      .filter(c => c.uid !== component.uid);
  }

  // -- event handlers --------------------------------------------------------

  onToggleMedia(event: PlayMediaEvent): void {

    // -- MediaSelectionMode.PLAY_AND_KEEP

    // media && uid -> toggle
    // media && !uid -> start(:time)
    // !media && !uid -> load > start (:time)

    // -- MediaSelectionMode.STOP_AND_RESET

    // media && uid -> toggle
    // media && !uid -> start(0)
    // !media && uid -> load > start(0)
    // !media && !uid -> load > start(0)


    if (this._currentMedia && event.mediaFile.id === this._currentMedia.id) {
      if (event.componentUid === this._currentUid) {
        this.playerTogglePlay();
      } else {
        this.playerStop();
        this._playerStopped
          .pipe(
            tap(() => this.setCurrentMedia(event)),
            tap(() => {
              const playTime = this.selectionMode === MediaSelectionMode.PLAY_AND_KEEP
                ? this._consumerPlayTimes[this._currentUid]
                : 0;
              this.playerStart(playTime);
            })
          )
          .subscribe(() => { });
      }
    } else {
      this.playerStop();
      this._playerStopped
        .pipe(
          tap(() => this.setCurrentMedia(event)),
          mergeMap(() => this.loadMedia())
        )
        .subscribe(media => {
          if (media.available) {
            const playTime = this.selectionMode === MediaSelectionMode.PLAY_AND_KEEP
              ? this._consumerPlayTimes[this._currentUid]
              : 0;
            this.playerSetMedia(media.fileLink, playTime);
          } else {
            this.notifyMediaUnavailable(media);
          }
        });
    }
  }

  onPlayerStateChange(state: VgStates): void {
    const mediaState = this.fromPlayerState(state);
    if (this.activeConsumer) {
      this.activeConsumer.setState(mediaState);
    }
  }

  onPlayerTimeUpdate(time: number): void {
    if (this._currentUid) {
      this._consumerPlayTimes[this._currentUid] = time;
    }
    if (this.activeConsumer && this.activeConsumer.setTime) {
      this.activeConsumer.setTime(time);
    }
  }

  // -- general methods -------------------------------------------------------

  private setCurrentMedia(event: PlayMediaEvent): void {
    this._components.forEach(c => c.setState(MediaState.STOPPED));
    this._currentUid = event.componentUid;
    this._currentMedia = event.mediaFile;
    this._currentMedia.fileLink =
      `${environment.urlWithVersion}` +
      `/download/account-file/${this._currentMedia.md5}/inline` +
      `?accessToken=${this.accessToken}`;

    this.resetCurrentPlayTime();
  }

  private resetCurrentPlayTime(): void {
    if (!this._currentUid) { return; }
    if (this._consumerPlayTimes[this._currentUid] === undefined) {
      this._consumerPlayTimes[this._currentUid] = 0;
    }
    if (this.selectionMode === MediaSelectionMode.STOP_AND_RESET) {
      this._consumerPlayTimes[this._currentUid] = 0;
    }
  }

  private getUID(): string {
    return '__' + Math.random().toString(36).substr(2, 9) + '__';
  }

  private fromPlayerState(state: VgStates): MediaState {
    switch (state) {
      case VgStates.VG_PLAYING:
        return MediaState.PLAYING;
      case VgStates.VG_LOADING:
        return MediaState.LOADING;
      case VgStates.VG_PAUSED:
      case VgStates.VG_ENDED:
      default:
        return MediaState.STOPPED;
    }
  }

  private notifyMediaUnavailable(media: MediaFileModel): void {
    if (this.activeConsumer) {
      this.activeConsumer.setState(MediaState.UNAVAILABLE);
    }
    this._components.forEach(component => {
      if (component.markMediaAsUnavailable) {
        component.markMediaAsUnavailable(media.id);
      }
    });
  }

  playerSetMedia(mediaStream: string, time: number = 0): void {
    if (this._player) {
      this._player.setMedia(mediaStream, time);
    }
  }

  playerStart(playTime: number): void {
    if (this._player) {
      this._player.start(playTime);
    }
  }

  playerStop(): void {
    if (this._player) {
      this._player.stop();
      const subs = this._player.stateChange
        .pipe(
          filter(state => state !== VgStates.VG_PLAYING),
        )
        .subscribe(() => {
          subs.unsubscribe();
          this._playerStopped.next();
        });
    }
  }

  playerTogglePlay(): void {
    if (this._player) {
      this._player.toggle();
    }
  }

  playerSetTime(time: number): void {
    if (this._player) {
      this._player.setTime(time);
    }
  }

  playerSetVolume(volume: number): void {
    if (this._player) {
      this._player.setVolume(volume);
    }
  }

  // -- data methods ----------------------------------------------------------

  private loadMedia(): Observable<MediaFileModel> {
    if (!this._mediaStreams[this._currentMedia.id]) {

      this._mediaStreams[this._currentMedia.id] = {...this._currentMedia};

      return this.checkMediaStream(this._mediaStreams[this._currentMedia.id]);
    } else {
      return of(this._mediaStreams[this._currentMedia.id]);
    }
  }

  private checkMediaStream(media: MediaFileModel): Observable<MediaFileModel> {
    return this._http
      .get<any>(media.fileLink)
      .pipe(
        tap(_ => of(media)),
        catchError(error => {
          if (error && error.status === 404) {
            this._notification.error('invalidMediaFile', { file: media.fileName });
            media.available = false;
          }
          return of(media);
        })
      );
  }
}
