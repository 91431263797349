import { BaseModel } from '@models/local/base.model';
import { CallRuleActionModel } from './call-rule-action.model';
import { SipModel } from '@models/local/sip/sip.model';

export class CallRuleModel implements BaseModel {
    id: number;
    name: string;
    description: string;
    enabled: boolean;
    sipId: number;
    sip?: SipModel;
    ruleActions: CallRuleActionModel[];
  
    get status(): string {
      return this.enabled ? 'enabled' : 'disabled';
    }
  }
