import { Subject } from 'rxjs';

import { GuideEventType, GuideSrcElement } from './guide.enums';


export interface GuideAction {
  handler: string;
  params?: any[];
}

export interface GuideEvent {
  type: GuideEventType | string;
  srcElement: GuideSrcElement | string;
  handler?: string;
  param?: any;
}

export interface GuideEventParam {
  key: string;
  value: any;
}

export interface ElementPosition {
  left: number;
  top: number;
}

export interface ElementRect {
  left: number;
  top: number;
  width: number;
  height: number;
}

export interface GuideOptionsModel {
  hidePrev: boolean;
  hideNext: boolean;
  prevLabel: string;
  nextLabel: string;
  closeLabel: string;
  stepSwitchDelay: number;
}

export interface GuideStepModel {
  intro: string;            // Tooltip text
  position?: string;        // auto | top | bottom | left | right

  element?: string;         // Selector string for element to be highlighted on the page
  tableElement?: boolean;   // If true, target element selector will be taken from 'model' parameter. Mask: row-<num>
  arrowTo?: string;         // input | button | ... - when specified, points to DOM-child element which tip arrow should be bound to
  hint?: boolean;           // Scenario-related step number

  focus?: boolean;          // Flag to set highlighted element focused
  validate?: string;        // Form field name to be valid to allow next step transition

  events?: GuideEvent[];    // External event handlers array
  actions?: GuideAction[];  // Array of actions to be observed when step is active
  canMove?: boolean;        // Flag defines should prev/next labels enabled after start (default: true)

  stepSwitchDelay?: number; // Allows to override stepSwitchDelay value for the current step (acts after leave)
}

export interface IGuide {
  // properties
  options: any;
  active: boolean;

  current: GuideStepModel;
  index: number;
  prevIndex: number;

  position: ElementPosition;
  arrowPosition: ElementPosition;
  targetRect: ElementRect;

  events$: Subject<string>;
  params: any[];

  actionСount: number;
  hasPrev: boolean;
  hasNext: boolean;
  canPrev: boolean;
  canNext: boolean;

  start(): void;
  stop(): void;
  init(steps: GuideStepModel[], options: any): void;
  go(index: number): void;
  prev(): void;
  next(): void;
  updateStepDefaults(): void;

  notify(event: GuideEvent): void;
  hideTooltip(): void;
  showNextStep(): void;
  validateForm(name: string): void;

  redraw(): void;
}

export class GuideEventFactory {
  static pageLoad(srcElement: GuideSrcElement, param?: any): GuideEvent {
    return this.create(GuideEventType.PAGE_LOAD, srcElement, param);
  }

  static buttonClick(srcElement: GuideSrcElement, param?: any): GuideEvent {
    return this.create(GuideEventType.BUTTON_CLICK, srcElement, param);
  }

  static setParam(param?: any): GuideEvent {
    return this.create(GuideEventType.SAVE_DATA, null, param);
  }

  static create(type: GuideEventType, srcElement: GuideSrcElement, param?: any): GuideEvent {
    return {
      type: type,
      srcElement: srcElement,
      param: param
    };
  }
}
