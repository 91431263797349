import { Mapper } from '@mappers/mapper';
import { SipOuterModel } from '@models/local/sip/sip-outer.model';
import { SipOuterServerModel } from '@models/server/sip/sip-outer-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { SipInnerMapper } from './sip-inner.mapper';
import { SipStatus } from '@models/local/sip/sip-status.enum';
import { SipType } from '@models/local/sip/sip-type.enum';
import { PhoneFormatterPipe } from '@shared/pipes/phone-formatter.pipe';


// tslint:disable-next-line:naming-convention
export let SipOuterMapper: Mapper<SipOuterModel, SipOuterServerModel> = {

  from(obj: SipOuterServerModel): SipOuterModel {
    if (!obj) { return null; }

    const sipInners = SipInnerMapper.fromArray(obj.sipInners);
    const defaultInner = SipInnerMapper.from(obj.defaultInner);

    let statusName;
    switch (obj.status) {
      case 3:
        statusName = SipStatus.PROCESSED;
        break;
      case 1:
        statusName = SipStatus.ENABLED;
        break;
      case 0:
      default:
        statusName = SipStatus.DISABLED;
        break;
    }

    const formatter = new PhoneFormatterPipe();

    const numberTrunk = obj.number ? {
      host: obj.number.host,
      port: obj.number.port,
      login: obj.number.login,
      password: obj.externalSecret
    } : null;

    return {
      id: obj.id,
      blocked: statusName === SipStatus.PROCESSED,
      callRecord: obj.callRecord,
      defaultInner: defaultInner,
      defaultInnerNumber: defaultInner ? '#' + defaultInner.phoneNumber : null,
      deletable: !obj.safe,
      enabledIvr: obj.enabledIvr,
      enabledOuterRule: obj.enabledOuterRule,
      formattedPhone: formatter.transform(obj.phoneNumber),
      host: obj.host,
      port: obj.port,
      number: numberTrunk,
      isExternalNumber: obj.number ? obj.number.isExternalNumber : false,
      innerCount: obj.countInners ? obj.countInners : 0,
      ivrUse: !!obj.enabledIvr,
      rulesUse: !!obj.enabledOuterRule,
      login: obj.login,
      password: null,
      phoneNumber: obj.phoneNumber,
      providerId: obj.providerId,
      status: obj.status,
      statusName: statusName,
      type: obj && obj.providerId === 1
        ? SipType.INTERNAL
        : SipType.EXTERNAL,
      sipInners: sipInners,
      displayname: obj.displayname
    };
  },

  fromArray(arr: SipOuterServerModel[]): SipOuterModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: SipOuterModel): SipOuterServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
