export interface ModalDataModel {
    title?: string;
    body?: string;
    bodyParams?: any;
    cancelBtn?: ModalButton;
    okBtn?: ModalButton;
    warningBtn?: ModalButton;
    showDialogActions?: boolean;
    size?: ModalSize;
}

export interface ModalButton {
    title: string;
    result: string;
    style?: ModalButtonStyle;
}

export enum ModalButtonStyle {
  BASIC = 'basic',
  PRIMARY = 'primary',
  WARNING = 'warn',
}

export enum ModalSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}
