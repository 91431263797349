/**
 * MediaSelectionMode enum - specifies media player behavior when new media
 * is loaded.
 * 
 * STOP_AND_RESET - autoplay off, playback time resets to start;
 * PLAY_AND_KEEP - autoplay on, keep playback time.
 */
export enum MediaSelectionMode {
  STOP_AND_RESET,
  PLAY_AND_KEEP,
}
