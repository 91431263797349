import { PageInfoModel } from '@models/common/table-models/page-info.model';

export const PAGE_LIMIT_DEFAULT: number = 10;

export function getUrlByPageInfo(pageInfo: PageInfoModel<any>): string {
  let url = `?page=${pageInfo.page}&limit=${pageInfo.limit}`;

  if (pageInfo.filterInfo && pageInfo.filterInfo.name) {
    url = `${url}&filter[${pageInfo.filterInfo.name}]=true`;
  }

  if (pageInfo.filter) {
    const keys = Object.keys(pageInfo.filter);
    keys.forEach(key => {
      if (pageInfo.filter[key]) {
        url = `${url}&filter[${key}]=${pageInfo.filter[key]}`;
      }
    });
  }

  if (pageInfo.sort
    && pageInfo.sort.field
    && pageInfo.sort.direction) {
    url = `${url}&sort[${pageInfo.sort.field}]=${pageInfo.sort.direction}`;
  }

  return url;
}

export function createPageInfo<T>(): PageInfoModel<T> {
  const pageName = window.location.pathname;
  const data = localStorage.getItem('pagination');
  let res = {};
  if (data && data !== 'undefined') {
    res = JSON.parse(data);
  }

  return {
    page: 1,
    pageCount: 0,
    items: [] = [],
    itemsCount: 0,
    limit: typeof res[pageName] === 'object' ? 10 : res[pageName] || 10
  };
}

export function getLimit(): number {
  const pageName = window.location.pathname;
  const pagination = localStorage.getItem('pagination');
  let result = {};
  if (pagination && pagination !== 'undefined') {
    result = JSON.parse(pagination);
  }
  if (result[pageName] && result[pageName] !== 'undefined') {
    return result[pageName];
  }
  return PAGE_LIMIT_DEFAULT;
}
