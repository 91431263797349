import { Injectable } from '@angular/core';
import {LAST_SESSION_INFO} from '@shared/constant/storage.const';

/**
 * Local Storage service. Provides read and write local storage data
 * functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {
    constructor() {}

    /**
     * Stores data at the local storage by name.
     * @param name Local storage data key
     * @param data Local storage data value
     */
    writeItem(name: string, data: any): void {
        localStorage.setItem(name, JSON.stringify(data));
    }

    /**
     * Reads data from the local storage by name and converts it to JSON.
     * @param name Local storage data key
     * @param defaultValue Default value will be returned when data hasn't been set
     */
    readItem(name: string, defaultValue?: any): any {
        const data = localStorage.getItem(name);
        if (data && data !== 'undefined') {
            return JSON.parse(data);
        } else {
            // this.logger.log(`Reading storage item ${name}`, `Item with name '${name}' was not found at storage`);
            return defaultValue;
        }
    }

    /**
     * Removes data from the local storage by key.
     * @param name Local storage data key
     */
    clearItem(name: string): void {
        localStorage.removeItem(name);
    }

    /**
     * Removes all local storage data.
     */
    clear(): void {
        let lastUrl: string;
        lastUrl = this.readItem(LAST_SESSION_INFO);
        const pagination = this.readItem('pagination');
        const lang = this.readItem('user_lang');
        const theme = this.readItem('theme');
        localStorage.clear();
        this.writeItem(LAST_SESSION_INFO, lastUrl);
        this.writeItem('user_lang', lang);
        this.writeItem('pagination', pagination);
        this.writeItem('theme', theme);
    }

    writeToSession(name: string, data: any) {
        sessionStorage.setItem(name, JSON.stringify(data));
    }

    removeFromSession(name: string) {
        sessionStorage.removeItem(name);
    }

    readFromSession(name: string) {
        const data = sessionStorage.getItem(name);
        if (data && data !== 'undefined') {
            return JSON.parse(data);
        } else {
            return undefined;
        }
    }
}
