export enum ValidationErrorType {
  AGE = 'age',
  CALL_GROUP_REQUIRED = 'callGroupRequired',
  CALL_QUEUE_REQUIRED = 'callQueueRequired',
  CALL_RULE_REQUIRED = 'callRuleRequired',
  DAYS = 'days',
  DUPLICATED = 'duplicated',
  EQUAL = 'equal',
  EQUAL_TIME = 'equalTime',
  EXTENSION_REQUIRED = 'extensionRequired',
  FIRST_LETTER_ERROR = 'firstLetterError',
  INVALID_DATE = 'invalidDate',
  INVALID_RANGE = 'invalidRange',
  IVR_REQUIRED = 'ivrRequired',
  MAX = 'max',
  MAXLENGTH = 'maxlength',
  MIN = 'min',
  MINLENGTH = 'minlength',
  MUST_MATCH = 'mustMatch',
  PATTERN = 'pattern',
  RANGE = 'range',
  REQUIRED = 'required',
  START_TIME = 'startTime',
  TRIMMED_LENGTH = 'trimmedLength',
  VOICE_FILE_REQUIRED = 'voiceFileRequired',
}
