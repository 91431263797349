import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CabinetComponent } from '@modules/cabinet/cabinet.component';
import { ErrorComponent } from '@modules/error/error.component';
import { AuthGuard } from '@shared/guards/auth.guard';
import { SignUpGuard } from '@shared/guards/sign-up.guard';


const routes: Routes = [
  { path: '', redirectTo: 'cabinet', pathMatch: 'full' },
  {
    path: 'cabinet',
    component: CabinetComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@modules/dashboard/dashboard.module').then(x => x.DashboardModule),
        data: { title: 'Dashboard' }
      },
      {
        path: 'address-book',
        loadChildren: () => import('@modules/address-book/address-book.module').then(x => x.AddressBookModule),
        data: { title: 'Address Book' }
      },
      {
        path: 'phone-numbers',
        loadChildren: () => import('@modules/phone-numbers/phone-numbers.module').then(x => x.PhoneNumbersModule),
        data: { title: 'Phone Numbers' }
      },
      {
        path: 'call-rules',
        loadChildren: () => import('@modules/call-rules/call-rules.module').then(x => x.CallRulesModule),
        data: { title: 'Call Rules' }
      },
      {
        path: 'call-forwarding',
        loadChildren: () => import('@modules/call-forwarding/call-forwarding.module').then(x => x.CallForwardingModule),
        data: { title: 'Call Forwarding' }
      },
      {
        path: 'call-queues',
        loadChildren: () => import('@modules/call-queues/call-queues.module').then(x => x.CallQueuesModule),
        data: { title: 'Call Queues' }
      },
      {
        path: 'new-call-queues',
        loadChildren: () => import('@modules/new-call-queues/new-call-queues.module').then(x => x.NewCallQueuesModule),
        data: { title: 'Call Queues' }
      },
      {
        path: 'ivr',
        loadChildren: () => import('@modules/ivr/ivr.module').then(x => x.IvrModule),
        data: { title: 'IVR' }
      },
      {
        path: 'auto-call',
        loadChildren: () => import('@modules/auto-call/auto-call.module').then(x => x.AutoCallModule),
        data: { title: 'Auto Call' }
      },
      {
        path: 'account',
        loadChildren: () => import('@modules/account/account.module').then(x => x.AccountModule),
        data: { title: 'Account verification' }
      },
      {
        path: 'extensions',
        loadChildren: () => import('@modules/extensions/extensions.module').then(x => x.ExtensionsModule),
        data: { title: 'Extensions' }
      },
      {
        path: 'cdr',
        loadChildren: () => import('@modules/cdr/cdr.module').then(x => x.CdrModule),
        data: { title: 'Details and Records' }
      },
      {
        path: 'invoices',
        loadChildren: () => import('@modules/invoices/invoices.module').then(x => x.InvoicesModule),
        data: { title: 'Invoices' }
      },
      {
        path: 'refill',
        loadChildren: () => import('@modules/refill-balance/refill-balance.module').then(x => x.RefillBalanceModule),
        data: { title: 'Refill balance' }
      },
      {
        path: 'storage',
        loadChildren: () => import('@modules/storage/storage.module').then(x => x.StorageModule),
        data: { title: 'Storage' }
      },
      {
        path: 'tariff',
        loadChildren: () => import('@modules/tariff/tariff.module').then(x => x.TariffModule),
        data: { title: 'Tariff Plan' }
      },
      {
        path: 'settings',
        loadChildren: () => import('@modules/settings/settings.module').then(x => x.SettingsModule),
        data: { title: 'Settings' }
      },
      {
        path: 'marketplace',
        loadChildren: () => import('@modules/marketplace/marketplace.module').then(x => x.MarketplaceModule),
        data: { title: 'Marketplace' }
      },
      {
        path: 'support',
        loadChildren: () => import('@modules/support/support.module').then(x => x.SupportModule),
        data: { title: 'Support' }
      },
      {
        path: 'transfer',
        loadChildren: () => import('@modules/transfer/transfer.module').then(x => x.TransferModule),
        data: { title: 'Transfer with Support' }
      },
    ],
  },
  { path: 'ref/:hash', loadChildren: () => import('@modules/auth-modules/ref/ref.module').then(x => x.RefModule) },
  { path: 'sign-in', loadChildren: () => import('@modules/auth-modules/login/login.module').then(x => x.LoginModule) },
  {
    path: 'sign-up',
    canActivate: [SignUpGuard],
    loadChildren: () => import('@modules/auth-modules/sign-up/sign-up.module').then(x => x.SignUpModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('@modules/auth-modules/password-recovery/password-recovery.module').then(x => x.PasswordRecoveryModule)
  },
  {
    path: 'temporary-code',
    loadChildren: () => import('@modules/auth-modules/temporary-code/temporary-code.module').then(x => x.TemporaryCodeModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('@modules/auth-modules/logout/logout.module').then(x => x.LogoutModule)
  },
  {
    path: 'code-confirmation/:hash',
    loadChildren: () => import('@modules/auth-modules/confirm-code/confirm-code.module').then(x => x.ConfirmCodeModule)
  },
  {
    path: 'password-reset/:hash',
    loadChildren: () => import('@modules/auth-modules/password-reset/password-reset.module').then(x => x.PasswordResetModule)
  },
  {
    path: 'email-confirmation/:hash',
    loadChildren: () => import('@modules/auth-modules/email-confirm/email-confirm.module').then(x => x.EmailConfirmModule)
  },
  {
    path: 'unsubscribe/:hash',
    loadChildren: () => import('@modules/unsubscribe/unsubscribe.module').then(x => x.UnsubscribeModule)
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'error' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
