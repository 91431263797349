import { CallRuleModel } from '@models/local/call-rule/call-rule.model';
import { BaseService } from '@models/common/base-service.interface';
import { ListItemModel } from '@models/common/list-item.model';
import { PageInfoModel } from '@models/common/table-models/page-info.model';
import { Observable, Subject, of } from 'rxjs';
import { DeleteServerResponse } from '@models/server/responses/delete-response';
import { SipModel } from '@models/local/sip/sip.model';
import { SipInnerModel } from '@models/local/sip/sip-inner.model';
import { CallQueueModel } from '@models/local/call-queue/call-queue.model';
import { RingGroupModel } from '@models/local/ring-group/ring-group.model';
import { MediaFileModel } from '@models/local/storage/media-file.model';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@services/translate/translate.service';
import { ScheduleDayModel } from '@models/local/call-rule/schedule-day.model';
import { ScheduleTimeModel } from '@models/local/call-rule/schedule-time.model';
import { map, tap, switchMap } from 'rxjs/operators';
import { CallQueueServerModel } from '@models/server/call-queue/call-queue-server.model';
import { convertPageInfo } from '@mappers/page-info.mapper';
import { CallQueueMapper } from '@mappers/call-queue/call-queue.mapper';
import { MediaFileMapper } from '@mappers/storage/media-file.mapper';
import { MediaFileServerModel } from '@models/server/storage/media-file-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { CallRuleServerModel } from '@models/server/call-rule/call-rule-server.model';
import { CallRuleMapper } from '@mappers/call-rules/call-rule.mapper';
import { getUrlByPageInfo } from '@helpers/utils/pageInfo.utils';
import { environment } from '@env/environment';


export abstract class AbstractCallRulesService implements BaseService<CallRuleModel> {

  actions: ListItemModel[];

  phoneNumbers: SipModel[];
  extensions: SipInnerModel[];
  callQueues: CallQueueModel[];
  nextQueues: any;
  ringGroups: RingGroupModel[];
  mediaFiles: MediaFileModel[];

  mediaFileReplaced$: Subject<any> = new Subject<any>();

  scheduleDateTypes: ListItemModel[] = [
    { id: 1, code: 'Always' },
    { id: 3, code: 'Days of the week' },
  ];

  scheduleTimeTypes: ListItemModel[] = [
    { id: 1, code: 'Always' },
    { id: 2, code: 'Set the time' },
  ];

  scheduleDays: ScheduleDayModel[] = [
    { code: 'mon', title: 'Mon', selected: true },
    { code: 'tue', title: 'Tue', selected: true },
    { code: 'wed', title: 'Wed', selected: true },
    { code: 'thu', title: 'Thu', selected: true },
    { code: 'fri', title: 'Fri', selected: true },
    { code: 'sat', title: 'Sat', selected: false },
    { code: 'sun', title: 'Sun', selected: false },
  ];

  schedule12HCTimes: ScheduleTimeModel[];
  schedule24HCTimes: ScheduleTimeModel[];

  schedule12HCHours: ScheduleTimeModel[];
  schedule24HCHours: ScheduleTimeModel[];

  scheduleMinutes: ScheduleTimeModel[];

  constructor(
    protected http: HttpClient,
    protected translate: TranslateService
  ) {
    this.schedule12HCTimes = this.create12HCTimes();
    this.schedule24HCTimes = this.create24HCTimes();
    this.schedule12HCHours = this.create12HCHours();
    this.schedule24HCHours = this.create24HCHours();
    this.scheduleMinutes = this.createMinutes();

    this.translate.register('schedule-date-types', this.scheduleDateTypes, 'code');
    this.translate.register('schedule-time-types', this.scheduleTimeTypes, 'code');
    this.translate.register('schedule-times', this.schedule12HCTimes, 'time', false, { dictKey: 'dictKey', paramKey: 'time' });
    this.translate.register('schedule-hours', this.schedule12HCHours, 'time', false, { dictKey: 'dictKey', paramKey: 'time' });
  }

  // -- BaseService implementation --------------------------------------------

  baseURL: string;

  getItems(pageInfo: PageInfoModel<CallRuleModel>): Observable<PageInfoModel<CallRuleModel>> {
    return this.getReferences(true)
      .pipe(
        switchMap(() => this.http
          .get<PageInfoModel<CallRuleServerModel>>(`${this.baseURL}${getUrlByPageInfo(pageInfo)}`)),
        map(res => convertPageInfo(res, CallRuleMapper)),
      );
  }

  getItem(id: number): Observable<CallRuleModel> {
    return this.getReferences()
      .pipe(
        switchMap(() => this.http.get<CallRuleServerModel>(`${this.baseURL}/${id}`)),
        map(res => CallRuleMapper.from(res))
      );
  }

  saveItem(model: CallRuleModel): Observable<any> {
    if (model.id) {
      return this.http
        .put(`${this.baseURL}/${model.id}`, { ...model });
    } else {
      return this.http
        .post(`${this.baseURL}`, { ...model });
    }
  }

  deleteItem(id: number): Observable<DeleteServerResponse> {
    return this.http
      .delete<DeleteServerResponse>(`${this.baseURL}/${id}`);
  }

  // -- References ------------------------------------------------------------

  getReferences(forceLoad: boolean = false): Observable<any> {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }

  getCallRuleActions(forceLoad: boolean = false): Observable<ListItemModel[]> {
    if (this.actions && !forceLoad) {
      return of(this.actions);
    }
    return this.http
      .get(`${this.baseURL}/params`)
      .pipe(
        map((res: any) => res.actions),
        tap(res => {
          this.actions = res;
          this.translate.register('call-rule-actions', this.actions, 'code');
        })
      );
  }

  getCallQueues(forceLoad: boolean): Observable<CallQueueModel[]> {
    if (this.callQueues && !forceLoad) {
      return of(this.callQueues);
    }
    return this.http
      .get<PageInfoModel<CallQueueServerModel>>(`${this.baseURL}/call-queue`)
      .pipe(
        map(res => convertPageInfo(res, CallQueueMapper).items),
        tap(res => this.callQueues = res)
      );
  }

  getNextQueues(): Observable<CallQueueModel[]> {
    return this.http.get<PageInfoModel<any>>(`${environment.urlWithVersion}/queuesNext`).pipe(
      map(response => {
        this.nextQueues = response.items.map(r => ({
          id: r.id,
          name: r.title,
          globalId: r.name
        }));
        return this.nextQueues;
      })
    );
  }

  getMediaFiles(forceLoad: boolean): Observable<MediaFileModel[]> {
    if (this.mediaFiles && !forceLoad) {
      return of(this.mediaFiles);
    }
    return this.http
      .get<PageInfoModel<MediaFileServerModel>>(`${this.baseURL}/files?type=audio&limit=500`)
      .pipe(
        map(res => convertPageInfo(res, MediaFileMapper).items),
        tap(res => this.mediaFiles = res)
      );
  }

  private create12HCTimes(): ScheduleTimeModel[] {
    const model = [];

    for (let i = 0; i < 24; ++i) {
      const hpfx = i < 10 && i > 0 ? '0' : '';
      const tpfx = i < 12 ? 'a' : 'p';
      const hour = (i % 12 === 0) ? 12 : i % 12;
      model.push({
        time: `${hour}:00`,
        sysTime: `${hpfx}${i}:00`,
        dictKey: `${tpfx}mTime`
      });
    }

    return model;
  }

  private create24HCTimes(): ScheduleTimeModel[] {
    const model = [];

    for (let i = 0; i < 24; ++i) {
      const hpfx = i < 10 ? '0' : '';
      model.push({
        time: `${i}:00`,
        sysTime: `${hpfx}${i}:00`
      });
    }

    return model;
  }

  private create12HCHours(): ScheduleTimeModel[] {
    const model = [];

    for (let i = 0; i < 24; ++i) {
      const hpfx = i < 10 && i > 0 ? '0' : '';
      const tpfx = i < 12 ? 'a' : 'p';
      const hour = (i % 12 === 0) ? 12 : i % 12;
      model.push({
        time: `${hour}`,
        sysTime: `${hpfx}${i}`,
        dictKey: `${tpfx}mTime`
      });
    }

    return model;
  }

  private create24HCHours(): ScheduleTimeModel[] {
    const model = [];

    for (let i = 0; i < 24; ++i) {
      const hpfx = i < 10 ? '0' : '';
      model.push({
        time: `${i}`,
        sysTime: `${hpfx}${i}`
      });
    }

    return model;
  }

  private createMinutes(): ScheduleTimeModel[] {
    const model = [];

    for (let i = 0; i < 60; i += 5) {
      const hpfx = i < 10 ? '0' : '';
      model.push({
        time: `${hpfx}${i}`,
        sysTime: `${hpfx}${i}`
      });
    }

    return model;
  }
}
