import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TagModel } from '@models/common/tag.model';


@Component({
  selector: 'etl-tag-selector',
  templateUrl: './etl-tag-selector.component.html',
  styleUrls: ['./etl-tag-selector.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlTagSelectorComponent),
      multi: true
    }
  ],
})
export class EtlTagSelectorComponent
  implements OnInit, ControlValueAccessor {
  
  value: string[];

  selected: TagModel[];
  unselected: TagModel[];

  private _tags: TagModel[];

  @Input() set tags(value: TagModel[]) {
    this._tags = value;
    this.updateData();
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor() { }

  ngOnInit(): void { }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(obj: string[]): void {
    this.value = obj;
  }

  onChange: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched: any = () => {};
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // -- event handlers --------------------------------------------------------

  onSelect(event: TagModel): void {
    const tag = this._tags.find(t => t.id === event.id);
    if (tag) {
      tag.selected = true;
      this.updateData();
    }
  }

  onDeselect(event: TagModel): void {
    const tag = this._tags.find(t => t.id === event.id);
    if (tag) {
      tag.selected = false;
      this.updateData();
    }
  }

  // -- general methods -------------------------------------------------------

  setValue(): void {
    if (!this.selected || this.selected.length === 0) {
      this.value = null;
    } else {
      this.value = this.selected.map(tag => tag.id);
    }
    this.onChange(this.value);
    this.onTouched();
  }

  updateData(): void {
    if (this._tags && this._tags.length > 0) {
      this.selected = this._tags.filter(tag => tag.selected);
      this.unselected = this._tags.filter(tag => !tag.selected);
    }
    this.setValue();
  }
}
