import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const SIGN_UP_VALIDATION_MESSAGES = [
  {
    name: 'firstname',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your name'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.PATTERN,
    message: 'Name contains invalid characters or symbols. You can only use letters and the following characters: \'-.'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'Your name must begin with a letter'
  },
  {
    name: 'firstname',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Name can\'t contain over 190 characters'
  },
  {
    name: 'email',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct email address'
  },
  {
    name: 'email',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your email address'
  },
  {
    name: 'agreementConfirmation',
    error: ValidationErrorType.REQUIRED,
    message: 'You need to confirm Terms and Conditions and privacy statement agreement'
  },
  {
    name: 'phone',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter phone number'
  },
  {
    name: 'phone',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'Phone is too short. Please use at least 10 characters' : 'Contact Phone is too short. Please use at least 11 characters'
  },
  {
    name: 'phone',
    error: ValidationErrorType.MAXLENGTH,
    message: isComVersion() ? 'Contact phone is too long. Use no more than 16 numbers' : 'Contact phone is too long. Use no more than 11 numbers'
  },
  {
    name: 'phone',
    error: ValidationErrorType.PATTERN,
    message: isComVersion() ? 'Contact phone contains invalid characters. You can use numbers only' : 'Contact phone contains invalid characters. You can only use numbers and the following characters: #+'
  },
  {
    name: 'country',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose country'
  }
];
