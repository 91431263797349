import { ValidationErrorType } from './validation-error-type.enum';

export const LOGIN_VALIDATION_MESSAGES = [
  {
    name: 'login',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter correct email address or internal number'
  },
  {
    name: 'login',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct email address or internal number'
  },
  {
    name: 'password',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your password'
  },
  {
    name: 'password',
    error: ValidationErrorType.MAXLENGTH,
    message: 'The password is too long'
  },
  {
    name: 'password',
    error: ValidationErrorType.MINLENGTH,
    message: 'The password is too short'
  }
];
