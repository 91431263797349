export function scrollToBottomById(elementId: string): void {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element && element.scrollHeight) {
      element.scrollTop = element.scrollHeight;
    }
  }, 0);
}

export function scrollToBottomByTag(elementId: string): void {
  setTimeout(() => {
    const element = document.getElementsByTagName(elementId)[0];
    if (element && element.scrollHeight) {
      element.scrollTop = element.scrollHeight;
    }
  }, 0);
}

export function forceFileDownload(data: Blob, fileName: string = 'file'): void {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(data);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return;
}
