import { Mapper } from '@mappers/mapper';
import { ContactModel } from '@models/local/address-book/contact.model';
import { ContactServerModel } from '@models/server/address-book/contact-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { ContactEmailMapper } from './contact-email.mapper';
import { ContactPhoneMapper } from './contact-phone.mapper';


// tslint:disable-next-line:naming-convention
export let ContactMapper: Mapper<ContactModel, ContactServerModel> = {

  from(obj: ContactServerModel): ContactModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      address: obj.address,
      blacklist: obj.blacklist,
      blacklistId: obj.blacklistId,
      canBlock: obj.canBlock,
      company: obj.company,
      canRemove: obj.canRemove,
      department: obj.department,
      emails: ContactEmailMapper.fromArray(obj.contactEmail),
      email: obj.contactEmail && obj.contactEmail.length > 0
        ? obj.contactEmail[0].value
        : null,
      phones: ContactPhoneMapper.fromArray(obj.contactPhone),
      phoneNumber: obj.contactPhone && obj.contactPhone.length
        ? obj.contactPhone[0].value
        : null,
      countryId: obj.countryId,
      firstname: obj.firstname,
      lastname: obj.lastname,
      position: obj.position,
      name: obj.name
    };
  },

  fromArray(arr: ContactServerModel[]): ContactModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: ContactModel): ContactServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
