import { ValidationErrorType } from './validation-error-type.enum';
import {isComVersion} from '@helpers/utils/app.utils';

export const COMPANY_VALIDATION_MESSAGES = [
  {
    name: 'organization',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the organization name'
  },
  {
    name: 'organization',
    error: ValidationErrorType.MAXLENGTH,
    message: 'The organization name is too long. Use no more than 100 characters'
  },
  {
    name: 'organization',
    error: ValidationErrorType.PATTERN,
    message: 'The organization name contains invalid characters or symbols. You can only use letters and the following characters: \'-.'
  },
  {
    name: 'email',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your email address'
  },
  {
    name: 'email',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct email address'
  },
  {
    name: 'phone',
    error: ValidationErrorType.PATTERN,
    message: 'Contact phone contains invalid characters. You can use numbers only',
  },
  {
    name: 'phone',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Contact phone can\'t contain over 16 characters'
  },
  {
    name: 'phone',
    error: ValidationErrorType.MINLENGTH,
    message: isComVersion() ? 'Contact phone is too short. Please use at least 10 characters' : 'Contact phone is too short. Please use at least 6 characters'
  },
  {
    name: 'vatId',
    error: ValidationErrorType.MINLENGTH,
    message: 'VAT ID is too short. Please use at least 6 characters'
  },
  {
    name: 'vatId',
    error: ValidationErrorType.MAXLENGTH,
    message: 'VAT ID is too longe. Use no more than 16 characters'
  },
  {
    name: 'vatId',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct VAT ID'
  },
  {
    name: 'postalCode',
    error: ValidationErrorType.MINLENGTH,
    message: 'Postal code is too short. Please use at least 6 characters'
  },
  {
    name: 'postalCode',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Postal code can\'t contain over 9 characters'
  },
  {
    name: 'postalCode',
    error: ValidationErrorType.PATTERN,
    message: 'Postal code contains invalid characters. You can only use letters and numbers only'
  },
  {
    name: 'regionName',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your State/Region'
  },
  {
    name: 'regionName',
    error: ValidationErrorType.MINLENGTH,
    message: 'State/Region is too short. The State/Region must be at least 1 characters long'
  },
  {
    name: 'regionName',
    error: ValidationErrorType.MAXLENGTH,
    message: 'State/Region is too longe. Use no more than 100 characters'
  },
  {
    name: 'regionName',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct State/Region'
  },
  {
    name: 'locationName',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your city name'
  },
  {
    name: 'locationName',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct city name'
  },
  {
    name: 'street',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter your street name'
  },
  {
    name: 'street',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct street name'
  },
  {
    name: 'building',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the House number'
  },
  {
    name: 'building',
    error: ValidationErrorType.MAXLENGTH,
    message: 'House can\'t contain over 10 characters'
  },
  {
    name: 'building',
    error: ValidationErrorType.PATTERN,
    message: 'Please enter correct the House number'
  },
  {
    name: 'office',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Office can\'t contain over 15 characters'
  },
  {
    name: 'office',
    error: ValidationErrorType.PATTERN,
    message: 'Office contains invalid characters. You can only use letters, numbers and the following characters: \'-.',
  },
];
