import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
// import 'rxjs/add/observable/fromPromise';
import { Observable, of } from 'rxjs';

import { environment as env } from '@env/environment';
import { map } from 'rxjs/operators';

// import * as translationsData from '../assets/i18n/translations.json';
const translateStorageKey = 'etl-translate';

export class EtlTranslateLoader implements TranslateLoader {

  translationsRoot: string;
  translations: {} = {};

  constructor(private _http: HttpClient) {
    this.translationsRoot = env.translation;
  }

  getTranslation(lang: string): Observable<any> {
    return of(this.translations[lang]);
  }

  loadTranslations(): Observable<any> {
    this.translations = {};
    const tra = localStorage.getItem(translateStorageKey);
    if (tra && tra !== 'null') {
      const res = JSON.parse(tra);
      if (res) {
        Object.keys(res).forEach(key => {
          this.setTranslation(res[key], 'en', 'eng');
          this.setTranslation(res[key], 'ru', 'ru');
        });
      }
      return of(this.translations);
    }
    return this._http
      .get(this.translationsRoot)
      .pipe(
        map(response => {
          localStorage.setItem(translateStorageKey, JSON.stringify(response));
          Object.keys(response).forEach(key => {
            this.setTranslation(response[key], 'en', 'eng');
            this.setTranslation(response[key], 'ru', 'ru');
          });
          return this.translations;
        })
      );
  }

  setTranslation(item: any, localLang: string, externalLang: string): void {
    if (!this.translations[localLang]) {
      this.translations[localLang] = {};
    }

    this.translations[localLang][item['key']] = item[externalLang];
  }
}
