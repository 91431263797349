import { Mapper } from '@mappers/mapper';
import { CallRuleActionServerModel } from '@models/server/call-rule/call-rule-action-server.model';
import { TIME_RULES_DEFAULT } from '@shared/constant/call-rule.constants';
import { CallRuleActionModel } from '@models/local/call-rule/call-rule-action.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';

// tslint:disable-next-line:naming-convention
export let CallRuleActionMapper: Mapper<CallRuleActionModel, CallRuleActionServerModel> = {

  from(obj: CallRuleActionServerModel): CallRuleActionModel {
    if (!obj) { return null; }

    if (!obj.timeRules) {
      obj.timeRules = TIME_RULES_DEFAULT;
    }

    const timeParts = obj.timeRules.split('|');

    let sipInnerNumber: string;
    if (obj.sipInner) {
      sipInnerNumber = obj.sipInner.phoneNumber;
    }

    return {
      id: obj.id,
      action: obj.action,
      parameter: obj.parameter,
      timeout: obj.timeout,
      timeRules: obj.timeRules,
      scheduleDate: timeParts[1],
      scheduleTime: timeParts[0],
      sipInnerNumber: sipInnerNumber
    };
  },

  fromArray(arr: CallRuleActionServerModel[]): CallRuleActionModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: CallRuleActionModel): CallRuleActionServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  },

  toArray(arr: CallRuleActionModel[]): CallRuleActionServerModel[] {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
