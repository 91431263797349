import { Observable, interval, concat } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export class GuideTaskSchedulerModel {

  private _queue$: Observable<any>[] = [];

  push(delay: number, fn: () => void): void {
    this._queue$.push(this._lazyExec(delay, fn));
  }

  run(): void {
    concat(...this._queue$)
      .subscribe();
  }

  // tslint:disable-next-line:naming-convention
  private _lazyExec(delayMs: number, fn: () => void): Observable<any> {
    return interval(delayMs)
      .pipe(
        take(1),
        tap(_ => fn()),
      );
  }
}
