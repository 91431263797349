export enum GuideEventType {
  BUTTON_CLICK = 'buttonClick',
  PAGE_LOAD = 'pageLoad',
  SAVE_DATA = 'dataSave'
}

export enum GuideEventHandler {
  HIDE_TOOLTIP = 'hideTooltip',
  SHOW_NEXT_STEP = 'showNextStep'
}

export enum GuideAction {
  VALIDATE_FORM = 'validateForm',
  VALIDATE_TABLE_CHECKED = 'validateTableChecked',
  CHECK_FORM_VALUE = 'checkFormValue',
  CHECK_MODULE_ENABLED = 'checkModuleEnabled'
}

export enum GuideSrcElement {
  ADDRESS_BOOK_PAGE = 'addressBookPage',
  ADDRESS_BOOK_SIDEBAR = 'addressBookSidebar',
  CALL_QUEUE_PAGE = 'callQueuePage',
  CALL_QUEUE_EDIT_PAGE = 'callQueueEditPage',
  CALL_QUEUE_MEMBERS_PAGE = 'callQueueMembersPage',
  CALL_QUEUE_ADD_MEMBERS_PAGE = 'callQueueAddMembersPage',
  EXTENSION_PAGE = 'extensionPage',
  EXTENSION_EDIT_PAGE = 'extensionEditPage',
  INVOICE_VIEW_PAGE = 'invoiceViewPage',
  
  ADD_MEMBERS_BUTTON = 'addMembersButton',
  CREATE_BUTTON = 'createButton',
  DOWNLOAD_BUTTON = 'downloadButton',
  SAVE_BUTTON = 'saveButton',
  ZOOM_BUTTON = 'zoomButton'
}
