import { GuideStepModel, GuideOptionsModel } from './guide.interfaces';
import { CALL_QUEUE_CREATE_SCENARIO } from './scenarios/call-queue-create.scenario';
import { INVOICES_DOWNLOAD_SCENARIO } from './scenarios/invoices-download.scenario';
import { EXTENSION_CREATE_SCENARIO } from './scenarios/extension-create.scenario';
import { ADDRESS_BOOK_CREATE_SCENARIO } from './scenarios/address-book-create.scenario';


export const GUIDE_DEFAULT_OPTIONS: GuideOptionsModel = {
  hidePrev: true,
  hideNext: true,
  prevLabel: 'guide-back',
  nextLabel: 'guide-next',
  closeLabel: 'guide-close',
  stepSwitchDelay: 30
};

export const GUIDE_INFO: any[] = [
  {
    key: 'addressBookCreate',
    startPage: '/cabinet/address-book',
    // stepSwitchDelay: 50,
  },
  {
    key: 'callQueueCreate',
    startPage: '/cabinet/call-queues',
  },
  {
    key: 'extensionCreate',
    startPage: '/cabinet/extensions'
  },
  {
    key: 'invoiceDownload',
    startPage: '/cabinet/invoices',
  },
];

export const GUIDE_SCENARIOS: { [key: string]: GuideStepModel[] } = {
  'addressBookCreate': ADDRESS_BOOK_CREATE_SCENARIO,
  'callQueueCreate': CALL_QUEUE_CREATE_SCENARIO,
  'extensionCreate': EXTENSION_CREATE_SCENARIO,
  'invoiceDownload': INVOICES_DOWNLOAD_SCENARIO,
};
