import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { AuthenticationService } from './authentication-service.service';
import { SignUpModel } from '@models/server/user/signUp.model';
import { ServerTokenModel } from '@models/server/user/server.token.model';
import { ModuleName } from '@models/common/module/module-name.enum';
import { CURRENT_USER } from '@shared/constant/storage.const';
import { UserDataModel } from '@models/local/user/user-data.model';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentEmail: string;
  accountInactiveLogin = false;

  constructor(
    private _http: HttpClient,
    private _authService: AuthenticationService,
    private _storage: BrowserStorageService
  ) { }

  signUp(data: SignUpModel) {
    if (localStorage.getItem('ref') && localStorage.getItem('uniqueHash')) {
      data.ref = localStorage.getItem('ref');
      data.uniqueHash = localStorage.getItem('uniqueHash');
    }
    return this._http.post(`${environment.back}/registration`, { ...data }).pipe(
      tap(() => { this.currentEmail = data.email; })
    );
  }

  sendResetLink(email: string): Observable<any> {
    return this._http.post<any>(`${environment.back}/password/reset`, { email });
  }

  sendTemporaryPassword(email: string): Observable<any> {
    return this._http.post<any>(`${environment.back}/password/temporary`, { email });
  }

  resendEmail(email: string): Observable<any> {
    return this._http.post<any>(`${environment.back}/registration/resend-confirm`, { email });
  }

  codeConfirm(code: string, hash: string): Observable<ServerTokenModel> {
    return this._http.post<ServerTokenModel>(`${environment.back}/login/${hash}`, { code }).pipe(
      tap(res => {
        this._authService.initToken(res);
      })
    );
  }

  confirmEmail(code: string) {
    return this._http.get<any>(`${environment.back}/registration/confirm/${code}`);
  }

  changePassword(password: string, hash: string) {
    return this._http.post(`${environment.back}/password/reset/${hash}`, {
      password: password,
      password_confirmation: password
    });
  }

  isModuleEnabled(module: ModuleName): boolean {
    const user = this._storage.readItem(CURRENT_USER) as UserDataModel;
    if (user && user.services) {
      return user.services.some(service => service.name === module);
    }
    return false;
  }
}
