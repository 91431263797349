import {
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { VgApiService, VgStates, IPlayable } from '@videogular/ngx-videogular/core';
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';
import { Subject } from 'rxjs';

import { MediaPlayerComponent } from '@models/common/media-player/media-player.component';


@Component({
  selector: 'etl-media-player',
  templateUrl: './etl-media-player.component.html',
  styleUrls: ['./etl-media-player.component.sass']
})
export class EtlMediaPlayerComponent
  implements MediaPlayerComponent {

  private _api: VgApiService;

  playerReady: Subject<VgApiService> = new Subject();
  stateChange: Subject<VgStates> = new Subject();
  timeUpdate: Subject<number> = new Subject();
  volumeChange: Subject<number> = new Subject();

  @Input() mediaStream: string = '';

  hlsBitrates: any;
  @ViewChild(VgHlsDirective, { static: false }) vgHls: VgHlsDirective;

  // -- properties ------------------------------------------------------------

  get media(): IPlayable {
    return this._api.getDefaultMedia();
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor() { }

  // -- event handlers --------------------------------------------------------

  onPlayerReady(api: VgApiService): void {
    this._api = api;

    this.media.subscriptions.timeUpdate
      .subscribe(() => {
        if (this.media.state === VgStates.VG_PLAYING) {
          const time = this.media.currentTime;
          this.timeUpdate.next(time);
        }
      });

    this.media.subscriptions.ended
      .subscribe(() => {
        this.media.currentTime = 0;
        this.onStateChange();
      });

    this.media.subscriptions.canPlay
      .subscribe(() => {
        this.onStateChange();
      });

    this.playerReady.next(api);
  }

  onStateChange(): void {
    setTimeout(() => {
      this.stateChange.next(this.media.state);
    }, 0);
  }

  // -- MediaPlayerComponent interface ----------------------------------------

  setMedia(mediaStream: string, time: number = 0): void {
    this.mediaStream = mediaStream;
    setTimeout(() => {
      if (time < 0) {
        this._api.getDefaultMedia().pause();
        this.media.currentTime = 0;
      } else {
        this.media.currentTime = time;
      }
      this.onStateChange();
    }, 0);
  }

  setTime(time: number): void {
    this.media.currentTime = time;
  }

  setVolume(volume: number): void {
    this._api.volume = volume;
  }

  start(time: number = 0): void {
    this.media.pause();
    this.media.currentTime = time;
    setTimeout(() => {
      this.media.play();
      this.onStateChange();
    }, 0);
  }

  stop(): void {
    this.media.pause();
    this.onStateChange();
  }

  toggle(): void {
    switch (this.media.state) {
      case VgStates.VG_LOADING:
        break;
      case VgStates.VG_PLAYING:
        this.media.pause();
        this.onStateChange();
        break;
      case VgStates.VG_PAUSED:
      case VgStates.VG_ENDED:
        this.media.play();
        this.onStateChange();
        break;
    }
  }
}
