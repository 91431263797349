import { ValidationErrorType } from './validation-error-type.enum';

export const IVR_LEVEL = [
  {
    name: 'name',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Ivr name'
  },
  {
    name: 'name',
    error: ValidationErrorType.MINLENGTH,
    message: 'Ivr name is too short. Please use at least 4 characters'
  },
  {
    name: 'name',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Ivr name can\'t contain over 40 characters'
  },
  {
    name: 'name',
    error: ValidationErrorType.PATTERN,
    message: 'You can only use letters, numbers, and the following characters: -_\'.&'
  },
  {
    name: 'name',
    error: ValidationErrorType.FIRST_LETTER_ERROR,
    message: 'firstCharErrorMsg_Nt',
    params: { field: 'IVR Name' }
  },
  {
    name: 'action',
    error: ValidationErrorType.REQUIRED,
    message: 'Please select action'
  },
  {
    name: 'description',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Ivr description can\'t contain over 255 characters'
  },
  {
    name: 'voiceGreeting',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Voice Greeting'
  },
  {
    name: 'loopMessage',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Loop message'
  },
  {
    name: 'loopMessage',
    error: ValidationErrorType.PATTERN,
    message: 'Loop message value should be from 1 to 5.'
  },
  {
    name: 'waitTime',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Wait time'
  },
  {
    name: 'waitTime',
    error: ValidationErrorType.MIN,
    message: 'Wait time cannot be less than 1'
  },
  {
    name: 'waitTime',
    error: ValidationErrorType.MAX,
    message: 'Wait time cannot exceed 20'
  },
  {
    name: 'digits.*.digit',
    error: ValidationErrorType.REQUIRED,
    message: '??????'
  },
  {
    name: 'digits.*.description',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Description can\'t contain over 255 characters'
  },
  {
    name: 'digits.*.action',
    error: ValidationErrorType.REQUIRED,
    message: '?????????'
  },
  {
    name: 'digits.*.parameter',
    error: ValidationErrorType.REQUIRED,
    message: '?????????'
  },
];

export const fillValidationLevels = () => {
  const res: any[] = [
    {
      name: 'sipId',
      error: ValidationErrorType.REQUIRED,
      message: 'Please choose the Phone number'
    },
    ...IVR_LEVEL,
  ];

  IVR_LEVEL.filter(x => x.name !== 'name').forEach(item => {
    res.push({
      name: `levels.*.${item.name}`,
      error: item.error,
      message: item.message
    });
  });
  res.push(...[
    {
      name: 'levels.*.name',
      error: ValidationErrorType.REQUIRED,
      message: 'Please enter the Level name'
    },
    {
      name: 'levels.*.name',
      error: ValidationErrorType.MINLENGTH,
      message: 'Level name is too short. Please use at least 4 characters'
    },
    {
      name: 'levels.*.name',
      error: ValidationErrorType.MAXLENGTH,
      message: 'Level name can\'t contain over 40 characters'
    },
    {
      name: 'levels.*.name',
      error: ValidationErrorType.PATTERN,
      message: 'You can only use letters, numbers, and the following characters: -_\'.&'
    },
    {
      name: 'levels.*.name',
      error: ValidationErrorType.FIRST_LETTER_ERROR,
      message: 'firstCharErrorMsg_Nt',
      params: { field: 'Level Name' }
    },
  ]);

  return res;
};
