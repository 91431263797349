import { Component, OnInit, forwardRef, Input, ViewChildren, QueryList, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { EtlBaseFormControlComponent } from '@elements/etl-base-form/etl-base-form-control.component';
import { CallRulesService } from '@services/call-rules/call-rules.service';
import { EtlSelectComponent } from '@elements/etl-select/etl-select.component';
import { ScheduleTimeModel } from '@models/local/call-rule/schedule-time.model';
import { CallRuleScheduleTimeType } from '@shared/constant/call-rule.constants';
import { ListItemModel } from '@models/common/list-item.model';
import { AuthenticationService } from '@services/user/authentication-service.service';

@Component({
  selector: 'schedule-time',
  templateUrl: './schedule-time.component.html',
  styleUrls: ['./schedule-time.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleTimeComponent),
      multi: true
    }
  ],
})
export class ScheduleTimeComponent
  extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor {

  value: string;
  
  timeFrom: ScheduleTimeModel;
  timeTo: ScheduleTimeModel;
  
  timeList: ScheduleTimeModel[];

  private _selectedType: number = CallRuleScheduleTimeType.ALWAYS;
  get selectedType(): number {
    return this._selectedType;
  }
  @Input() set selectedType(val: number) {
    this._selectedType = +val;
  }

  @ViewChildren(EtlSelectComponent) selectControls: QueryList<EtlSelectComponent>;

  // -- properties ------------------------------------------------------------

  get scheduleTimeTypes(): ListItemModel[] {
    return this.service.scheduleTimeTypes;
  }

  get timeRangeVisible(): boolean {
    return this.selectedType === CallRuleScheduleTimeType.CERTAIN_TIME;
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    public service: CallRulesService,
    protected injector: Injector,
    private _auth: AuthenticationService,
  ) {
    super(injector);

    this.timeList = (+this._auth.user.timeFormat === 24)
      ? this.service.schedule24HCTimes
      : this.service.schedule12HCTimes;
    this.timeFrom = this.timeList.find(t => t.sysTime === '09:00');
    this.timeTo = this.timeList.find(t => t.sysTime === '18:00');
  }

  ngOnInit() { }

  // -- ControlValueAccessor interface ----------------------------------------

  writeValue(value: any): void {
    if (!value || typeof value !== 'string') { return; }

    if (value === '*') {
      this.selectedType = CallRuleScheduleTimeType.ALWAYS;
    } else {
      this.selectedType = CallRuleScheduleTimeType.CERTAIN_TIME;
      const times = value.split('-');
      this.timeFrom = this.getTimeModel(this.timeList, times[0]);
      this.timeTo = this.getTimeModel(this.timeList, times[1]);
    }
  }

  setValue(): void {
    let value: string;
    if (this.selectedType === CallRuleScheduleTimeType.ALWAYS) {
      value = '*';
    } else if (this.selectedType === CallRuleScheduleTimeType.CERTAIN_TIME) {
      value = `${this.timeFrom.sysTime}-${this.timeTo.sysTime}`;
    }
    this.value = value;

    this.onChange(this.value);
  }

  // -- event handlers --------------------------------------------------------

  onFocus(event?: any): void {
    if (!this.focused) {
      this.focused = true;
      this.focus.emit(event);
    }
  }

  onBlur(event?: any): void {
    if (this.selectControls.find(c => c.focused) && this.focused) {
      this.focused = false;
      this.blur.emit(event);
    }
  }

  // -- helpers ---------------------------------------------------------------

  getTimeModel(model: ScheduleTimeModel[], sysTime: string): ScheduleTimeModel {
    return model.find(t => t.sysTime === sysTime);
  }
}
