import { Mapper } from '@mappers/mapper';
import { SupportStatusUpdateModel } from '@models/local/support/support-status-update.model';
import { SupportStatusUpdateServerModel } from '@models/server/support/support-status-update-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { SupportStatusMapper } from './support-status.mapper';

// tslint:disable-next-line:naming-convention
export let SupportStatusUpdateMapper: Mapper<SupportStatusUpdateModel, SupportStatusUpdateServerModel> = {
  from(obj: SupportStatusUpdateServerModel): SupportStatusUpdateModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      status: obj.status,
      statusName: SupportStatusMapper.from(obj.status),
    };
  },

  fromArray(arr: SupportStatusUpdateServerModel[]): SupportStatusUpdateModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: SupportStatusUpdateModel): SupportStatusUpdateServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
