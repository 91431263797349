import { isArray, isObject, isNullOrUndefined, isNull } from 'util';

export function hasId(obj: any): boolean {
  if (obj === null || obj === undefined) {
    return false;
  }
  if (Array.isArray(obj)) {
    return hasId(obj[0]);
  }
  if (obj !== null && typeof obj === 'object') {
    return obj.hasOwnProperty('id');
  }
  return false;
}

export function tryValueAsNumber(value: any): object | number {
  return value !== null && typeof value !== 'object' && !isNaN(+value)
    ? +value
    : value;
}
