import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@services/user/authentication-service.service';
import { EtlHttpErrorResponse } from '@models/local/responses/etl-http-error-response';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    ) { }

  private handleError(error: HttpErrorResponse): Observable<never> {
    // if refresh-token return any error
    if (error.url.includes('refresh')) {
      this._authService.logout();
    }
    // proccess error code here
    if (error.status === 401) {
      this._authService.logout();
    }
    if (error.status === 403 && error.url.indexOf('cabinet/dashboard') === -1) {
      this._router.navigate(['cabinet/dashboard']);
    }
    // copy 'error' field to 'internal' to stop mess in handling errors
    // so 'error.error.errors' can be accessed as 'error.interal.errors'
    if (error.error) {
      (error as EtlHttpErrorResponse).internal = error.error;
    }
    return throwError(
      error
    );
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => this.handleError(err))
    );
  }
}
