import { Component, OnInit, HostListener } from '@angular/core';

import { GuideService } from '@services/guide/guide.service';
import { GuideModel } from '@models/common/guide/guide.model';


@Component({
  selector: 'app-guide',
  templateUrl: './etl-guide.component.html',
  styleUrls: ['./etl-guide.component.sass']
})
export class EtlGuideComponent implements OnInit {

  // --- properties -----------------------------------------------------------

  service: GuideService;
  guide: GuideModel;

  visible: boolean;
  tooltipVisible: boolean = true;

  // -- component lifecycle hooks ---------------------------------------------

  constructor() { }

  ngOnInit(): void {
    this.guide = this.service.guide;
  }

  // -- event handlers --------------------------------------------------------

  @HostListener('window:resize')
  onResize() {
    this.guide.redraw();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const code = event.code;

    if (code === 'Escape' || +code === 27) {
      this.stop();
    } else if (code === 'Tab' || +code === 9) {
      this.guide.next();
    } else if (code === 'ArrowRight' || +code === 39) {
      this.guide.next();
    }
  }

  onClose(): void {
    this.stop();
  }

  onPrev(): void {
    this.guide.prev();
  }
  
  onNext(): void {
    this.guide.next();
  }

  // -- event handlers --------------------------------------------------------

  show(): void {
    this.visible = true;
  }
  
  hide(): void {
    this.visible = false;
  }

  showTooltip(): void {
    this.tooltipVisible = true;
  }

  hideTooltip(): void {
    this.tooltipVisible = false;
  }

  start(): void {
    this.show();
    this.guide.start();
  }

  stop(): void {
    this.guide.stop();
    this.hide();
  }
}
