export function fromPlain<T extends Object>(cls: new () => T, item: any): T {
  const model = new cls();
  Object.keys(item).forEach(key => {
    model[key] = item[key];
  });
  return model;
}

export function clone<T>(obj: T): T {
  if (Array.isArray(obj)) {
    const arr = [];
    obj.forEach(o => arr.push(clone(o)));
    return <any>arr;
  } else {
    return Object.assign({}, obj);
  }
}

export function mergeObjects(obj1: any, obj2: any): any {
  let result: any = null;

  if (obj1) {
    result = clone(obj1);
  } else if (obj2) {
    result = clone(obj2);
  }

  if (obj1 && obj2) {
    Object.keys(result).forEach(key => {
      if (Array.isArray(result[key])) {
        if (obj2[key]) {
          result[key].forEach((val, i) => {
            result[key][i] = mergeObjects(val, obj2[key][i]);
          });
        }
      } else if (typeof result[key] === 'object') {
        result[key] = mergeObjects(result[key], obj2[key]);
      } else {
        if (obj2[key] != null) {
          result[key] = obj2[key];
        }
      }
    });
  }

  return result;
}
