import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ContentChildren,
  AfterViewInit,
  ChangeDetectorRef,
  QueryList,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { merge, ObservableInput, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { PageInfoModel } from '@models/common/table-models/page-info.model';
import { SelectableType } from './selectable-type.enum';
import { TableSelectionEvent } from '@models/local/events/table-selection.event';
import { MAX_PAGE_ROWS_COUNT } from '@shared/app-settings/app.settings';
import { getLimit } from '@helpers/utils/pageInfo.utils';


@Component({
  selector: 'etl-data-table',
  templateUrl: './etl-data-table.component.html',
  styleUrls: ['./etl-data-table.component.sass']
})
export class EtlDataTableComponent<T> implements OnInit, AfterViewInit, OnDestroy {
  selectableType = SelectableType;

  data: PageInfoModel<T> = {
    limit: MAX_PAGE_ROWS_COUNT,
    page: 1,
  };
  dataSource: any;

  selectedRow: T;
  selection: SelectionModel<T>;

  refreshDataSubs: Subscription;

  @ViewChild(MatTable, { static: false }) table: MatTable<T>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  @ContentChildren(MatColumnDef) columns: QueryList<MatColumnDef>;

  @Input() displayedColumns: string[] = [];
  @Input() selectable: SelectableType = SelectableType.NONE;
  @Input() serverSort: boolean = true;
  @Input() sticky: boolean = false;
  @Input() disabledName: string;
  @Input() highlightRow: Function;
  @Input() showPagination: boolean = true;

  public firstLoading: boolean = false;

  @Input() set pageInfo(val: PageInfoModel<T>) {
    this.data = {
      ...this.data,
      page: val.page,
      items: val.items || null,
      itemsCount: val.itemsCount,
      sort: val.sort
    };
    if (!this.serverSort && this.data.items) {
      this.dataSource = new MatTableDataSource(this.data.items);
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = this.data.items
        ? Object.assign(this.data.items)
        : [];
    }
    setTimeout(() => {
      if (this.data.sort && this.data.sort.field) {
        this.sort.active = this.data.sort.field;
        this.sort.direction = this.data.sort.direction as SortDirection;
      } else {
        this.sort.active = null;
        this.sort.direction = '';
      }
      this.initHandler();
    }, 0);
  }

  @Input() set filter(val: any) { }

  @Output() updateData: EventEmitter<PageInfoModel<T>> = new EventEmitter();
  @Output() selectionChange: EventEmitter<TableSelectionEvent<T>> = new EventEmitter();

  // -- properties ------------------------------------------------------------

  get selectionCount(): number {
    if (!this.data.items || this.selectable === SelectableType.NONE) {
      return 0;
    }
    return this.selection.selected.length;
  }

  get paginationVisible(): boolean {
    return this.showPagination
      && this.data && ((this.data.itemsCount >= 10) || (this.data.itemsCount > this.data.limit && this.data.limit < 10));
  }

  // -- component lifecycle hooks ---------------------------------------------

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public sort: MatSort,
  ) {
    this.data.limit = getLimit();
  }

  ngOnInit() {
    const multiSelection = this.selectable !== SelectableType.SINGLE;
    this.selection = new SelectionModel<T>(multiSelection, []);
  }

  ngAfterViewInit(): void {
    const col = [];
    if (this.displayedColumns && this.displayedColumns.length > 0) {
      this.displayedColumns.map(column => {
        const addCol = this.columns.find(c => c.name === column);
        if (addCol) {
          this.table.addColumnDef(addCol);
        }
      });
    } else {
      this.columns.map(c => {
        this.table.addColumnDef(c);
        col.push(c.name);
      });
      this.displayedColumns = col;
    }
    if (this.selectable === SelectableType.MULTIPLE) {
      this.displayedColumns.unshift('etl-multiple-select__');
    }
    this._changeDetector.detectChanges();
  }

  initHandler() {
    const emitters: ObservableInput<{}>[] = [];
    if (this.serverSort) {
      emitters.push(this.sort.sortChange);
    }

    if (this.paginator) {
      emitters.push(this.paginator.page);
    }

    if (this.refreshDataSubs) {
      this.refreshDataSubs.unsubscribe();
    }

    this.refreshDataSubs = merge(...emitters)
      .pipe(
        map(() => this.getData())
      )
      .subscribe(data => {
        this.updateData.emit(data);
      });
  }

  ngOnDestroy(): void {
    if (this.refreshDataSubs) {
      this.refreshDataSubs.unsubscribe();
    }
  }

  // -- event handlers --------------------------------------------------------

  onSelectRow(row: T) {
    if (this.selectable === SelectableType.NONE
      || (this.disabledName && row[this.disabledName])) {
      return;
    }

    if (this.selectable === SelectableType.SINGLE) {
      if (this.selectedRow === row) {
        this.selectedRow = undefined;
      } else {
        this.selectedRow = row;
      }
    }
    this.selection.toggle(row);

    this.selectionChange.emit({
      type: this.selectable,
      value: this.selectedRow,
      values: this.selection.selected
    });
  }

  onToggleSelection(event: MatCheckboxChange, row: T): void {
    if (!event) { return; }

    this.selection.toggle(row);

    this.selectionChange.emit({
      type: this.selectable,
      value: this.selectedRow,
      values: this.selection.selected
    });
  }

  isDisabled(row: T): boolean {
    return this.disabledName && row[this.disabledName];
  }

  // -- general methods -------------------------------------------------------

  getData(): PageInfoModel<T> {
    const data = {
      page: this.data.page,
      limit: this.data.limit,
      sort: undefined
    };

    if (this.paginator) {
      data.page = this.paginator.pageIndex + 1;
      data.limit = this.paginator.pageSize;
    }

    if (this.sort.active) {
      data.sort = {
        field: this.sort.active,
        direction: this.sort.direction
      };
    }

    return data;
  }

  isAllSelected(): boolean {
    if (!this.data.items) {
      return false;
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.data.items.length;
    return numSelected === numRows;
  }

  setSelected(values: T[]): void {
    if (values && this.data.items) {
      this.selection.clear();
      const selection = values
        .map(
          (val: any) => this.data.items
            .find((item: any) => item.id === val.id)
        )
        .filter(val => val);
      this.selection.select(...selection);

      if (this.selectable === SelectableType.SINGLE) {
        this.selectedRow = selection ? selection[0] : null;
      }
    }
    this._changeDetector.detectChanges();
  }

  clearSelection(): void {
    if (this.selection) {
      this.selection.clear();
    }
    this.selectedRow = undefined;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.items.forEach(row => this.selection.select(row));
    this.selectionChange.emit({
      type: this.selectable,
      value: this.selectedRow,
      values: this.selection.selected
    });
  }

  onPaginateChange(event: any): void {
    const pageName = window.location.pathname;
    const data = localStorage.getItem('pagination');
    let res = {};

    if (data && data !== 'undefined') {
      res = JSON.parse(data);
    }
    res[pageName] = event.pageSize;
    this.data.limit = event.pageSize;

    localStorage.setItem('pagination', JSON.stringify(res));

    this.clearSelection();
    this.ngOnInit();
  }

  highlightRowClass(row) {
    return this.highlightRow ? this.highlightRow(row) : '';
  }
}
