import { FilterMapper } from '@mappers/filter-mapper';
import { FilterInfoModel } from '@models/common/table-models/filter-info.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { StorageFilterType } from '@models/local/storage/storage-filter.model';

// tslint:disable-next-line:naming-convention
export let MediaFileFilterInfoMapper: FilterMapper<FilterInfoModel, any> = {
  get filterKey(): string { return null; },

  create(): FilterInfoModel {
    return {
      name: this.filterKey,
      options: [
        {
          id: StorageFilterType.AUDIO,
          title: 'Sound files'
        },
        {
          id: StorageFilterType.CALL_RECORD,
          title: 'Call Records'
        },
        {
          id: StorageFilterType.TRASH,
          title: 'Trash'
        },
      ]
    };
  },

  from(obj: any): FilterInfoModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  },

  to(obj: any): FilterInfoModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
