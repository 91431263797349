export function capitalize(string: string): string {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(string: string): string {
  if (!string) {
    return string;
  }
  return string.replace(/\b\w/g, char => char.toUpperCase());
}

export const addLeadingZeros = (firstLetter: string, count: number, endLetter: string) => {
    let sNum: string;
    let len: number = count;
    len -= String(endLetter).length;
    sNum = endLetter;
    while (len--) {
      sNum = '0' + sNum;
    }
    return firstLetter + sNum;
};
