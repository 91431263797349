import { ValidationErrorType } from './validation-error-type.enum';

export const AUTO_CALL_VALIDATION_MESSAGES = [
  {
    name: 'name',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the campaign name'
  },
  {
    name: 'name',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Please enter the campaign name'
  },
  {
    name: 'name',
    error: ValidationErrorType.MAXLENGTH,
    message: 'Campaign name can\'t contain over 150 characters'
  },
  {
    name: 'name',
    error: ValidationErrorType.PATTERN,
    message: 'invalidCharsDigitsSymbolsMsg',
    params: { field: 'Campaign name', symbols: ' \'-_.&' }
  },
  {
    name: 'sipId',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Phone number'
  },
  {
    name: 'dateStart',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Date start',
  },
  {
    name: 'dateStart',
    error: ValidationErrorType.INVALID_DATE,
    message: 'Invalid date format',
  },
  {
    name: 'dateEnd',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Date end',
  },
  {
    name: 'dateEnd',
    error: ValidationErrorType.INVALID_DATE,
    message: 'Invalid date format',
  },
  {
    name: 'scheduleDate',
    error: ValidationErrorType.DAYS,
    message: 'Please choose at least one day of the week'
  },
  {
    name: 'scheduleTime',
    error: ValidationErrorType.START_TIME,
    message: 'Start time cannot be greater than end time'
  },
  {
    name: 'scheduleTime',
    error: ValidationErrorType.EQUAL_TIME,
    message: 'Start time cannot be the same as end time'
  },
  {
    name: 'scheduleTime',
    error: ValidationErrorType.INVALID_RANGE,
    message: 'Invalid time range format'
  },
  {
    name: 'type',
    error: ValidationErrorType.INVALID_RANGE,
    message: 'Please choose type'
  },
  {
    name: 'type',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose type'
  },
  {
    name: 'objectId',
    error: ValidationErrorType.CALL_RULE_REQUIRED,
    message: 'Please choose the Call rule'
  },
  {
    name: 'objectId',
    error: ValidationErrorType.CALL_QUEUE_REQUIRED,
    message: 'Please choose the Call Queue'
  },
  {
    name: 'objectId',
    error: ValidationErrorType.IVR_REQUIRED,
    message: 'Please choose the IVR'
  },
  {
    name: 'repeatCount',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Repeat count'
  },
  {
    name: 'repeatCount',
    error: ValidationErrorType.MIN,
    message: 'Repeat count cannot be less than 1'
  },
  {
    name: 'repeatCount',
    error: ValidationErrorType.MAX,
    message: 'Repeat count cannot exceed 5'
  },
  {
    name: 'repeatTime',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Repeat time'
  },
  {
    name: 'repeatTime',
    error: ValidationErrorType.MIN,
    message: 'Repeat time cannot be less than 1'
  },
  {
    name: 'repeatTime',
    error: ValidationErrorType.MAX,
    message: 'Repeat time cannot exceed 24'
  },
  {
    name: 'outerLinesCount',
    error: ValidationErrorType.PATTERN,
    message: 'invalidDigitsMsg',
    params: { field: 'Outer Lines Count' }
  },
  {
    name: 'externalPhone',
    error: ValidationErrorType.MINLENGTH,
    message: 'Please use at least 11 characters'
  },
  {
    name: 'phoneNumber',
    error: ValidationErrorType.MINLENGTH,
    message: 'Please use at least 10 characters'
  },
];
