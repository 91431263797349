import { Mapper } from '@mappers/mapper';
import { ContactPhoneModel } from '@models/local/address-book/contact.model';
import { ContactPhoneServerModel } from '@models/server/address-book/contact-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';


// tslint:disable-next-line:naming-convention
export let ContactPhoneMapper: Mapper<ContactPhoneModel, ContactPhoneServerModel> = {
  
  from(obj: ContactPhoneServerModel): ContactPhoneModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      extension: obj.extension,
      typeId: obj.typeId,
      value: obj.value
    };
  },

  fromArray(arr: ContactPhoneServerModel[]): ContactPhoneModel[] {
    return arr.map(a => this.from(a));
  },
  
  to(obj: ContactPhoneModel): ContactPhoneServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
