import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MainMenuModel } from '@models/local/main-menu/main-menu.model';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { Subscription } from 'rxjs';
import { StateService } from '@services/state/state.service';
import { BrowserStorageService } from '@services/browser-storage/browser-storage.service';
import { LAST_SESSION_INFO } from '@shared/constant/storage.const';
import { componentDestroyed } from '@helpers/utils/componentDestroyed';
import { WebSocketService } from '@services/web-socket/web-socket.service';

@Component({
  selector: 'app-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.sass']
})
export class CabinetComponent implements OnInit, OnDestroy {

  pageTitle: string;
  mainMenu: MainMenuModel[];
  profileMenu: MainMenuModel[];

  messageOne: boolean = true;
  messageTwo: boolean = false;
  notificationHide: boolean = false;
  notificationMessageOne: boolean = false;
  notificationMessageTwo: boolean = false;

  routerSubscription: Subscription;
  verified = true;
  showBalanceNotification = true;

  get verificationStatus(): number {
    return this._authService.user ? this._authService.user.verificationStatus : 1;
  }

  get balanceNotification(): boolean {
    return this.verified && (this._authService.user && this._authService.user.accountStatus === 3) && this.showBalanceNotification;
  }

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _state: StateService,
    private _storage: BrowserStorageService,
    private _ws: WebSocketService
  ) {
    this._ws.verified$.takeUntil(componentDestroyed(this)).subscribe(res => {
      this.notificationHide = res;
      this.verified = res;
    });
    this._ws.verified$.next(this._authService.user && this._authService.user.verificationStatus === 1);
    // this.notificationHide = true;
    this._authService.navigationMenu.subscribe(res => {
      this.mainMenu = res;
    });
    if (this._authService.user && this._authService.user.accountVerification) {
      this.messageOne = false;
      this.messageTwo = true;
    }
    this.routerSubscription = this._router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url: string = event.url;
        this._storage.writeItem(LAST_SESSION_INFO, url);
        if (!this.verified) {
          if (url === '/cabinet/tariff'
            || url === '/cabinet/account'
            || url.includes('/cabinet/refill')
            || url === '/cabinet/support') {
            this.notificationHide = false;
          } else {
            this._router.navigate(['/cabinet/account']);
          }
          if (this._router.url === '/cabinet/account') {
            this.notificationMessageOne = false;
            this.notificationMessageTwo = true;
          } else {
            this.notificationMessageOne = true;
            this.notificationMessageTwo = false;
          }
        } else {
          this.notificationHide = true;
        }
        let root = this._router.routerState.snapshot.root;
        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
          } else if (root.data && root.data['title']) {
            this.pageTitle = root.data['title'];
            return;
          } else {
            return;
          }
        }
      });

    this._state.change.takeUntil(componentDestroyed(this)).subscribe(state => {
      if (state) {
        this.notificationHide = false;
        this.messageOne = false;
        this.messageTwo = true;
      }
    });
  }

  ngOnInit() {
    const currentUser = this._authService.currentUser.getValue();
    // const callTransfer = currentUser.services.find(service => service.name === 'callTransfer');
    // if (callTransfer) {
    //   this.mainMenu.push({
    //     icon: '/assets/icons/_menu/audio_conference.svg',
    //     menuType: 0,
    //     path: 'transfer',
    //     title: 'Transfer with Support',
    //   });
    // }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  notificationClose(): void {
    this.notificationHide = true;
    this.showBalanceNotification = false;
  }
}
