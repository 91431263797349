import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { TariffPlanModel } from '@models/local/tariff-plan/tariff-plan.model';
import { TariffMapper } from '@mappers/tariff-plan/tariff.mapper';
import { TariffServerModel } from '@models/server/tariff-plan/tariff-server.model';
import { TranslateService } from '@services/translate/translate.service';
import { NotificationService } from '@services/notification/notification.service';
import { TariffPlanServices } from '@models/local/tariff-plan/tariff-plan-services';


@Injectable({
  providedIn: 'root'
})
export class TariffPlansService {

  tariffs$: BehaviorSubject<TariffPlanModel[]> = new BehaviorSubject<TariffPlanModel[]>([]);
  currentTariff: TariffPlanModel;
  dateNextTariffPlan = null;
  nextTariffPlanId = null;

  constructor(
    private _http: HttpClient,
    private _translate: TranslateService,
    private _notification: NotificationService
  ) {
    this.getTariffPlans().subscribe();
  }

  getTariffPlans(): Observable<TariffPlanModel[]> {

    return this._http.get<TariffServerModel>(`${environment.urlWithVersion}/tariff-plan/account`)
      .pipe(
        map(response => {
          const result = TariffMapper.from(response);
          this.nextTariffPlanId = response.nextTariffPlanId;
          this.dateNextTariffPlan = response.dateNextTariffPlan;

          this.tariffs$.next(result.tariffPlans);
          return result.tariffPlans;
        })
      );
  }

  getTariffChanges(curId: number, dstId: number): Observable<TariffPlanServices[]> {
    return forkJoin([
      this._http
        .get<any>(`${environment.urlWithVersion}/tariff-plan/services/${curId}`)
        .pipe(
          map(res => ({
            ivrs: res.ivrs.map(i => i.name),
            queues: res.queues.map(q => q.name),
            rules: res.rules.map(r => r.name),
            autoCalls: res.autoCalls ? res.autoCalls.map(r => r.name) : [],
            tariff: res.tariff
          }))
        ),
      this._http
        .get<any>(`${environment.urlWithVersion}/tariff-plan/services/${dstId}`)
        .pipe(
          map(res => ({
            ivrs: res.ivrs.map(i => i.name),
            queues: res.queues.map(q => q.name),
            rules: res.rules.map(r => r.name),
            autoCalls: res.autoCalls ? res.autoCalls.map(r => r.name) : [],
            tariff: res.tariff
          }))
        )
    ]);
  }

  changeTariff(id: number): Observable<any> {
    return this._http.post(`${environment.urlWithVersion}/order/tariff-plan/${id}`, {})
      .pipe(
        map((res: any) => {
          this.nextTariffPlanId = id;
          this.dateNextTariffPlan = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
          this._notification.success(res.message);
        })
      );
  }
}
