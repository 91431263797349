import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {
  Component, forwardRef, OnInit, OnDestroy, Injector,
  ElementRef, ViewChild, AfterViewInit, Input
} from '@angular/core';
import { EtlBaseFormControlComponent } from './etl-base-form-control.component';
import IMask from 'imask';
import moment from 'moment';
import { MaskType } from '@shared/constant/mask.type';
import { METHOD_MUST_BE_OVERRIDDEN } from '@shared/constant/app-notification.constants';

@Component({
  selector: 'app-etl-base-masked-form-component',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EtlBaseMaskedFormControlComponent),
      multi: true
    }
  ],
})
export class EtlBaseMaskedFormControlComponent extends EtlBaseFormControlComponent
  implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit {

  @Input() set maskType(val: MaskType) {
    this._maskType = val;
    if (val === MaskType.PHONE) {
      this.initMaskPhone();
    }
    if (val === MaskType.DATE) {
      this.initMaskDate();
    }
  }
  maskRef?: IMask.InputMask<IMask.AnyMaskedOptions>;
  imask: IMask.AnyMaskedOptions;
  unmask = true;
  private _maskVal: any;
  private _maskType: MaskType;
  private _viewInitialized: boolean;
  private _composing: boolean;
  private _writingValue: any;
  private _writing: boolean;
  @ViewChild('input', { static: false }) inputElement: ElementRef;
  imaskElement: (elementRef: ElementRef, directiveRef: any) => IMask.MaskElement;

  get maskElement() {
    return this.imaskElement(this.inputElement, this);
  }

  get maskValue(): any {
    if (!this.maskRef) { return this.maskElement.value; }

    if (this.unmask) { return this.maskRef.typedValue; }
    if (this.unmask) { return this.maskRef.unmaskedValue; }
    return this.maskRef.value;
  }

  set maskValue(value: any) {
    if (this.maskRef) {
      if (this.unmask) {
        this.maskRef.typedValue = value;
      } else
        if (this.unmask) {
          this.maskRef.unmaskedValue = value;
        } else {
          this.maskRef.value = value;
        }
    } else {
      this.renderer.setProperty(this.maskElement, 'value', value);
    }
  }

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
    this.imaskElement = (elementRef: any) => elementRef.nativeElement;
    this._viewInitialized = false;
    this._composing = false;
    this._writing = false;
  }

  writeValue(value: any) {
    if (!this.imask || this._maskType === MaskType.NONE) {
      super.writeValue(value);
      return;
    }
    value = value == null ? '' : value;
    if (this._maskType === MaskType.PHONE) {
      value = ('' + value).replace(/^\D+/g, '');
    }

    if (this.maskRef) {
      this.beginWrite(value);

      if (this.maskValue !== value ||
        (typeof value !== 'string' && this.maskRef.value === '')
      ) {
        this.maskValue = value;
      }
    } else {
      this.renderer.setProperty(this.element, 'value', value);
      this._maskVal = value;
    }
  }


  onInput(val) {
    const res = this.imask ? this.maskValue : val;
    this.onChange(res);
  }

  initMaskPhone() {
    this.imask = { mask: '+{7} (000) 000-00-00' };
  }

  initMaskDate() {
    const momentFormat = 'DD.MM.YYYY';
    this.imask = {
      mask: Date,
      pattern: momentFormat,
      lazy: true,
      min: new Date(1900, 0, 1),
      max: new Date(2030, 0, 1),

      format: function (date) {
        return moment(date).format(momentFormat);
      },
      parse: function (str) {
        return moment(str, momentFormat);
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 9999
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }
    } as IMask.AnyMaskedOptions;
  }

  ngAfterViewInit() {
    if (this.imask) {
      setTimeout(() => {
        this.initMask();
        if (this._maskVal) {
          setTimeout(() => {
            this.writeValue(this._maskVal);
          }, 0);
        }
      }, 0);
    }
    this._viewInitialized = true;
  }

  beginWrite(value: any): void {
    this._writing = true;
    this._writingValue = value;
  }

  endWrite(): any {
    this._writing = false;
    return this._writingValue;
  }

  onAccept() {
    const value = this.maskValue;
    if (this._writing && value === this.endWrite()) { return; }
    if (this._maskType === MaskType.DATE) {
      this.sendDateChange({ startDate: value, endDate: value });
    } else {
      this.onChange(this.maskValue);
    }
  }

  onComplete() {
    if (this._maskType === MaskType.DATE) {
      this.sendDateChange({ startDate: this.maskValue, endDate: this.maskValue });
    } else {
      this.onChange(this.maskValue);
    }
  }

  protected sendDateChange(value) {
    throw Error(METHOD_MUST_BE_OVERRIDDEN);
  }

  private initMask() {
    this.maskRef = IMask(this.maskElement, this.imask as IMask.AnyMaskedOptions)
      .on('accept', this.onAccept.bind(this))
      .on('complete', this.onComplete.bind(this));
  }
}
