import { GuideEventType, GuideSrcElement, GuideEventHandler, GuideAction } from '../guide.enums';
import { ModuleName } from '@models/common/module/module-name.enum';

export const EXTENSION_CREATE_SCENARIO = [
  {
    intro: 'Welcome! We will tell you how to create an internal number',
    hint: true,
  },
  {
    element: 'button-create',
    intro: 'Click on the button Create internal number',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.CREATE_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.EXTENSION_EDIT_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    element: 'external-phone',
    arrowTo: 'input',
    intro: 'Select the phone number that will be used to make calls on this additional line',
    position: 'bottom-left',
    focus: true,
    canMove: false,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['outer']
      }
    ]
  },
  {
    element: 'extension-number',
    arrowTo: 'input',
    intro: 'Enter additional line number',
    position: 'bottom-left',
    focus: true,
    actions: [
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['phoneNumber']
      }
    ]
  },
  {
    element: 'save-all-calls',
    arrowTo: 'input',
    intro: 'Enable the flag Record all calls if required',
    position: 'bottom-right',
    focus: true,
    actions: [
      {
        handler: GuideAction.CHECK_MODULE_ENABLED,
        params: [ModuleName.CALL_RECORD, 1]
      }
    ]
  },
  {
    element: 'fax',
    arrowTo: 'input',
    intro: 'Enable the Fax flag if required',
    position: 'bottom-right',
    focus: true
  },
  {
    element: 'fax-email',
    arrowTo: 'input',
    intro: 'Enter your email address',
    position: 'bottom-left',
    focus: true,
    canMove: false,
    actions: [
      {
        handler: GuideAction.CHECK_FORM_VALUE,
        params: ['faxEnable', true, 1]
      },
      {
        handler: GuideAction.VALIDATE_FORM,
        params: ['faxEmail']
      }
    ]
  },
  {
    element: 'button-save',
    intro: 'Click on the Save button',
    position: 'bottom-left',
    canMove: false,
    events: [
      {
        type: GuideEventType.BUTTON_CLICK,
        srcElement: GuideSrcElement.SAVE_BUTTON,
        handler: GuideEventHandler.HIDE_TOOLTIP
      },
      {
        type: GuideEventType.PAGE_LOAD,
        srcElement: GuideSrcElement.EXTENSION_PAGE,
        handler: GuideEventHandler.SHOW_NEXT_STEP
      }
    ]
  },
  {
    tableElement: true,
    intro: 'The created internal number appeared in the table',
    position: 'top-left',
  },
  {
    intro: 'Come again, always happy to help ...',
    hint: true,
  },
];
