import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EtlMediaPlayerComponent } from '@elements/etl-media-player/etl-media-player.component';
import { EtlNotificationComponent } from '@elements/etl-notification/etl-notification.component';
import { CabinetModule } from '@modules/cabinet/cabinet.module';
import { ErrorComponent } from '@modules/error/error.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '@services/user/authentication-service.service';
import { LOADER_SETTINGS } from '@shared/app-settings/app.settings';
import { ApiInterceptor } from '@shared/interceptors/api.interceptor';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { EtlTranslateLoader } from '@shared/loaders/etl-translate.loader';
import { SharedModule } from '@shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EtlGuideComponent } from '@elements/etl-guide/etl-guide.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(LOADER_SETTINGS),
    NgxUiLoaderHttpModule.forRoot({
      delay: 300,
      showForeground: true,
      excludeRegexp: ['/front-api/refresh-token', '/assets/*']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: EtlTranslateLoader,
        deps: [HttpClient]
      }
    }),

    CabinetModule,
    VgCoreModule,
    VgBufferingModule,
    VgStreamingModule,
    VgControlsModule,
    VgOverlayPlayModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService,
    CookieService
  ],

  entryComponents: [
    EtlNotificationComponent,
    EtlMediaPlayerComponent,
    EtlGuideComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
