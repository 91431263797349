import { Mapper } from '@mappers/mapper';
import { TariffPlanModel } from '@models/local/tariff-plan/tariff-plan.model';
import { TariffPlanServerModel } from '@models/server/tariff-plan/tariff-plan-server.model';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { ModuleMapper } from '@mappers/user/module.mapper';
import { ModuleModel } from '@models/local/tariff-plan/module.model';

// tslint:disable-next-line:naming-convention
export let TariffPlanMapper: Mapper<TariffPlanModel, TariffPlanServerModel> = {

  from(obj: TariffPlanServerModel): TariffPlanModel {
    if (!obj) { return null; }

    let servicePrice = 0;
    let discountPrice = 0;
    let services: ModuleModel[] = null;

    if (obj.offers) {
      obj.offers.map(offer => {
        servicePrice += offer.currentPrice.sumWithVat;
        discountPrice += offer.currentPrice.sum;
      });

      services = obj.offers
        .map(offer => ModuleMapper.from(offer.service));

      const onePhoneInTariff = services.find(s => s.name.indexOf('onePhoneInTariff') !== -1);
      if (onePhoneInTariff) {
        onePhoneInTariff.title += '**';
      }

      const minutesInTariff = services.find(s => s.name.indexOf('minutesInTariff') !== -1);

      if (minutesInTariff) {
        minutesInTariff.title += '*';
      }

      if (!minutesInTariff) {
        services.push({
          id: null,
          name: 'minutesInTariff',
          title: 'Minutes package',
          sum: null,
          sumWithVat: null,
          sort: 30,
          exists: 0,
          count: 0
        });
      }

      services.push({
        id: null,
        name: 'Internal lines',
        title: 'Internal lines',
        sum: null,
        sumWithVat: null,
        sort: 50,
        exists: 2,
        count: obj.params.innerLimit
      });

      services.push({
        id: null,
        name: 'CRMIntegration',
        title: 'CRM Integration',
        sum: null,
        sumWithVat: null,
        sort: 60,
        exists: 1,
        count: 0
      });
    }

    return {
      id: obj.id,
      title: obj.title,
      hidden: obj.hidden,
      description: obj.description,
      tariffPrice: obj.sum ? Math.round(obj.sum * 100) / 100 : null,
      servicePrice: Math.round(servicePrice * 100) / 100,
      discountPrice: Math.round(discountPrice * 100) / 100,
      services: services,
      originalTitle: obj.title === 'Maximum' ? 'Pro' : obj.title
    };
  },

  fromArray(arr: TariffPlanServerModel[]): TariffPlanModel[] {
    return arr.map(a => this.from(a));
  },

  to(obj: TariffPlanModel): TariffPlanServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
