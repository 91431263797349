import { Component, OnInit, Input } from '@angular/core';
import {FormGroup, FormArray, Validators, AbstractControl} from '@angular/forms';
import { MaskType } from '@shared/constant/mask.type';
import { CallRulesService } from '@services/call-rules/call-rules.service';
import { EtlValidators } from '@shared/validators/etl-validators';
import { CrmIntegrationRulesService } from '@services/crm-integration/crm-integration-rules.service';
import {isComVersion} from '@helpers/utils/app.utils';

@Component({
  selector: 'sip-outer-redirection',
  templateUrl: './sip-outer-redirection.component.html',
  styleUrls: [
    './sip-outer-redirection.component.sass',
    './sip-outer-redirection.dark-theme.component.sass',
    './sip-outer-redirection.white-theme.component.sass',
    './sip-outer-redirection.adaptive.component.sass',
  ]
})
export class SipOuterRedirectionComponent implements OnInit {
  // tslint:disable-next-line:naming-convention
  MaskType: typeof MaskType = MaskType;

  maskPhone = isComVersion() ? MaskType.NONE : MaskType.PHONE;

  @Input() form: FormGroup;
  @Input() index: number;
  @Input() service: CallRulesService | CrmIntegrationRulesService;

  constructor() { }

  private _sip = '';

  ngOnInit(): void {

    /*console.log();
    const subscription = this.form.valueChanges.subscribe(
      (value: any) => {
        if (
          !this.form
          || !this.form.value
          || !this.form.value.sipId
          || this.form.value.sipId === this._sip
        ) {
          return;
        }
        console.log('this.index', this.index);
        this._sip = this.form.value.sipId;

        const ruleActions: FormArray = this.form.controls['ruleActions'] as FormArray;

        if (!ruleActions) {
          return;
        }
        const ruleAction = ruleActions.at(this.index);
        ruleAction.markAsUntouched({onlySelf: true});
        ruleAction.markAsTouched({onlySelf: true});
        ruleAction.updateValueAndValidity();
        console.log('updateValueAndValidity()', ruleAction.value);

      }
    )*/
    const current = (this.form.controls['ruleActions'] as FormArray).controls[this.index] as FormGroup;
    /*let phoneVal;
    if (this.service instanceof CallRulesService) {
      phoneVal = this.service.phoneNumbers.find(x => x.id === this.form.value.sipId).phoneNumber;
    }*/
    // console.log('phoneVal: ', phoneVal);
    current.controls['parameter'].setValidators(
      [
       isComVersion() ? Validators.minLength(10) : Validators.minLength(11),
       isComVersion() ? Validators.maxLength(16) : Validators.maxLength(11),
        Validators.pattern('[0-9]*'),
        Validators.required,
      //  EtlValidators.equal(phoneVal ? phoneVal : undefined),
      //   EtlValidators.equalSipID(this.service, this.form)
      ]
    );
    /*current.controls['parameter'].setAsyncValidators(
      [
        EtlValidators.equalSipID(this.service, this.form)
      ]
    );*/
  }
}
