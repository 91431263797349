import { ValidationErrorType } from './validation-error-type.enum';

export const SUPPORT_VALIDATION_MESSAGES = [
  {
    name: 'category',
    error: ValidationErrorType.REQUIRED,
    message: 'Please choose the Category'
  },
  {
    name: 'subject',
    error: ValidationErrorType.REQUIRED,
    message: 'Please enter the Subject'
  },
  {
    name: 'subject',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Please enter the Subject'
  },
  {
    name: 'message',
    error: ValidationErrorType.TRIMMED_LENGTH,
    message: 'Write a message'
  },
  {
    name: 'message',
    error: ValidationErrorType.REQUIRED,
    message: 'Write a message'
  },
  // { key: 'message', error: 'maxlength', message: 'Message can\'t contain over 190 characters' },
];
