import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export enum DadataType {
  PARTY = 'party',
  FIO = 'fio',
  ADDRESS = 'address'
}

export interface DadataFIO {
  surname?: string;
  name?: string;
  patronymic?: string;
  gender?: string;
  qc?: string;
}

export interface DadataAddress {
  postal_code?: string;
  country?: string;
  region_fias_id?: string;
  region_kladr_id?: string;
  region_with_type?: string;
  region_type?: string;
  region_type_full?: string;
  region?: string;
  area_fias_id?: string;
  area_kladr_id?: string;
  area_with_type?: string;
  area_type?: string;
  area_type_full?: string;
  area?: string;
  city_fias_id?: string;
  city_kladr_id?: string;
  city_with_type?: string;
  city_type?: string;
  city_type_full?: string;
  city?: string;
  city_area?: string;
  city_district_fias_id?: string;
  city_district_kladr_id?: string;
  city_district_with_type?: string;
  city_district_type?: string;
  city_district_type_full?: string;
  city_district?: string;
  settlement_fias_id?: string;
  settlement_kladr_id?: string;
  settlement_with_type?: string;
  settlement_type?: string;
  settlement_type_full?: string;
  settlement?: string;
  street_fias_id?: string;
  street_kladr_id?: string;
  street_with_type?: string;
  street_type?: string;
  street_type_full?: string;
  street?: string;
  house_fias_id?: string;
  house_kladr_id?: string;
  house_type?: string;
  house_type_full?: string;
  house?: string;
  block_type?: string;
  block_type_full?: string;
  block?: string;
  flat_type?: string;
  flat_type_full?: string;
  flat?: string;
  flat_area?: string;
  square_meter_price?: string;
  flat_price?: string;
  postal_box?: string;
  fias_id?: string;
  fias_code?: string;
  fias_level?: string;
  fias_actuality_state?: string;
  kladr_id?: string;
  capital_marker?: string;
  okato?: string;
  oktmo?: string;
  tax_office?: string;
  tax_office_legal?: string;
  timezone?: string;
  geo_lat?: string;
  geo_lon?: string;
  beltway_hit?: string;
  beltway_distance?: string;
  metro?: string;
  qc_geo?: string;
  qc_complete?: string;
  qc_house?: string;
  history_values?: string[];
  unparsed_parts?: string;
  source?: string;
  qc?: string;
}

export interface DadataParty {
  kpp?: string;
  capital?: string;
  management: {
    name?: string;
    post?: string
  };
  founders?: string;
  managers?: string;
  branch_type?: string;
  branch_count?: number;
  source?: string;
  qc?: string;
  hid?: string;
  type?: string;
  state: {
    status?: string;
    actuality_date?: number;
    registration_date?: number;
    liquidation_date: null
  };
  opf: {
    type?: string;
    code?: string;
    full?: string;
    short?: string
  };
  name: {
    full_with_opf?: string;
    short_with_opf?: string;
    latinstring?: string;
    full?: string;
    short?: string
  };
  inn?: string;
  ogrn?: string;
  okpo?: string;
  okved?: string;
  okveds?: string;
  authorities?: string;
  documents?: string;
  licenses?: string;
  address: {
    value?: string;
    unrestricted_value?: string;
    data: DadataAddress;
  };
  phones?: string;
  emails?: string;
  ogrn_date?: number;
  okved_type?: string;
}

export interface DadataSuggestion {
  value: string;
  unrestricted_value: string;
  data: DadataAddress | DadataFIO | DadataParty;
}

export interface DadataResponse {
  suggestions: DadataSuggestion[];
}

@Injectable({
  providedIn: 'root'
})
export class DadataService {

  private _url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';
  private _token = 'c468cbf86ac468c7eb3e5030739fb43db8e72309';

  constructor(private _http: HttpClient) { }

  public getData(value: string, config:
    { count: number; parts?: string[], type: DadataType, mode: 'suggest' | 'findById' }): Observable<DadataResponse> {

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + this._token,
      })
    };
    const body = Object.assign(
      {},
      { query: value },
      { count: config.count }
    );

    if (config && config.parts && config.parts.length) {
      Object.assign(body, { parts: config.parts });
    }

    return this._http.post<DadataResponse>(`${this._url}/${config.mode}/${config.type}`, JSON.stringify(body), options);
  }
}
