import { Subject, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { GUIDE_DEFAULT_OPTIONS } from './guide.constants';
import { GuideEventParam, IGuide, ElementPosition, GuideStepModel, GuideEvent, ElementRect, GuideOptionsModel } from './guide.interfaces';
import { EtlDataTableComponent } from '@elements/etl-data-table/etl-data-table.component';
import { clone } from '@helpers/utils/transformation.utils';
import { GuideTaskSchedulerModel } from './guide-task-scheduler.model';
import { UserService } from '@services/user/user.service';
import { ModuleName } from '../module/module-name.enum';


export const DEF_POS_PADDING = 5;
export const ARROW_SIZE = 60;
// export const STEP_SWITCH_DELAY = 30;


export class GuideModel implements IGuide {

  private _steps: GuideStepModel[] = [];
  private _stepSubs: Subscription[] = [];
  private _stopCurTransition: boolean = false;

  options: GuideOptionsModel;
  active: boolean;

  current: GuideStepModel;
  index: number = -1;
  prevIndex: number = -1;

  position: ElementPosition = { left: 0, top: 0 };
  arrowPosition: ElementPosition = { left: 0, top: 0 };
  targetRect: ElementRect = { left: 0, top: 0, width: 0, height: 0 };

  events$: Subject<string> = new Subject();
  params: GuideEventParam[] = [];

  get actionСount(): number {
    return (this._steps.filter(x => !x.hint) || []).length;
  }

  get hasPrev(): boolean {
    return this.index !== 0 && this._steps.length > 0;
  }

  get hasNext(): boolean {
    return this.index < this._steps.length - 1 && this._steps.length > 0;
  }

  get canPrev(): boolean {
    // return this.hasPrev && !this._steps[this.pos - 1].hint;
    return false;
  }

  get canNext(): boolean {
    return this.hasNext && this.current && this.current.canMove;
  }

  constructor(
    private _user: UserService
  ) { }

  start(): void {
    this.active = true;
    this.go(0);
  }

  stop(): void {
    this.active = false;
    this._resetStep();
  }

  init(steps: GuideStepModel[], options: any = null): void {
    this.options = {
      ...GUIDE_DEFAULT_OPTIONS,
      ...options,
    };
    // console.log('opts', this.options);
    this._steps = clone(steps);
    this.updateStepDefaults();
  }

  go(index: number): void {
    const stepSwitchDelay = this.current && this.current.stepSwitchDelay
      || this.options.stepSwitchDelay;
    const scheduler = new GuideTaskSchedulerModel();
    scheduler.push(1, () => {
      this.events$.next('hideTooltip');
      this._resetStep();
    });
    scheduler.push(stepSwitchDelay, () => {
      this.prevIndex = this.index;
      this.index = index;
      this.current = this._steps[this.index];
    });
    scheduler.push(1, () => {
      this._setStep();
    });
    scheduler.push(stepSwitchDelay, () => {
      if (!this._stopCurTransition) {
        this.events$.next('showTooltip');
      }
    });
    scheduler.run();
  }

  prev(): void {
    if (!this.active) { return; }

    if (this.hasPrev && this.canPrev) {
      this.go(this.index - 1);
    }
  }

  next(): void {
    if (!this.active) { return; }

    if (this.hasNext && this.canNext) {
      this.go(this.index + 1);
    } else {
      if (this.current.focus) {
        const element = this._getTargetElement();
        if (element) {
          setTimeout(() => this._setFocus(element), 0);
        }
      }
    }
  }

  updateStepDefaults(): void {
    this._steps
      .forEach(s => {
        s.position = s.position || 'auto';
        s.canMove = s.canMove == null ? true : s.canMove;
      });
  }

  // -- events ----------------------------------------------------------------

  notify(event: GuideEvent): void {
    this._setParam(event.param);

    if (!this.active || !this.current.events || this.current.events.length === 0) { return; }

    const srcEvent = this.current.events
      .find(x => x.type === event.type && x.srcElement === event.srcElement);
    if (!srcEvent) { return; }

    if (this[srcEvent.handler]) {
      this[srcEvent.handler]();
    }
  }

  hideTooltip(): void {
    this.events$.next('hideTooltip');
  }

  showNextStep(): void {
    this.current.canMove = true;
    this.next();
  }

  // -- actions ---------------------------------------------------------------

  validateForm(name: string): void {
    const form = this._getParamValue('form') as FormGroup;
    if (!form) { return; }

    const sub = form.get(name).valueChanges
      .subscribe(_ => {
        this.current.canMove = form.get(name).valid;
      });
    this._stepSubs.push(sub);
  }

  validateTableChecked(): void {
    const table = this._getParamValue('table') as EtlDataTableComponent<any>;
    if (!table) { return; }

    const sub = table.selectionChange
      .subscribe(sel => {
        this.current.canMove = sel.values.length > 0;
      });
    this._stepSubs.push(sub);
  }

  checkFormValue(name: string, value: any, offset: number = 1): void {
    const form = this._getParamValue('form') as FormGroup;
    if (!form) { return; }

    if (form.get(name).value !== value) {
      this._stopCurTransition = true;
      this.go(this.index + offset);
    }
  }

  checkModuleEnabled(moduleName: ModuleName, offset: number = 1): void {
    if (!this._user.isModuleEnabled(moduleName)) {
      this._stopCurTransition = true;
      this.go(this.index + offset);
    }
  }

  // -- DOM elemnts processing ------------------------------------------------

  redraw(): void {
    this._redraw();
  }

  // tslint:disable-next-line:naming-convention
  private _getTargetElement(): Element {
    if (!this.current) { return null; }

    let selector;
    if (this.current.element) {
      selector = `[guide-element='${this.current.element}']`;
    } else if (this.current.tableElement) {
      const model = this._getParamValue('model');
      if (model && model.id) {
        selector = `#row-${model.id}`;
        const element = document.querySelector(selector);
        if (!element) {
          this.current.hint = true;
        }
      }
    }
    return selector ? document.querySelector(selector) : null;
  }

  // tslint:disable-next-line:naming-convention
  private _setStep(): void {
    this._stopCurTransition = false;
    if (this.current.actions) {
      this.current.actions.forEach(a => {
        if (this[a.handler]) {
          a.params = a.params || [];
          this[a.handler](...a.params);
        }
      });
    }
    if (!this._stopCurTransition) {
      this._redraw();
    }
  }

  // tslint:disable-next-line:naming-convention
  private _resetStep(): void {
    const element = this._getTargetElement();
    if (element) {
      this._resetTargetElement();
    }

    this._stepSubs.forEach(s => s.unsubscribe());
    this._stepSubs = [];
  }

  // tslint:disable-next-line:naming-convention
  private _getParamValue(key: string): any {
    const param = this.params.find(a => a.key === key);
    return param ? param.value : null;
  }

  // tslint:disable-next-line:naming-convention
  private _setParam(param: GuideEventParam): void {
    if (param) {
      const current = this.params.find(x => x.key === param.key);
      if (current) {
        current.value = param.value;
      } else {
        this.params.push(param);
      }
    }
  }

  // tslint:disable-next-line:naming-convention
  private _redraw(): void {
    const tip = document.querySelector('.guide-tooltip');
    if (!tip) { return; }

    const bodyR = document.body.getBoundingClientRect();
    const tipR = tip.getBoundingClientRect();

    const element = this._getTargetElement();
    if (element) {
      this._showTargetElement(element);
      this._setFocus(element);

      // calc in relation to target element position
      const elR = element.getBoundingClientRect();
      const offset = this._getArrowOffset(element);
      switch (this.current.position) {
        case 'top-left':
          this._calcTopLeftPosition(elR, tipR, bodyR, offset);
          break;
        case 'bottom-left':
          this._calcBottomLeftPosition(elR, tipR, bodyR, offset);
          break;
        case 'bottom-right':
          this._calcBottomRightPosition(elR, tipR, bodyR, offset);
          break;
      }
    } else {
      this.position = {
        top: Math.ceil((bodyR.height / 2) - (tipR.height / 2)),
        left: Math.ceil((bodyR.width / 2) - (tipR.width / 2))
      };
    }
  }

  // tslint:disable-next-line:naming-convention
  private _showTargetElement(element: Element): void {
    this._addClass(element, 'guide-target-element');

    const cssPos = this._getCssValue(element, 'position');
    if (cssPos !== 'absolute' && cssPos !== 'relative' && cssPos !== 'fixed') {
      this._addClass(element, 'guide-relative-pos');
    }

    let parent = element.parentNode as Element;
    while (parent !== null && parent.tagName.toLowerCase() !== 'body') {
      const zindex = this._getCssValue(parent, 'z-index');
      const opacity = parseFloat(this._getCssValue(parent, 'opacity'));
      const transform = this._getCssValue(parent, 'transform')
        || this._getCssValue(parent, '-webkit-transform')
        || this._getCssValue(parent, '-moz-transform')
        || this._getCssValue(parent, '-ms-transform')
        || this._getCssValue(parent, '-o-transform');

      if (/[0-9]+/.test(zindex) || opacity < 1 || (transform !== 'none' && transform !== undefined)) {
        this._addClass(parent, 'guide-parent-fix');
      }

      parent = parent.parentNode as Element;
    }

    const rect = element.getBoundingClientRect();
    const body = document.body.getBoundingClientRect();
    this.targetRect = {
      left: rect.left - DEF_POS_PADDING,
      top: rect.top - body.top - DEF_POS_PADDING,
      width: rect.width + DEF_POS_PADDING * 2,
      height: rect.height + DEF_POS_PADDING * 2
    };
  }

  // tslint:disable-next-line:naming-convention
  private _setFocus(element: Element): void {
    const input = element.querySelector('input');
    if (input) {
      input.focus();
    }
  }

  // tslint:disable-next-line:naming-convention
  private _resetTargetElement(): void {
    let elements = document.querySelectorAll('.guide-target-element');
    elements.forEach(e => this._removeClass(e, /guide-[a-zA-Z]+/g));
    elements = document.querySelectorAll('.guide-parent-fix');
    elements.forEach(e => this._removeClass(e, /guide-parent-fix/));
  }

  // tslint:disable-next-line:naming-convention
  private _addClass(element: Element, className: string): void {
    element.classList.add(className);
  }

  // tslint:disable-next-line:naming-convention
  private _removeClass(element: Element, classRegExp: RegExp): void {
    element.className = element.className
      .replace(classRegExp, '')
      .replace(/^\s+|\s+$/g, '');
  }

  // tslint:disable-next-line:naming-convention
  private _getCssValue(element: Element, propName: string): string {
    const value = document.defaultView
      .getComputedStyle(element, null)
      .getPropertyValue(propName);

    return value && value.toLowerCase
      ? value.toLowerCase()
      : value;
  }

  // tslint:disable-next-line:naming-convention
  private _calcTopLeftPosition(target: ClientRect, tip: ClientRect, body: ClientRect, offset: number): void {
    const arrX = target.left + offset - ARROW_SIZE + 10;
    const arrY = target.top - body.top - ARROW_SIZE - DEF_POS_PADDING * 3;

    const tipX = arrX - tip.width;
    const tipY = target.top - body.top - tip.height - DEF_POS_PADDING * 2;

    this.arrowPosition = { left: arrX, top: arrY };
    this.position = { left: tipX, top: tipY };

    this._checkLeftBorderPos();
  }

  // tslint:disable-next-line:naming-convention
  private _calcBottomLeftPosition(target: ClientRect, tip: ClientRect, body: ClientRect, offset: number): void {
    const arrX = target.left + offset - ARROW_SIZE + 20;
    const arrY = target.top + target.height - body.top + DEF_POS_PADDING;

    const tipX = arrX - tip.width;
    const tipY = arrY + 15;

    this.arrowPosition = { left: arrX, top: arrY };
    this.position = { left: tipX, top: tipY };

    this._checkLeftBorderPos();
    if (!this._checkBottomBorderPos(tip, body)) {
      this.current.position = 'top-left';
      this._calcTopLeftPosition(target, tip, body, offset);
    }
  }

  // tslint:disable-next-line:naming-convention
  private _calcBottomRightPosition(target: ClientRect, tip: ClientRect, body: ClientRect, offset: number): void {
    const arrX = target.left + offset;
    const arrY = target.top + target.height + DEF_POS_PADDING;

    const tipX = arrX + ARROW_SIZE;
    const tipY = arrY + 15;

    this.arrowPosition = { left: arrX, top: arrY };
    this.position = { left: tipX, top: tipY };

    this._checkRightBorderPos(tip, body);
  }

  // tslint:disable-next-line:naming-convention
  private _getArrowOffset(element: Element): number {
    const rect = element.getBoundingClientRect();
    if (this.current.arrowTo && this.current.arrowTo.length > 0) {
      const child = element.querySelector(this.current.arrowTo);
      if (child) {
        return child.getBoundingClientRect().left - rect.left;
      }
    }
    return rect.width / 2;
  }

  // tslint:disable-next-line:naming-convention
  private _checkLeftBorderPos(): void {
    if (this.position.left < DEF_POS_PADDING) {
      this.arrowPosition.left += (DEF_POS_PADDING - this.position.left);
      this.position.left = DEF_POS_PADDING;
    }
  }

  // tslint:disable-next-line:naming-convention
  private _checkRightBorderPos(tip: ClientRect, body: ClientRect): void {
    if (this.position.left + tip.width > body.width - DEF_POS_PADDING) {
      const offset = body.width - DEF_POS_PADDING - tip.width;
      this.arrowPosition.left -= offset;
      this.position.left = offset;
    }
  }

  // tslint:disable-next-line:naming-convention
  private _checkBottomBorderPos(tip: ClientRect, body: ClientRect): boolean {
    const win = this._getWinSize();
    const hasScroll = win.height < body.height;

    const y = this.position.top + tip.height;
    if (y > win.height) {
      if (!hasScroll || y > body.height) {
        return false;
      }
      const delta = y - win.height + DEF_POS_PADDING;
      window.scrollBy(0, delta);
  }

    return true;
  }

  // tslint:disable-next-line:naming-convention
  private _getWinSize() {
    if (window.innerWidth !== undefined) {
      return { width: window.innerWidth, height: window.innerHeight };
    } else {
      const doc = document.documentElement;
      return { width: doc.clientWidth, height: doc.clientHeight };
    }
  }
}
