import { Observable, ReplaySubject, PartialObserver } from 'rxjs';
import { OnDestroy } from '@angular/core';

export function safeSubscribe(
  component: OnDestroy,
  observable: Observable<any>,
  next?: (value: any) => void,
  error?: (error: any) => void,
  complete?: () => void
) {
  observable
    .takeUntil(componentDestroyed(component))
    .subscribe(next, error, complete);
}

export function componentDestroyed(component: OnDestroy) {
  const oldNgOnDestroy = component.ngOnDestroy;
  const destroyed$ = new ReplaySubject<void>(1);
  component.ngOnDestroy = () => {
    oldNgOnDestroy.apply(component);
    destroyed$.next(undefined);
    destroyed$.complete();
  };
  return destroyed$;
}
