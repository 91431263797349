import { Mapper } from '@mappers/mapper';
import { SipInnerModel } from '@models/local/sip/sip-inner.model';
import { SipInnerServerModel } from '@models/server/sip/sip-inner-server.model';
import { SipOuterMapper } from './sip-outer.mapper';
import { METHOD_IS_NOT_IMPLEMENTED } from '@shared/constant/app-notification.constants';
import { capitalize } from '@helpers/utils/string.utils';

// tslint:disable-next-line:naming-convention
export let SipInnerMapper: Mapper<SipInnerModel, SipInnerServerModel> = {

  from(obj: SipInnerServerModel): SipInnerModel {
    if (!obj) { return null; }

    return {
      id: obj.id,
      default: obj.default,
      host: obj.host,
      port: obj.port,
      mobileApp: obj.mobileApp,
      callRecord: obj.callRecord,
      phoneNumber: obj.phoneNumber,
      sipOuterId: obj.sipOuter ? obj.sipOuter.id : null,
      sipOuter: SipOuterMapper.from(obj.sipOuter),
      status: obj.status,
      faxEnable: obj.faxEnable,
      faxEmail: obj.faxEmail,
      statusName: capitalize(obj.statusName),
      secret: obj.secret,
      displayname: obj.displayname
    };
  },

  fromArray(arr: SipInnerServerModel[]): SipInnerModel[] {
    if (!arr) { return null; }
    return arr.map(a => this.from(a));
  },

  to(obj: SipInnerModel): SipInnerServerModel {
    throw new Error(METHOD_IS_NOT_IMPLEMENTED);
  }
};
